import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
export const Associationreset = createAction("counter/reset");

type InitialState = {
  nameErrorMessage: string;
  aadharImageFrontErrorMessage: string;
  aadharImageBackErrorMessage: string;
  tenantAggrementErrorMessage: string;
  phoneNumberErrorMessage: string;
  emailErrorMessage: string;
  unitNumberErrorMessage: string;
  associationApiErrorMessage: string;
  //amenities
  amenitiesNameErrMessage: string;
  amenitiesOccupancyErrMessage: string;
  amenitiesServiceAmountErrMessage: string;
  amenitiesImagesErrMessage: string;

  //assest
  assetNameErrMessage: string;
  assetCostErrMessage: string;
  quantityErrMessage: string;
  dateOfPurchaseErrMessage: string;
  conditionErrMessage: string;
  //stock
  productNameErrMessage: string;
  productCostErrMessage: string;
  productTypeErrMessage: string;
  stockQuantityErrMessage: string;
  stockDateOfPurchaseErrMessage: string;
  noOfUnitsPurchasedErrMessage: string;
  expiryDateErrMessage: string;
  //Event
  eventImageErrorMessage: string;
  eventTitlesErrorMessage: string;
  venueErrorMessage: string;
  descriptionsErrorMessage: string;
  fromDateErrorMessage: string;
  toDateErrorMessage: string;
  participantsErrorMessage: string;
  ////expense/////
  expenseAmountErrorMessage: string;
  amountExpenditureErrorMessage: string;
  amenitiesErrorMessage: string;
  expenseDateErrorMessage: string;
  descriptionErrorMessage: string;
  ///income
  incomeAmountErrorMessage: string;
  incomeRecievedFromErroMessage: string;
  incomeDateErrorMessage: string;
  //maintenance
  maintenanceAmountErrMessage: string;
  maintenanceFlagErrMessage: string;
  //community enrich
  statusErrorMessage:string;
  //maintenance config
  isPersqftRateErrorMessage: string;
  persqftRateAmountErroMessage: string;
  fixedAmountErrorMessage: string;
  maintenanceConfigProject:string;
  gracePeriodErrorMessage:string;
};
const initialState: InitialState = {
  nameErrorMessage: "",
  aadharImageFrontErrorMessage: "",
  aadharImageBackErrorMessage: "",
  tenantAggrementErrorMessage: "",
  phoneNumberErrorMessage: "",
  emailErrorMessage: "",
  unitNumberErrorMessage: "",
  associationApiErrorMessage: "",
  //amenities
  amenitiesNameErrMessage: "",
  amenitiesOccupancyErrMessage: "",
  amenitiesServiceAmountErrMessage: "",
  amenitiesImagesErrMessage: "",

  //assets
  assetNameErrMessage: "",
  assetCostErrMessage: "",
  quantityErrMessage: "",
  dateOfPurchaseErrMessage: "",
  conditionErrMessage: "",
  //stock
  productNameErrMessage: "",
  productCostErrMessage: "",
  productTypeErrMessage: "",
  stockQuantityErrMessage: "",
  stockDateOfPurchaseErrMessage: "",
  noOfUnitsPurchasedErrMessage: "",
  expiryDateErrMessage: "",
  //Event
  eventImageErrorMessage: "",
  eventTitlesErrorMessage: "",
  venueErrorMessage: "",
  descriptionsErrorMessage: "",
  fromDateErrorMessage: "",
  toDateErrorMessage: "",
  participantsErrorMessage: "",

  ///Expense
  expenseAmountErrorMessage: "",
  amountExpenditureErrorMessage: "",
  amenitiesErrorMessage: "",
  expenseDateErrorMessage: "",
  descriptionErrorMessage: "",

  //income
  incomeAmountErrorMessage: "",
  incomeRecievedFromErroMessage: "",
  incomeDateErrorMessage: "",
  //maintenance
  maintenanceAmountErrMessage: "",
  maintenanceFlagErrMessage: "",
  //community enrich
  statusErrorMessage:"",
  //maintenance config
  isPersqftRateErrorMessage: "",
  persqftRateAmountErroMessage: "",
  fixedAmountErrorMessage: "",
  maintenanceConfigProject:"",
  gracePeriodErrorMessage:"",
};
const associationErrorSlice = createSlice({
  name: "associationErrorMessage",
  initialState,
  reducers: {
    setNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.nameErrorMessage = action.payload;
    },
    setAadharFrontImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.aadharImageFrontErrorMessage = action.payload;
    },
    setAadharBackImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.aadharImageBackErrorMessage = action.payload;
    },
    setTenantAggrementErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.tenantAggrementErrorMessage = action.payload;
    },
    setPhoneNumberErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.phoneNumberErrorMessage = action.payload;
    },
    setEmailErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.emailErrorMessage = action.payload;
    },
    setUnitNumberErroMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.unitNumberErrorMessage = action.payload;
    },
    setApiErrorMessageAssociation: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.associationApiErrorMessage = action.payload;
    },
    //amenities
    setAmenitiesNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.amenitiesNameErrMessage = action.payload;
    },
    setAmenitiesOccupancyErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.amenitiesOccupancyErrMessage = action.payload;
    },

    setAmenitiesServiceAmountErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.amenitiesServiceAmountErrMessage = action.payload;
    },

    setAmenitiesImagesErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.amenitiesImagesErrMessage = action.payload;
    },
    //Event
    setEventImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.eventImageErrorMessage = action.payload;
    },
    setEventTitleErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.eventTitlesErrorMessage = action.payload;
    },
    setEventVenueErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.venueErrorMessage = action.payload;
    },
    setEventDescriptionErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.descriptionsErrorMessage = action.payload;
    },
    setEventFromDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.fromDateErrorMessage = action.payload;
    },
    setEventToDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.toDateErrorMessage = action.payload;
    },
    setParticipantCountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.participantsErrorMessage = action.payload;
    },

    ////ASSETS//////
    setAssetNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.assetNameErrMessage = action.payload;
    },
    setAssetCostErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.assetCostErrMessage = action.payload;
    },
    setQuantityErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.quantityErrMessage = action.payload;
    },
    setDateOfPurchaseErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.dateOfPurchaseErrMessage = action.payload;
    },
    setConditionErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.conditionErrMessage = action.payload;
    },
    // productNameErrMessage: "",
    // productCostErrMessage: "",
    // productTypeErrMessage:"",
    // stockQuantityErrMessage: "",
    // stockDateOfPurchaseErrMessage: "",
    // noOfUnitsPurchased:"".
    setProductNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productNameErrMessage = action.payload;
    },
    setProductCostErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productCostErrMessage = action.payload;
    },
    setProductTypeErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productTypeErrMessage = action.payload;
    },
    setStockQuantityErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.stockQuantityErrMessage = action.payload;
    },
    setStockDateOfPurchaseErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.stockDateOfPurchaseErrMessage = action.payload;
    },
    setNoOfUnitsPurchasedErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.noOfUnitsPurchasedErrMessage = action.payload;
    },
    setExpiryDateErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.expiryDateErrMessage = action.payload;
    },
    ///exepense
    setExpenseAmountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.expenseAmountErrorMessage = action.payload;
    },
    setAmountExpenditureErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.amountExpenditureErrorMessage = action.payload;
    },
    setAmenitiesErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.amenitiesErrorMessage = action.payload;
    },
    setExpenseDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.expenseDateErrorMessage = action.payload;
    },
    setDescriptionErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.descriptionErrorMessage = action.payload;
    },

    //income
    setIncomeAmountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.incomeAmountErrorMessage = action.payload;
    },
    setIncomeReceivedFromErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.incomeRecievedFromErroMessage = action.payload;
    },
    setIncomeDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.incomeDateErrorMessage = action.payload;
    },
    //maintenance
    setMaintenanceAmountErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.maintenanceAmountErrMessage = action.payload;
    },
    SetFlagStatusErrMesage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.maintenanceFlagErrMessage = action.payload;
    },
    //Community enrich 
    setStatusErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.statusErrorMessage = action.payload;
    },

    //maintenance config
    setMaintenanceIsPersqftRateErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.isPersqftRateErrorMessage = action.payload;
    },
    SetPersqftRateAmountErrMesage: ( 
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.persqftRateAmountErroMessage = action.payload;
    },
    setfixedAmountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.fixedAmountErrorMessage = action.payload;
    },
    setMaintenanceProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.maintenanceConfigProject = action.payload;
    },
    setGracePeriodErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.gracePeriodErrorMessage = action.payload;
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(Associationreset, (state) => {
      return initialState;
    });
  },
});
export default associationErrorSlice.reducer;
export const {
  setNameErrorMessage,
  setAadharFrontImageErrorMessage,
  setAadharBackImageErrorMessage,
  setTenantAggrementErrorMessage,
  setPhoneNumberErrorMessage,
  setEmailErrorMessage,
  setUnitNumberErroMessage,
  setApiErrorMessageAssociation,
  //amenities
  setAmenitiesNameErrorMessage,
  setAmenitiesOccupancyErrMessage,
  setAmenitiesServiceAmountErrMessage,
  setAmenitiesImagesErrMessage,

  ///ASSETS///
  setAssetCostErrMessage,
  setAssetNameErrMessage,
  setQuantityErrMessage,
  setConditionErrMessage,
  setDateOfPurchaseErrMessage,

  //STOCK////
  setProductCostErrMessage,
  setProductNameErrMessage,
  setProductTypeErrMessage,
  setStockDateOfPurchaseErrMessage,
  setStockQuantityErrMessage,
  setNoOfUnitsPurchasedErrMessage,
  setExpiryDateErrMessage,
  //Event
  setEventImageErrorMessage,
  setEventTitleErrorMessage,
  setEventVenueErrorMessage,
  setEventDescriptionErrorMessage,
  setEventFromDateErrorMessage,
  setEventToDateErrorMessage,
  setParticipantCountErrorMessage,

  ///expense

  setAmountExpenditureErrorMessage,
  setExpenseAmountErrorMessage,
  setAmenitiesErrorMessage,
  // setDescriptionErrorMessage,
  setExpenseDateErrorMessage,

  ///income
  setIncomeAmountErrorMessage,
  setIncomeReceivedFromErrorMessage,
  setIncomeDateErrorMessage,
  //maintenance
  setMaintenanceAmountErrMessage,
  SetFlagStatusErrMesage,
  //community enrich
  setStatusErrorMessage,
  //maintenace config
  setMaintenanceIsPersqftRateErrMessage,
   SetPersqftRateAmountErrMesage,
   setfixedAmountErrorMessage,
   setMaintenanceProjectErrMessage,
   setGracePeriodErrorMessage,
} = associationErrorSlice.actions;
