import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const reportApi = createApi({
    reducerPath: "reportTeamApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        // baseQueryArgs: {
        
        // },
        // serialize: {
        //     data: value => {
        //       if (value instanceof ArrayBuffer) {
        //         // Convert ArrayBuffer to a serializable representation
        //         return Array.from(new Uint8Array(value));
        //       }
        //       return value;
        //     },
        prepareHeaders: (headers: any) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem("dashboard-token")}`
            );
            return headers;
        },
        // responseHandler: (response) => response.arrayBuffer(),
    }),
    tagTypes: ["report"],
    endpoints: (builder) => ({
        generateReport: builder.query<any, { status: any, issueCategory: any, priority: any, customerCategory: any, callStatus: any, siteVisitStatus: any, taskStatus: any, requestType: any,  createDateGte:any,createDateLte:any,closeDateGte:any,closeDateLte:any,resolvedDateGte:any,resolvedDateLte:any,}>({
            query: ({
                status,
                issueCategory,
                priority,
                customerCategory,
                callStatus,
                siteVisitStatus,
                taskStatus,
                requestType,
                createDateGte,
                createDateLte,
                closeDateGte,
                closeDateLte,
                resolvedDateGte,
                resolvedDateLte,
            }) => ({
                url: "/admin-ticekt-report/",
                method: "GET",
                // body:reportTeamInfo ,
                params: {
                    status: status.join(","),
                    issue_category: issueCategory,
                    priority: priority.join(","),
                    customer_category: customerCategory.join(","),
                    call_status: callStatus.join(","),
                    site_status: siteVisitStatus.join(","),
                    task_status: taskStatus.join(","),
                    request_type: requestType.join(","),
                    created_at_gte:createDateGte ,
                    created_at_lte: createDateLte,
                    closed_at_gte:closeDateGte ,
                    closed_at_lte:closeDateLte,
                    revolved_time_gte:resolvedDateGte, 
                    revolved_time_lte:resolvedDateLte ,
                },
            }),
            providesTags: ["report"],
        }),


    }),
});

export const {
    useGenerateReportQuery
} = reportApi;

// https://casagrandsupport.co.in/api/staff/export-excel-data-v1/?
// &status=102&priority=101&customer_category=101&issue_category=33&call_status=101&site_status=101&
// request_type=101&task_status=102&created_at_gte=2023-11-16&created_at_lte=2023-11-16&closed_at_gte=2023-11-17&closed_at_lte=2023-11-17&revolved_time_gte=2023-11-16&revolved_time_lte=2023-11-16