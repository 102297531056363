import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  emailErrorMessage: string;
  passwordErrorMessage: string;
};
const initialState: InitialState = {
  emailErrorMessage: "",
  passwordErrorMessage: "",
};
const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    setEmailErrorMessage: (state: InitialState, action: PayloadAction<string>) => {
      state.emailErrorMessage = action.payload;
    },
    setPasswordErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.passwordErrorMessage = action.payload;
    },
  },
});
export default LoginSlice.reducer;
export const { setEmailErrorMessage, setPasswordErrorMessage } = LoginSlice.actions;
