import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const userListApi = createApi({
    reducerPath: "userListApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem("dashboard-token")}`
            );
            return headers;
        },
    }),
    tagTypes: ["user-list"],
    endpoints: (builder) => ({
        addUser: builder.mutation<any, {userInfo:any,isAssociation:any}>({
            query: ({userInfo,isAssociation}) => ({
                url: "/staff-user/",
                method: "POST",
                body: userInfo,
                params:{
                    is_admin_creation:isAssociation==true?'':1,
                    is_association_creation:isAssociation==true?1:''
                }
            }),
            invalidatesTags: ["user-list"],
        }),
        getStaffDepartmentsList: builder.query<any, {isAssociation:any}>({
            query: ({isAssociation}) => ({
                url: "/department/list_departments/",
                method: "GET",
                params:{
                    admin_depts:isAssociation?'':1,
                    search:"",
                    association_depts:isAssociation?1:'',   
                }
            }),
        }),
        getStaffDepartmentGroupList: builder.query<any, { id: any }>({
            query: ({ id }) => ({
                url: `/department/${id}/`,
                method: "GET",

            }),
        }),
        getListOfLeads: builder.query<any, {role:any}>({
            query: ({role}) => ({
                url: `/staff-user/`,
                method: "GET",
                params: {
                    // lead_persons: 1,
                    hierarchy:role
                }
            }),
            providesTags: ["user-list"],
        }),
        getRolesList: builder.query<any, void>({
            query: () => ({
                url: `/role/`,
                method: "GET",
               
            }),
            providesTags: ["user-list"],
        }),
        getListOfEmploayeeUser: builder.query<any, { pages: any, searchName: any,association:any,vendor:any,admin:any }>({
            query: ({ pages, searchName,association,vendor ,admin}) => ({
                url: "/staff-user/get_all_user/",///staff-user/get_all_user?is_admin_users=&is_association_users=1&is_vendor_users=&search=go
                method: "GET",
                params: {
                    page: String(pages),
                    search: searchName,
                    is_admin_users:admin?1:"",
                    is_association_users:association?1:"",
                    is_vendor_users:vendor?1:"",
                },
            }),
            providesTags: ["user-list"],
        }),
        getSingleStaffeData: builder.query<any, { id: any,listType:any }>({////get_one/?association_id=&admin_id=&vendor_id=5
            query: ({ id,listType}) => ({
                url: `/staff-user/get_one/`,
                method: "GET",
                params:{
                    admin_id:listType==1?id:'',
                    association_id:listType==2?id:'',
                    vendor_id:listType==3?id:'',
                }
            }),
            providesTags: ["user-list"],
        }),
        editUserDetails: builder.mutation<any, { userInfo: any; id: any,listType:any }>({
            query: ({ userInfo, id,listType }) => ({
                url: `/staff-user/${id}/`,
                method: "PUT",
                body: userInfo,
                params:{
                    is_admin_creation:listType==1?1:'',
                    is_association_creation:listType==2?1:'',
                    vendor_id:listType==3?id:'',
                }
            }),
            invalidatesTags: ["user-list"],
        }),
        deleteUserAndAssignTickets: builder.mutation<any, {  oldUser: any ,newUser:any}>({
            query: ({ oldUser ,newUser}) => ({//http://localhost:8000/api/staff-user/change_ticket_delete_user/?old_user=345&new_user=344
                url: `/staff-user/change_ticket_delete_user/`,
                method: "DELETE",
                params:{
                    old_user:oldUser,
                    new_user:newUser
                }
            }),
            invalidatesTags: ["user-list"],
        }),
        deleteUser: builder.mutation<any, {  id: any }>({
            query: ({ id }) => ({
                url: `/staff-user/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ["user-list"],
        }),//http://localhost:8000/api/staff-user/?old_user=265
        getStaffUsersData:builder.query<any, { id: any }>({
            query: ({ id }) => ({
                url: `/staff-user/`,
                method: "GET",
                params:{
                    old_user:id
                }
            }),
            providesTags: ["user-list"],
        }),
        deleteVendorUser: builder.mutation<any, {  id: any }>({
            query: ({ id }) => ({
                url: `/vendor-register/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ["user-list"],
        }),
        getNoneDeptGroups:builder.query<any,any>({
            query: () => ({
                url: `/group_perm/get_none_groups/`,
                method: "GET",
            }),
            providesTags: ["user-list"],
        }),
    }),
});

export const {  
    useAddUserMutation,
    useGetStaffDepartmentsListQuery,
    useGetStaffDepartmentGroupListQuery,
    useGetListOfLeadsQuery,
    useGetListOfEmploayeeUserQuery,
    useGetSingleStaffeDataQuery,
    useEditUserDetailsMutation,
    useGetRolesListQuery,
    useDeleteUserMutation,
    useDeleteUserAndAssignTicketsMutation,
    useGetStaffUsersDataQuery,
    useDeleteVendorUserMutation,
    useGetNoneDeptGroupsQuery,
} = userListApi;
