import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const productApi = createApi({
    reducerPath: "product",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['product'],
    endpoints: (builder) => ({
        getAllListOfProducts: builder.query<any, {pages:any,searchName:any}>({
            query: ({pages,searchName}) => ({
                url: "/show-all-products/?myProducts=1",
                method: "GET",
                params: {
                  page: String(pages),
                  search:searchName
                },
            }),
            providesTags: ['product']
        }),
        AddProduct: builder.mutation<any, object>({
            query: (productInfo: object) => ({
              url: "/enrich-product-list/",
              method: "POST",
              body: productInfo,
            }),
            invalidatesTags: ["product"],
          }),
          deleteProduct: builder.mutation<any, any>({
            query: (id) => ({
                url: `/show-all-products/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['product']
        }),
        updateProduct: builder.mutation<any, {id:any , formData:any}>({
            query: ({formData,id}) => ({
                url: `/enrich-product-list/${id}/`,
                method: "PATCH",
                body: formData,
            }),
            invalidatesTags: ['product']
        }),
        getSingleProduct: builder.query<any, {editId:any}>({
            query: ({editId}) => ({
                url: `/enrich-product-list/?edit=${editId}`,
                method: "GET",
            }),
        providesTags: ['product']
        }),


    }),

});

export const { useGetAllListOfProductsQuery, useAddProductMutation,useDeleteProductMutation,useUpdateProductMutation ,useGetSingleProductQuery } = productApi;
