import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
export const Adminreset = createAction("counter/reset");

type InitialState = {
  //milestone
  milestoneStartDateErrorMessage: string;
  milestoneEndDateErrorMessage: string;
  milestoneEstimatedErrorMessage: string;
  milestoneCustomerErrorMessage: string;
  milestoneProjectErrorMessage: string;
  milestoneImageErrorMessage: string;
  nameErrorMessage: string;
  //customer edit//
  propertyTypeErrorMessage: string;
  agreementDateErrorMessage: string;
  blockErrorMessage: string;
  carpetArearErrorMessage: string;
  facingrErrorMessage: string;
  saleableErrorMessage: string;
  totalLandArearErrorMessage: string;
  ITCErrorMessage: string;
  projectErrorMessage: string;
  mobileNumberErrorMessage: string;
  unitNumberErrorMessage: string;
  emailErrorMessage: string;
  //banner
  bannerProjectErrorMessage: string;
  bannerMobileImageErrorMessage: string;
  bannerWebImageErrorMessage: string;
  bannerDateRangeErrorMessage: string;
  //payment
  paymentProjectErrMessage: string;
  paymentCustomerErrMessage: string;
  paymentDemandNoteNameErrMessage: string;
  paymentDueDateErrMessage: string;
  paymentAmountErrMessage: string;
  paymentDemandNoteStatusErrMessage: string;
  paymentDemandPercentageErrMessage: string;
  paymentStatusErrMessage: string;
  //paymentSchedule
  paymentScheduleProjectErrMessage: string;
  paymentScheduleCustomerErrMessage: string;
  paymentSchedulepaymentMilestoneNameErrMessage: string;
  paymentScheduleDueDateErrMessage: string;
  paymentScheduleinstrumentTypeErrMessage: string;
  paymentSchedulepaymentPercentageErrMessage: string;
  paymentScheduleGstErrMessage: string;
  paymentScheduleStatusErrMessage: string;
  //registrationOffice
  registrationOfficeProjectErrMessage: string;
  registrationOfficeNameErrMessage: string;
  registrationOfficeAddressErrMessage: string;
  registrationOfficePincodeErrMessage: string;
  //notification
  notificationTitleErrorMessage: string;
  notificationDescriptionErrMessage: string;
  notificationProjectErrMessage: string;
  //helpers
  helperNameErrMessage: string;
  helperPhoneNumberErrMessage: string;
  helperCategoriesErrMessage: string;
  helperProjectErrMessage: string;
  //maintenance
  maintenanceAmountErrMessage: string;
  maintenanceFlagErrMessage: string;
  //parkingSlot
  parkingSlotCustomerErr: string;
  parkingSlotProjectErr: string;
  parkingSlotVehicleNumberErr: string;
  parkingSlotId: string;
  //adStatus
  adStatusErrMessage: string;
  //appointment
  appointmentStatusErr: string;
  appointmentDateRangeErr: string;
  //gallery
  galleryProjectErr: string;
  galleryCustomerErr: string;
  galleryTitleErr: string;
  galleryImagesErr: string;
  //category
  categoryNameErr: string;
  categoryImgErr: string;
  subCategoryErr: string;
  //add Parking slot (SEcurity)
  addParkingSlotName: string;
  addParkingSlotAllotment: string;
  addParkingSlotProject: string;
  //bank
  bankNameErrMessage: string;
  bankBranchNameErrMessage: string;
  bankAddressErrMessage: string;
  //user list(add)
  userNameErrMessage: string;
  userEmailErrMessage: any;
  profileImageErrMessage: string;
  roleErrMessage: any;
  departmentErrMessage: string,
  leadPersonErrMessage: string,

  employeeIdErrMessage: any;
  emplyeePasswordErrMessage: string,
  departmentGroupErrMessage: string,
  userProjectErrMessage: string,
  //ticket doc errr
  ticketTitleErrMessage: string
  ticketDocErrMessage: string
  //profile-add
  profileNameErrMessage: string
  profileDepartmentErrMessage: string
  //role-add
  roleNameErrMessage: string,
  descriptionErrMessage: string,
  //newStaffError-userDelete
  newStaffErrMessage: string,
  //propertyServices
  propertyServicesProjectErrMessage: string;
  ebTaxReqDocErrMessage: string;
  ebTaxAmountErrMessage: string;
  ebTaxContactUsErrMessage: string;
  propertyTaxReqDocErrMessage: string;
  propertyTaxAmountErrMessage: string;
  propertyTaxContactUsErrMessage: string
  //add -ticket

  customerNameErrMessage: string,
  customerPhoneErrMessage: string,
  customerEmailErrMessage: string,
  customerProjectErrMessage: string,
  // customerUnitNumberErrMessage:string,
  reqTypeErrMessage: string,
  issueCategoryErrMessage: string,
  subCategoryErrMessage: string,
  priorityErrMessage: string,
  departmentToErrMessage: string,
  assignedToErrMessage: string,
  statusErrMessage: string,
  remarksErrMessage: string,
  //support team
  salesExecutiveNameErrMessage: string,
  salesExecutiveMobileErrMessage: string,
  paymentCollectionPersonNameErrMessage: string,
  paymentCollectionPersonMobileErrMessage: string,
  bankingTeamNameErrMessage: string,
  bankingTeamMobileErrMessage: string,
  crmExecutiveNameErrMessage: string,
  crmExecutiveMobileErrMessage: string,
  supportTeamProjectErrMessage: string,
  customersErrMessage: string,
  //department-add
  departmentNameErrMessage: string,
  departmentDescriptionErrMessage: string,
  //assure management
  assureNameErrMessage: string,
  assureContactErrMessage: string,
  serviceFeeErrMessage: string,
  staffIdErrMessage: string,
  projectIdErrMessage: string,
};
const initialState: InitialState = {
  //milestone
  milestoneStartDateErrorMessage: "",
  milestoneEndDateErrorMessage: "",
  milestoneEstimatedErrorMessage: "",
  milestoneCustomerErrorMessage: "",
  milestoneProjectErrorMessage: "",
  milestoneImageErrorMessage: "",
  nameErrorMessage: "",
  //customer edit
  propertyTypeErrorMessage: "",
  agreementDateErrorMessage: "",
  blockErrorMessage: "",
  carpetArearErrorMessage: "",
  facingrErrorMessage: "",
  saleableErrorMessage: "",
  totalLandArearErrorMessage: "",
  ITCErrorMessage: "",
  projectErrorMessage: "",
  mobileNumberErrorMessage: "",
  unitNumberErrorMessage: "",
  emailErrorMessage: "",
  //banner
  bannerProjectErrorMessage: "",
  bannerMobileImageErrorMessage: "",
  bannerWebImageErrorMessage: "",
  bannerDateRangeErrorMessage: "",
  //payment
  paymentProjectErrMessage: "",
  paymentCustomerErrMessage: "",
  paymentDemandNoteNameErrMessage: "",
  paymentDueDateErrMessage: "",
  paymentAmountErrMessage: "",
  paymentDemandNoteStatusErrMessage: "",
  paymentDemandPercentageErrMessage: "",
  paymentStatusErrMessage: "",
  //paymentSChedule
  paymentScheduleProjectErrMessage: "",
  paymentScheduleCustomerErrMessage: "",
  paymentSchedulepaymentMilestoneNameErrMessage: "",
  paymentScheduleDueDateErrMessage: "",
  paymentScheduleinstrumentTypeErrMessage: "",
  paymentSchedulepaymentPercentageErrMessage: "",
  paymentScheduleGstErrMessage: "",
  paymentScheduleStatusErrMessage: "",
  //paymentSchedule
  registrationOfficeProjectErrMessage: "",
  registrationOfficeNameErrMessage: "",
  registrationOfficeAddressErrMessage: "",
  registrationOfficePincodeErrMessage: "",
  //notification
  notificationTitleErrorMessage: "",
  notificationDescriptionErrMessage: "",
  notificationProjectErrMessage: "",
  //helpers
  helperNameErrMessage: "",
  helperPhoneNumberErrMessage: "",
  helperCategoriesErrMessage: "",
  helperProjectErrMessage: "",
  //maintenance
  maintenanceAmountErrMessage: "",
  maintenanceFlagErrMessage: "",
  //parkingSlot
  parkingSlotCustomerErr: "",
  parkingSlotProjectErr: "",
  parkingSlotVehicleNumberErr: "",
  parkingSlotId: "",
  //ad
  adStatusErrMessage: "",
  //appointment
  appointmentStatusErr: "",
  appointmentDateRangeErr: "",
  //gallery
  galleryProjectErr: "",
  galleryCustomerErr: "",
  galleryTitleErr: "",
  galleryImagesErr: "",
  //category
  categoryNameErr: "",
  categoryImgErr: "",
  subCategoryErr: "",
  //add Parking slot (SEcurity)
  addParkingSlotName: "",
  addParkingSlotAllotment: "",
  addParkingSlotProject: "",
  //bank
  bankNameErrMessage: '',
  bankBranchNameErrMessage: "",
  bankAddressErrMessage: "",
  //user list(add)
  userNameErrMessage: "",
  userEmailErrMessage: "",
  profileImageErrMessage: "",
  roleErrMessage: "",
  departmentErrMessage: "",
  leadPersonErrMessage: "",
  employeeIdErrMessage: "",
  emplyeePasswordErrMessage: "",
  departmentGroupErrMessage: "",
  userProjectErrMessage:"",
  //ticket doc errr
  ticketTitleErrMessage: "",
  ticketDocErrMessage: "",
  //profile-add
  profileNameErrMessage: "",
  profileDepartmentErrMessage: "",
  //role-add
  roleNameErrMessage: "",
  descriptionErrMessage: "",
  //newStaffError-userDelete
  newStaffErrMessage: "",
  //propertyServices
  propertyServicesProjectErrMessage: "",
  ebTaxReqDocErrMessage: "",
  ebTaxAmountErrMessage: "",
  ebTaxContactUsErrMessage: "",
  propertyTaxReqDocErrMessage: "",
  propertyTaxAmountErrMessage: "",
  propertyTaxContactUsErrMessage: "",

  //add -ticket

  customerNameErrMessage: "",
  customerPhoneErrMessage: "",
  customerEmailErrMessage: "",
  customerProjectErrMessage: "",
  // customerUnitNumberErrMessage:string,
  reqTypeErrMessage: "",
  issueCategoryErrMessage: "",
  subCategoryErrMessage: "",
  priorityErrMessage: "",
  departmentToErrMessage: "",
  assignedToErrMessage: "",
  statusErrMessage: "",
  remarksErrMessage: "",
  //support team
  salesExecutiveNameErrMessage: "",
  salesExecutiveMobileErrMessage: "",
  paymentCollectionPersonNameErrMessage: "",
  paymentCollectionPersonMobileErrMessage: "",
  bankingTeamNameErrMessage: "",
  bankingTeamMobileErrMessage: "",
  crmExecutiveNameErrMessage: "",
  crmExecutiveMobileErrMessage: "",
  supportTeamProjectErrMessage: "",
  customersErrMessage: "",
  //add-department
  departmentNameErrMessage: "",
  departmentDescriptionErrMessage: "",
  //assure management
  assureNameErrMessage: "",
  assureContactErrMessage: "",
  serviceFeeErrMessage: "",
  staffIdErrMessage: "",
  projectIdErrMessage: "",
};

const adminErrorSlice = createSlice({
  name: "adminErrorSlice",
  initialState,
  reducers: {
    setNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.nameErrorMessage = action.payload;
    },
    setMilestoneStartDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.milestoneStartDateErrorMessage = action.payload;
    },
    setMilestoneEndDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.milestoneEndDateErrorMessage = action.payload;
    },
    setMilestoneEstimatedErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.milestoneEstimatedErrorMessage = action.payload;
    },
    setMilestoneCustomerErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.milestoneCustomerErrorMessage = action.payload;
    },
    setMilestoneProjectErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.milestoneProjectErrorMessage = action.payload;
    },
    setMilestoneImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.milestoneImageErrorMessage = action.payload;
    },

    setPropertyTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.propertyTypeErrorMessage = action.payload;
    },
    setAgreementDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.agreementDateErrorMessage = action.payload;
    },
    setBlockErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.blockErrorMessage = action.payload;
    },
    setCarpetArearErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.carpetArearErrorMessage = action.payload;
    },
    setFacingrErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.facingrErrorMessage = action.payload;
    },
    setSaleableErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.saleableErrorMessage = action.payload;
    },

    setTotalLandArearErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.totalLandArearErrorMessage = action.payload;
    },
    setITCErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.ITCErrorMessage = action.payload;
    },
    setProjectErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.projectErrorMessage = action.payload;
    },
    setMobNumErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.mobileNumberErrorMessage = action.payload;
    },
    setUnitNumberErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.unitNumberErrorMessage = action.payload;
    },
    setEmailErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.emailErrorMessage = action.payload;
    },
    //banner
    setBannerProjectErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bannerProjectErrorMessage = action.payload;
    },
    setBannerMobileImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bannerMobileImageErrorMessage = action.payload;
    },
    setBannerWebImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bannerWebImageErrorMessage = action.payload;
    },
    setBannerDateRangeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bannerDateRangeErrorMessage = action.payload;
    },
    //payment
    setPaymentProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentProjectErrMessage = action.payload;
    },
    setPaymentCustomerErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentCustomerErrMessage = action.payload;
    },
    setPaymentDemandNoteNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentDemandNoteNameErrMessage = action.payload;
    },
    setPaymentDueDate: (state: InitialState, action: PayloadAction<string>) => {
      state.paymentDueDateErrMessage = action.payload;
    },
    setPaymentDueAmount: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentAmountErrMessage = action.payload;
    },
    setPaymentDemandNoteStatus: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentDemandNoteStatusErrMessage = action.payload;
    },
    setPaymentDemandPercentage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentDemandPercentageErrMessage = action.payload;
    },
    setPaymentStatus: (state: InitialState, action: PayloadAction<string>) => {
      state.paymentStatusErrMessage = action.payload;
    },
    //paymentSchedule
    setPaymentScheduleProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentScheduleProjectErrMessage = action.payload;
    },
    setPaymentScheduleCustomerErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentScheduleCustomerErrMessage = action.payload;
    },
    setPaymentScheduleMilestoneNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentSchedulepaymentMilestoneNameErrMessage = action.payload;
    },
    setPaymentScheduleDueDate: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentScheduleDueDateErrMessage = action.payload;
    },
    setPaymentScheduleDueInstrumentType: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentScheduleinstrumentTypeErrMessage = action.payload;
    },
    setPaymentSchedulePercentage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentSchedulepaymentPercentageErrMessage = action.payload;
    },
    setPaymentScheduleGst: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentScheduleGstErrMessage = action.payload;
    },
    setPaymentScheduleStatus: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentScheduleStatusErrMessage = action.payload;
    },
    //registrationOFfice
    setRegistrationProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.registrationOfficeProjectErrMessage = action.payload;
    },
    setRegistrationOfficeNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.registrationOfficeNameErrMessage = action.payload;
    },
    setRegistrationOfficePincodeErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.registrationOfficePincodeErrMessage = action.payload;
    },
    setRegistrationOfficeAddressErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.registrationOfficeAddressErrMessage = action.payload;
    },
    //notification
    setNotificationtitleErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.notificationTitleErrorMessage = action.payload;
    },
    setNotificationDescriptionErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.notificationDescriptionErrMessage = action.payload;
    },
    setNotificationProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.notificationProjectErrMessage = action.payload;
    },
    //Helpers

    setHelperNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.helperNameErrMessage = action.payload;
    },
    setHelperPhoneNumberErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.helperPhoneNumberErrMessage = action.payload;
    },

    setHelperCategoriesErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.helperCategoriesErrMessage = action.payload;
    },
    setHelperProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.helperProjectErrMessage = action.payload;
    },
    //maintenance

    //parkingSlot
    setParkingSlotCustomer: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.parkingSlotCustomerErr = action.payload;
    },
    setParkingSlotProject: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.parkingSlotProjectErr = action.payload;
    },
    setParkingSlotVehicleNumber: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.parkingSlotVehicleNumberErr = action.payload;
    },
    SetParkingSlotId: (state: InitialState, action: PayloadAction<string>) => {
      state.parkingSlotId = action.payload;
    },
    //ad
    setAdStatusErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.parkingSlotId = action.payload;
    },
    //appointment
    setAppointmentStatusErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.appointmentStatusErr = action.payload;
    },
    setAppointmentDateRangeErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.appointmentDateRangeErr = action.payload;
    },
    //gallery
    setGalleryProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.galleryProjectErr = action.payload;
    },
    setGalleryCustomerErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.galleryCustomerErr = action.payload;
    },
    setGalleryTitleErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.galleryTitleErr = action.payload;
    },
    setGalleryImagesErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.galleryImagesErr = action.payload;
    },
    //category

    setCategoryNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.categoryNameErr = action.payload;
    },
    setCategoryImageErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.categoryImgErr = action.payload;
    },
    setSubCategoryErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.subCategoryErr = action.payload;
    },
    //add Parking slot (SEcurity)

    setParkingSlotNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.addParkingSlotName = action.payload;
    },
    setParkingSlotAllotmentErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.addParkingSlotAllotment = action.payload;
    },
    setParkingSlotProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.addParkingSlotProject = action.payload;
    },
    //bank
    setBankNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bankNameErrMessage = action.payload;
    },
    setBankBranchErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bankBranchNameErrMessage = action.payload;
    },
    setBankAddressErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bankAddressErrMessage = action.payload;
    },
    //user list(add)

    setUserNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.userNameErrMessage = action.payload;
    },
    setUserEmailErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.userEmailErrMessage = action.payload;
    },
    setProfileImageErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.profileImageErrMessage = action.payload;
    },
    setRoleErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.roleErrMessage = action.payload;
    },
    setDepartmentErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.departmentErrMessage = action.payload;
    },
    setleadPersonErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.leadPersonErrMessage = action.payload;
    },
    setEmployeeIdErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.employeeIdErrMessage = action.payload;
    },
    setEmplyeePasswordErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.emplyeePasswordErrMessage = action.payload;
    },
    setDepartmentGroupErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.departmentGroupErrMessage = action.payload;
    },
    setUserProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.userProjectErrMessage = action.payload;
    },
    
    //ticket doc errr
    setTicketTitleErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.ticketTitleErrMessage = action.payload;
    },
    setTicketDocErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.ticketDocErrMessage = action.payload;
    },
    ///profile-add
    setProfileNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.profileNameErrMessage = action.payload;
    },
    setProfileDepartmentErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.profileDepartmentErrMessage = action.payload;
    },
    // //role-add
    setRoleNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.roleNameErrMessage = action.payload;
    },
    setDescriptionErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.descriptionErrMessage = action.payload;
    },
    //newStaffError-userDelete
    setNewStaffErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.newStaffErrMessage = action.payload;
    },
    //propertyServices
    setPropertyServiceProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.propertyServicesProjectErrMessage = action.payload;
    },
    setEbTaxReqDocErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.ebTaxReqDocErrMessage = action.payload;
    },
    setEbTaxAmountErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.ebTaxAmountErrMessage = action.payload;
    },
    setEbTaxContactUsErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.ebTaxContactUsErrMessage = action.payload;
    },
    setPropertyTaxReqDocErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.propertyTaxReqDocErrMessage = action.payload;
    },
    setPropertyTaxAmountErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.propertyTaxAmountErrMessage = action.payload;
    },
    setPropertyTaxContactUsErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.propertyTaxContactUsErrMessage = action.payload;
    },
    //add -ticket
    setCustomerNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.customerNameErrMessage = action.payload;
    },
    setCustomerPhoneErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.customerPhoneErrMessage = action.payload;
    },
    setCustomerEmailErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.customerEmailErrMessage = action.payload;
    },
    setCustomerProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.customerProjectErrMessage = action.payload;
    },
    setReqTypeErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.reqTypeErrMessage = action.payload;
    },
    setIssueCategoryErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.issueCategoryErrMessage = action.payload;
    },
    setTicketSubCategoryErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.subCategoryErrMessage = action.payload;
    },
    setPriorityErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.priorityErrMessage = action.payload;
    },
    setDepartmentToErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.departmentToErrMessage = action.payload;
    },
    setAssignedToErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.assignedToErrMessage = action.payload;
    },
    setStatusErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.statusErrMessage = action.payload;
    },
    setRemarksErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.remarksErrMessage = action.payload;
    },
    //support team
    setSalesExecutiveNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.salesExecutiveNameErrMessage = action.payload;
    },
    setSalesExecutiveMobileErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.salesExecutiveMobileErrMessage = action.payload;
    },
    setPaymentCollectionPersonNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentCollectionPersonNameErrMessage = action.payload;
    },
    setpaymentCollectionPersonMobileErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.paymentCollectionPersonMobileErrMessage = action.payload;
    },
    setBankingTeamNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bankingTeamNameErrMessage = action.payload;
    },
    setBankingTeamMobileErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bankingTeamMobileErrMessage = action.payload;
    },
    setCrmExecutiveNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.crmExecutiveNameErrMessage = action.payload;
    },
    setCrmExecutiveMobileErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.crmExecutiveMobileErrMessage = action.payload;
    },
    setSupportTeamProjectErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.supportTeamProjectErrMessage = action.payload;
    },
    setCustomersErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.customersErrMessage = action.payload;
    },
    ///department-add
    setDepartmentNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.departmentNameErrMessage = action.payload;
    },
    setDepartmentDescriptionErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.departmentDescriptionErrMessage = action.payload;
    },
    //assure management
    setAssureNameErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.assureNameErrMessage = action.payload;
    },
    setAssureContactErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.assureContactErrMessage = action.payload;
    },
    setServiceFeeErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.serviceFeeErrMessage = action.payload;
    },
    setStaffIdErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.staffIdErrMessage = action.payload;
    },
    setProjectIdErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.projectIdErrMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Adminreset, (state) => {
      return initialState;
    });
  },

});
export default adminErrorSlice.reducer;
export const {
  setMilestoneStartDateErrorMessage,
  setMilestoneEndDateErrorMessage,
  setMilestoneEstimatedErrorMessage,
  setMilestoneProjectErrorMessage,
  setMilestoneImageErrorMessage,
  setNameErrorMessage,
  setMilestoneCustomerErrorMessage,

  setPropertyTypeErrorMessage,
  setAgreementDateErrorMessage,
  setBlockErrMessage,
  setCarpetArearErrorMessage,
  setFacingrErrorMessage,
  setITCErrorMessage,
  setSaleableErrorMessage,
  setTotalLandArearErrorMessage,
  setProjectErrorMessage,
  setMobNumErrMessage,
  setUnitNumberErrorMessage,
  setEmailErrorMessage,
  //banner
  setBannerProjectErrorMessage,
  setBannerMobileImageErrorMessage,
  setBannerWebImageErrorMessage,
  setBannerDateRangeErrorMessage,

  //payment
  setPaymentProjectErrMessage,
  setPaymentCustomerErrMessage,
  setPaymentDemandNoteNameErrMessage,
  setPaymentDueDate,
  setPaymentDueAmount,
  setPaymentDemandNoteStatus,
  setPaymentDemandPercentage,
  setPaymentStatus,

  //paymentSChedule
  setPaymentScheduleProjectErrMessage,
  setPaymentScheduleCustomerErrMessage,
  setPaymentScheduleMilestoneNameErrMessage,
  setPaymentScheduleDueDate,
  setPaymentScheduleDueInstrumentType,
  setPaymentSchedulePercentage,
  setPaymentScheduleGst,
  setPaymentScheduleStatus,
  //registrationOffice
  setRegistrationProjectErrMessage,
  setRegistrationOfficeNameErrMessage,
  setRegistrationOfficeAddressErrMessage,
  setRegistrationOfficePincodeErrMessage,
  //notification
  setNotificationtitleErrMessage,
  setNotificationDescriptionErrMessage,
  setNotificationProjectErrMessage,
  //helpers
  setHelperNameErrMessage,
  setHelperPhoneNumberErrMessage,
  setHelperCategoriesErrMessage,
  setHelperProjectErrMessage,

  //parkingSlot
  setParkingSlotCustomer,
  setParkingSlotProject,
  setParkingSlotVehicleNumber,
  SetParkingSlotId,
  //adStatus
  setAdStatusErrMessage,
  //appointment
  setAppointmentStatusErrMessage,
  setAppointmentDateRangeErrMessage,
  //gallery
  setGalleryProjectErrMessage,
  setGalleryCustomerErrMessage,
  setGalleryTitleErrMessage,
  setGalleryImagesErrMessage,
  //category
  setCategoryNameErrMessage,
  setCategoryImageErrMessage,
  setSubCategoryErrMessage,
  //add Parking slot (SEcurity)
  setParkingSlotNameErrMessage,
  setParkingSlotAllotmentErrMessage,
  setParkingSlotProjectErrMessage,
  //bank
  setBankNameErrMessage,
  setBankBranchErrMessage,
  setBankAddressErrMessage,

  //USElIST(ADD)
  setUserNameErrMessage,
  setUserEmailErrMessage,
  setProfileImageErrMessage,
  setRoleErrMessage,
  setDepartmentErrMessage,
  setleadPersonErrMessage,
  setDepartmentGroupErrMessage,
  setEmployeeIdErrMessage,
  setEmplyeePasswordErrMessage,
  setUserProjectErrMessage,
  //ticket doc errr
  setTicketTitleErrMessage,
  setTicketDocErrMessage,
  //profile-add
  setProfileDepartmentErrMessage,
  setProfileNameErrMessage,
  //roles-add
  setRoleNameErrMessage,
  setDescriptionErrMessage,
  //newStaffErrMessage
  setNewStaffErrMessage,
  //propertyServices
  setPropertyServiceProjectErrMessage,
  setEbTaxReqDocErrMessage,
  setEbTaxAmountErrMessage,
  setEbTaxContactUsErrMessage,
  setPropertyTaxReqDocErrMessage,
  setPropertyTaxAmountErrMessage,
  setPropertyTaxContactUsErrMessage,


  //add-ticket
  setCustomerNameErrMessage,
  setCustomerEmailErrMessage,
  setCustomerPhoneErrMessage,
  setReqTypeErrMessage,
  setIssueCategoryErrMessage,
  setTicketSubCategoryErrMessage,
  setCustomerProjectErrMessage,
  setPriorityErrMessage,
  setDepartmentToErrMessage,
  setAssignedToErrMessage,
  setStatusErrMessage,
  setRemarksErrMessage,
  //support team
  setSalesExecutiveNameErrMessage,
  setSalesExecutiveMobileErrMessage,
  setPaymentCollectionPersonNameErrMessage,
  setpaymentCollectionPersonMobileErrMessage,
  setBankingTeamNameErrMessage,
  setBankingTeamMobileErrMessage,
  setCrmExecutiveNameErrMessage,
  setCrmExecutiveMobileErrMessage,
  setSupportTeamProjectErrMessage,
  setCustomersErrMessage,
  setDepartmentNameErrMessage,
  setDepartmentDescriptionErrMessage,
  //assuremanagement
  setAssureNameErrMessage,
  setAssureContactErrMessage,
  setServiceFeeErrMessage,
  setStaffIdErrMessage,
  setProjectIdErrMessage,
} = adminErrorSlice.actions;

