import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const casaCommissionApi = createApi({
  reducerPath: "casaCommissionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["commission"],
  endpoints: (builder) => ({
    getCasaCommission: builder.query<any, { year: any; vendor_id: any }>({
      query: ({ year, vendor_id }) => ({
        url: "/vendor-commission/",
        method: "GET",
        params: {
          year: year,
          vendor_id: vendor_id
        },
      }),
      providesTags: ["commission"],
    }),
    pendingAndCurrentMonthCommission: builder.query<any, { year: any; vendor_id: any }>({
      query: ({ year, vendor_id }: any) => ({
        url: `/vendor-commission/aggrigated_values/`,
        method: "GET",
        params: {
          year: year,
          vendor_id: vendor_id

        },
      }),

      providesTags: ["commission"],
    }),
    updateAdminCommissionStatus: builder.mutation<any, { id: any; statusObj: any }>({
      query: ({ id, statusObj }: any) => ({
        url: `/vendor-commission/${id}/`,
        method: "PUT",
        body: statusObj
      }),
      invalidatesTags: ["commission"],
    }),
    updateVendorCommissionStatus: builder.mutation<any, { id: any; statusObj: any }>({
      query: ({ id, statusObj }: any) => ({
        url: `/vendor-commission/${id}/`,
        method: "PUT",
        body: statusObj
      }),
      invalidatesTags: ["commission"],
    }),
    getCasaCommissionDetails: builder.query<any, {vendor_id: any,search:any,id:any ,page:any}>({
      query: ({vendor_id,search,id ,page}) => ({
        url: "/vendor-commission/orders/",
        method: "GET",
        params: {
          vendor_id: vendor_id,
          order_id:search,
          id:id,
          page:page
        },
      }),
      providesTags: ["commission"],
    }),//vendor-commission/orders/?id=3&vendor_id=1&order_id=20
  }),
});
export const { useGetCasaCommissionQuery, usePendingAndCurrentMonthCommissionQuery, useUpdateAdminCommissionStatusMutation,useUpdateVendorCommissionStatusMutation ,useGetCasaCommissionDetailsQuery} = casaCommissionApi;