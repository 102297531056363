import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const eventListApi = createApi({
    reducerPath: "event",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['events'],
    endpoints: (builder) => ({
        getAllListOfEvents: builder.query<any, { pages: number, searchName: string }>({
            query: ({ pages, searchName }) => ({
                url: "/association-event/get_events/",
                method: "GET",
                params: {
                    search: searchName,
                    page: String(pages),
                },
            }),
            providesTags: ['events']
        }),
        AddEvent: builder.mutation<any, object>({
            query: (joinEventInfo: object) => ({
                url: "/association-event/",
                method: "POST",
                body: joinEventInfo,
            }),
            invalidatesTags: ["events"],
        }),
        deleteEvent: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `/association-event/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['events']
        }),
        updateEvent: builder.mutation<any, any>({
            query: (eventData: any) => ({
                url: `/association-event/${eventData.id}/`,
                method: "PATCH",
                body: eventData.formData,
            }),
            invalidatesTags: ['events']
        }),
        singleEvent: builder.query<any, { eventId: any }>({
            query: ({ eventId }) => ({
                url: `/association-event/${eventId}/`,
                method: "GET",
            }),
            providesTags: ['events']
        }),
        eventEnquiryList: builder.query<any, { pages: any, searchName: string }>({
            query: ({ pages, searchName }) => ({
                // url: "/association-event/get_events/?casa_event=1",
                url: "/association-event/get_events/",

                method: "GET",
                params: {
                    pages: pages,
                    search: searchName,
                }
            }),
        }),
    }),
});

export const { useGetAllListOfEventsQuery, useSingleEventQuery, useAddEventMutation, useDeleteEventMutation, useUpdateEventMutation, useEventEnquiryListQuery } = eventListApi;
