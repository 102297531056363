import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const rolesApi = createApi({
    reducerPath: "rolesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem("dashboard-token")}`
            );
            return headers;
        },
    }),
    tagTypes: ["roles"],
    endpoints: (builder) => ({
        addRole: builder.mutation<any,any>({
            query: (roleInfoData) => ({
                url: "/role/",
                method: "POST",
                body: roleInfoData,
              
            }),
            invalidatesTags: ["roles"],
        }),
        editRole: builder.mutation<any, {roleInfoData:any,id:string}>({
            query: ({roleInfoData,id}) => ({
                url: `/role/${id}/`,
                method: "PUT",
                body: roleInfoData,
               
            }),
            invalidatesTags: ["roles"],
        }),
        getListOfRoles: builder.query<any, {page:any,search:any}>({
            query: ({page,search}) => ({
                url: `/role/`,
                method: "GET",
                params:{
                    page:page,
                    search:search,
                    pagination:1
                }
            }),
            providesTags: ["roles"],
        }),

        getSingleRole: builder.query<any, {  id: any }>({
            query: ({  id }) => ({
                url: `/role/${id}/`,
                method: "GET",
              
            }),
            providesTags: ["roles"],
        }),
        getInitialProfilePermissions: builder.query<any,void>({
            query: () => ({
                url: "/group_perm/",
                method: "GET",
                params: {
                    permissions: 1,
                },
            }),
            providesTags: ["roles"],
        }),
        deleteRole: builder.mutation<any, {id:string}>({
            query: ({id}) => ({
                url: `/role/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ["roles"],
        }),
    }),
});

export const {
    useAddRoleMutation,
    useEditRoleMutation,
    useGetListOfRolesQuery,
    useGetSingleRoleQuery,
    useGetInitialProfilePermissionsQuery,
    useDeleteRoleMutation,
} = rolesApi;
