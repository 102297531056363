import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const permissionApi = createApi({
  reducerPath: "permission",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["parkings"],
  endpoints: (builder) => ({
    associationPermission: builder.query<any, void>({
      query: () => ({
        url: "/group_perm/get_one/?my_permission=1",
        method: "GET",
      }),
    }),
  }),
});

export const { useAssociationPermissionQuery } = permissionApi;
