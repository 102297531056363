import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const registrationApi = createApi({
    reducerPath: "registration",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers;
        },
    }),
    tagTypes: ["registration","registration-single"],
    endpoints: (builder) => ({
        getListOfRegistrationSlots: builder.query<any, {pages:any,searchName:any}>({
            query: ({pages,searchName}) => ({
                url: "/project-date-slots/get_slots/",
                method: "GET",
                params: {
                  page: String(pages),
                  search:searchName
                },
            }),
            providesTags: ["registration"],
        }),
        addRegistrationSlots: builder.mutation<any, any>({
            query: (RegistrationData) => ({
                url: "/project-date-slots/",
                method: "POST",
                body: RegistrationData
            }),
            invalidatesTags: ["registration"],
        }),
        editRegistrationSlotData: builder.query<any, {slotEditId:any}>({
            query: ({slotEditId}) => ({
                url: `/project-date-slots/${slotEditId}/`,
                method: "GET",
            }),
            providesTags: ["registration"],
        }),
        editRegistrationSlot: builder.mutation<any, {slotEditId:any,editData:any}>({
            query: ({slotEditId,editData}) => ({
                url: `/project-date-slots/${slotEditId}/`,
                method: "PUT",
                body:editData,
            }),
            invalidatesTags: ["registration"],
        }),
    }),
});

export const { useGetListOfRegistrationSlotsQuery, useAddRegistrationSlotsMutation ,useEditRegistrationSlotDataQuery,useEditRegistrationSlotMutation} = registrationApi;
