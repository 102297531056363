import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const parkingListApi = createApi({
    reducerPath: "parking",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['parkings'],
    endpoints: (builder) => ({
        getAllListOfParking: builder.query<any, void>({
            query: () => ({
                url: "/visitor-parking-allotment/",
                method: "GET",
            }),
            providesTags: ['parkings']
        }),
        //new
        getAllListOfParkingSlotNew: builder.query<any, {projectId:any,searchName:any,pages:any}>({
            query: ({projectId,searchName,pages}) => ({
                url: "/customer-visitor-slots/",
                method: "GET",
                params: {
                  project_id: projectId,
                  search: searchName,
                  page: pages,
                },
            }),
            providesTags: ['parkings']
        }),
        addParkingSlotNew: builder.mutation<any, any>({
            query: (parkingData:any) => ({
                url: `/customer-visitor-slots/`,
                method: "POST",
                body: parkingData,
            }),
            invalidatesTags: ['parkings']
        }),
        updateParkingSlotNew: builder.mutation<any, {parkingId:any,parkingData:any}>({
            query: ({parkingId,parkingData}) => ({
                url: `/customer-visitor-slots/${parkingId}/?type=${parkingData.type}`,
                method: "PATCH",
                body: parkingData,
            }),
            invalidatesTags: ['parkings']
        }),
        deleteParkingSlotNew: builder.mutation<any, {parkingId:any,type:any}>({
            query: ({parkingId,type}) => ({
                url: `/customer-visitor-slots/${parkingId}/`,
                method: "DELETE",
                params:{
                    type:type
                }
            }),
            invalidatesTags: ['parkings']
        }),
        singleParkingSlotNew: builder.query<any, {editId:any,type:any}>({
            query: ({editId,type}) => ({
                url: `/customer-visitor-slots/${editId}/?type=${type}`,
                method: "GET",
            }),
            providesTags: ['parkings']
        }),
        //parking slot customer

        getListOfParkingByProject: builder.query<
        any,
        { pages: any; searchName: any; projectId: any }
      >({
        query: ({ pages, searchName, projectId }) => ({
          url: "/parking-slots/",
          method: "GET",
          params: {
            project_id: projectId,
            search: searchName,
            page: pages,
          },
        }),
        providesTags: ["parkings"],
      }),
      addParkingSlots: builder.mutation<any, {Apidata:any ,parkingId:any}>({
        query: ({Apidata ,parkingId}) => ({
          url: `/parking-slots/${parkingId}/`,
          method: "PUT",
          body: Apidata,
        }),
        invalidatesTags: ["parkings"],
      }),
      singleParkingData: builder.query<any, any>({
        query: (editId) => ({
          url: `/parking-slots/${editId}/`,
          method: "GET",
        }),
        providesTags: ["parkings"],
      }),
      deleteParkingData: builder.mutation<any, { editId: any }>({
        query: ({ editId }) => ({
          url: `/parking-slots/${editId}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["parkings"],
      }),
      availableParkingSlotCustomer: builder.query<any, { projectId:any }>({
        query: ({projectId}) => ({
          url: `/customer-visitor-slots/allotment_type/`,
          method: "GET",
          params: {
            is_available: 1,
            project_id: projectId,
            type: "Customer",
          },
        }),
        providesTags: ["parkings"],
      }),
      availableParkingSlotVisitor: builder.query<any, { projectId:any }>({
        query: ({projectId}) => ({
          url: `/customer-visitor-slots/allotment_type/`,
          method: "GET",
          params: {
            is_available: 1,
            project_id: projectId,
            type: "Visitor",
          },
        }),
        providesTags: ["parkings"],
      }),
      getListOfVisitor: builder.query<any, { date_from: any, date_to: any, search: any ,pages:any}>({
        query: ({date_from, date_to,search,pages}) => ({
          url: "/visitors/",
          method: "GET",
          params: {
            date_from: date_from,
            date_to: date_to,
            search:search,
            page:pages
          }
        }),
        providesTags: ["parkings"],
      }),
      updateVisit:builder.mutation<any ,any>({
        query: (data) => ({
          url: `/customer-visitor-slots/allotment_visitor/`,
          method: "PUT",
          body:data
        }),
        invalidatesTags: ["parkings"],
      }),
    }),

});

export const { useGetAllListOfParkingQuery ,useGetAllListOfParkingSlotNewQuery,useAddParkingSlotNewMutation,useUpdateParkingSlotNewMutation,useDeleteParkingSlotNewMutation,useSingleParkingSlotNewQuery,
    useGetListOfParkingByProjectQuery,
    useAddParkingSlotsMutation,
    useSingleParkingDataQuery,
    useDeleteParkingDataMutation,
    useAvailableParkingSlotCustomerQuery , useAvailableParkingSlotVisitorQuery ,useGetListOfVisitorQuery,useUpdateVisitMutation} = parkingListApi;
