import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const categoriesApi = createApi({
    reducerPath: "categoriesApi",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
        }),
    tagTypes: ['category-list'],
    endpoints: (builder) => ({
        getAllListOfCategories: builder.query<any,void>({
            query: () => ({
                url: "/product-category-list/",
                method: "GET",
            providesTags: ['category-list']
            }),
        }),
    }),
});

export const { useGetAllListOfCategoriesQuery} = categoriesApi;
