import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const assureApi = createApi({
  reducerPath: "assure",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["assure"],
  endpoints: (builder) => ({
    getListOfAssure: builder.query<any, { pages: any; searchName: any; project_id: any,type:any }>({
      query: ({ pages, searchName, project_id ,type}) => ({
        url: "/assure-request/",
        method: "GET",
        params: {
          project_id: project_id,
          search: searchName,
          page: pages,
          assure_type:type
        },
      }),
      providesTags: ["assure"],
    }),
    updateAssureStatus: builder.mutation<any,any >({
      query: (data) => ({
        url: `/assure-request/${data.id}/`,
        method: "PUT",
        body:data.statusObj
      }),
      invalidatesTags: ["assure"],
    }),
    addAssureManagement: builder.mutation<any,any >({
      query: (assureInfo:any) => ({
        url: `/assurance-details-list/`,
        method: "POST",
        body:assureInfo
      }),
      invalidatesTags: ["assure"],
    }),
    updateAssureManagement: builder.mutation<any,{assureInfo:any,id:string} >({
      query: ({assureInfo,id}) => ({
        url: `/assurance-details-list/${id}/`,
        method: "PUT",
        body:assureInfo
      }),
      invalidatesTags: ["assure"],
    }),
    getSingleAssureData: builder.query<any, {id:string}>({
      query: ({ id}) => ({
        url: `/assurance-details-list/${id}/`,
        method: "GET",
     
      }),
      providesTags: ["assure"],
    }),
    getListOfStaff: builder.query<any, {projectId:string}>({
      query: ({ projectId}) => ({
        url: "/staff-user/",
        method: "GET",
        params: {
          project_id: projectId,
        },
      }),
      providesTags: ["assure"],
    }),
    getListOfAssureManagement: builder.query<any, { pages: any; searchName: any; }>({
      query: ({ pages, searchName}) => ({
        url: "/assurance-details-list/assure_details_admin/",
        method: "GET",
        params: {
          search: searchName,
          page: pages,
        },
      }),
      providesTags: ["assure"],
    }),
    deleteAssureManagement: builder.mutation<any,{id:string} >({
      query: ({id}) => ({
        url: `/assurance-details-list/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["assure"],
    }),
  }),
});
export const {useGetListOfAssureQuery ,useUpdateAssureStatusMutation,
  useAddAssureManagementMutation,useGetListOfStaffQuery
  ,useUpdateAssureManagementMutation,useGetSingleAssureDataQuery,
  useGetListOfAssureManagementQuery,useDeleteAssureManagementMutation
} = assureApi;