export const PERMISSIONAME ={
    DOCUMENT:"customerprojectdocuments",
    TICKET:'servicerequest',
    REQDOC:"documentsrequest",
    REGSLOTS:"registrationdatesslots",
    REGOFFICE:"registrationoffice",
    CUSTOMER:"customer",
    PARKING:"parkingslot",
    BANNER:"banner",
    MILESTONE:"milestone",
    PAYMENT:"payments",
    PAYMENTSCHEDULE:"customerpaymentschedule",
    NOTIFICATION:"notification",
    HELPER:"helper",
    FORUMREPORTS:"forumpostreports",
    VENDORAD:"vendoradvertisement",
    APPOINTMENT:"appointment",
    ASSURE:"assure",
    GALLERY:"gallery",
    CATEGORY:"productcategories",
    REFERRAL:"referral",
    FITTEDHOME:"fittedhomerequest",
    REVIEW:"review",
    MAINTENANCE:"maintainenceconfigration",
    VENDOR:"vendor",
    USER:"staffuser",
    DEPARTMENT:'department',
    ROLE:'role',
    SUPPORTTEAM:'customersupportteam',
    PROPERTY_SERVICES:"customerpropertyservices",
    PROFILE:'group',
    ASSURECONFIG:'assure',
}