import { useCustomNavigate } from '../../../lib/navigationHook'
import PagenotFound from "../../../assets/images/pagenotfound.png";
import styles from './notFound.module.scss'
import { doNavigateBasedOnToken } from '../../../base/utils/utils';

function NotFound() {
  const navigate =  useCustomNavigate()
  return (
    <div className={styles.pageNotfoundWrapper}>
        <figure>
          <img src={PagenotFound}/>
        </figure>
        <h2>oops</h2>
        <h3>Page Not Found</h3>
        <button onClick={()=>doNavigateBasedOnToken(navigate)} >GO HOME</button>
    </div>

  )
}

export default NotFound