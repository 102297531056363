import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const bankApi = createApi({
    reducerPath: "bankApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['bank'],
    endpoints: (builder) => ({
        getListOfBanks: builder.query<any, { pages: number, searchName: string }>({
            query: ({ pages, searchName }) => ({
                url: "/bank/",
                method: "GET",
                params: {
                    search: searchName,
                    page: String(pages),
                    pagination:1
                },
            }),
            providesTags: ['bank']
        }),
        getSingleBank: builder.query<any, { id: string }>({
            query: ({ id }) => ({
                url: `/bank/${id}/`,
                method: "GET",
            }),
            providesTags: ["bank"],
        }),

        addBank: builder.mutation<any, object>({
            query: (bankInfo: object) => ({
                url: "/bank/",
                method: "POST",
                body: bankInfo,
            }),
            invalidatesTags: ["bank"],
        }),

        deleteBank: builder.mutation<any, { id: any }>({
            query: ({ id }) => ({
                url: `/bank/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['bank']
        }),
        updateBank: builder.mutation<any, { id: any, bankInfo: any }>({
            query: ({ id, bankInfo }) => ({
                url: `/bank/${id}/`,
                method: "PATCH",
                body: bankInfo
            }),
            invalidatesTags: ['bank']
        }),

    }),

});

export const { useGetListOfBanksQuery, useGetSingleBankQuery, useAddBankMutation, useUpdateBankMutation, useDeleteBankMutation } = bankApi;