import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const communityEnrichApi = createApi({
    reducerPath: "enrichProduct",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['enrichProduct'],
    endpoints: (builder) => ({
        getAllListOfEnrichProducts: builder.query<any, {pages:number,searchName:string}>({
            query: ({pages,searchName}) => ({
              url: "/community-enrich-product-list/get_community_products/",
              method: "GET",
              params: {
                  search:searchName,
                page: String(pages),
                type:"product"
              },
            }),
            providesTags: ["enrichProduct"],
          }),
       
          deleteEnrichProduct: builder.mutation<any, any>({
            query: (id:any) => ({
                url: `/community-enrich-product-list/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['enrichProduct']
        }),
        GetCommunictyEnrichServiceList: builder.query<any,{pages:number,searchName:string}>({
            query: ({pages,searchName}) => ({
                url: "/community-enrich-product-list/get_community_products/",
                method: "GET",
                params: {
                    search:searchName,
                  page: String(pages),
                  type:"service"
                },
              }),
              providesTags: ["enrichProduct"],
            }),
        updateCommunityEnrich: builder.mutation<any, any>({
            query: (enrichData:any) => ({
                url: `/community-enrich-product-list/${enrichData.id}/approve_product_service/`,
                method: "PUT",
                body: enrichData.formData,
            }),
            invalidatesTags: ['enrichProduct']
        }),
        // https://casagrandsupport.info/api/community-enrich-product-list/{id}/
        getProductOrService: builder.query<any, any>({
            query: (id:any) => ({
              url: `/community-enrich-product-list/${id}/`,
              method: "GET",
             
            }),
            providesTags: ["enrichProduct"],
          }),


    }),

});

export const { useGetAllListOfEnrichProductsQuery,useGetCommunictyEnrichServiceListQuery,useDeleteEnrichProductMutation ,useUpdateCommunityEnrichMutation,useGetProductOrServiceQuery} = communityEnrichApi;
