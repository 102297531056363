import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const paymentScheduleApi = createApi({
    reducerPath: "my-payment-schedule",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['my-payment-schedule'],
    endpoints: (builder) => ({
        getListOfPaymentSchedule: builder.query<any,  {pages:number,searchName:string,projectId:any}>({
            query: ({pages,searchName,projectId}) => ({
                url: "/my-payment-schedule/",
                method: "GET",
                params: {
                  search:searchName,
                page: String(pages),
                project_id:projectId
              },
            }),
            providesTags: ['my-payment-schedule']
        }),
        getSinglePaymentScheduleData: builder.query<any, {editId:number}>({
            query: ({editId}) => ({
              url: `/my-payment-schedule/${editId}/`,
              method: "GET",
            }),
            providesTags: ["my-payment-schedule"],
          }),
        
        addPaymentSchedule: builder.mutation<any, object>({
            query: (paymentData: object) => ({
              url: "/my-payment-schedule/",
              method: "POST",
              body: paymentData,
            }),
            invalidatesTags: ["my-payment-schedule"],
          }),
      
        deletePaymentSchedule: builder.mutation<any, any>({
          query: (paymentScheduleId: any) => ({
            url: `/my-payment-schedule/`,
            method: "DELETE",
            params: {
              milestone_id: paymentScheduleId,
            },
          }),
          invalidatesTags: ['my-payment-schedule']
        }),
        updatePaymentSchedule: builder.mutation<any, {paymentId:any,paymentData:any}>({
          query: ({paymentId,paymentData}) => ({
            url: `/my-payment-schedule/${paymentId}/`,
            method: "PATCH",
            body:paymentData
          }),
          invalidatesTags: ['my-payment-schedule']
        }),
        
    }),

});

export const {useGetListOfPaymentScheduleQuery , useGetSinglePaymentScheduleDataQuery , useAddPaymentScheduleMutation , useDeletePaymentScheduleMutation, useUpdatePaymentScheduleMutation } = paymentScheduleApi;