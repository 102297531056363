import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type InitialState = {
    messageNotify: boolean;
    messageInfo: string;
    messageType: string;

};

const initialState: InitialState = {
    messageNotify: false,
    messageInfo: "",
    messageType: "",
};

const MessageContextSlice = createSlice({
    name: "MessageContextSlice",
    initialState,
    reducers: {
        setMessageInfo: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.messageInfo = action.payload;
        },
        setMessageNotify: (
            state: InitialState,
            action: PayloadAction<boolean>
        ) => {
            state.messageNotify = action.payload;
        },
        setMessageType: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.messageType = action.payload;
        },
    },
});
export default MessageContextSlice.reducer;
export const {
    setMessageInfo,
    setMessageNotify,
    setMessageType,

} = MessageContextSlice.actions;