import {
  setPasswordErrorMessage,
  setEmailErrorMessage,
  setNameErrorMessage,
  setPhoneErrorMessage,
  setAddressErrorMessage,
  setDiscountErrorMessage,
  setDiscountTypeErrorMessage,
  setLocationErrorMessage,
 
  setProjectErrorMessage,
  setCouponNameErrorMessage,
  setCouponCodeErrorMessage,
  setShopNameErrorMessage,
  setCouponExpiryDateErrorMessage,
  setCouponTypeErrorMessage,
  setCouponStartDateErrorMessage,
  setEventFromDateErrorMessage,
  setEventToDateErrorMessage,
  setEventTitleErrorMessage,
  setEventVenueErrorMessage,
  setParticipantCountErrorMessage,
  setEventDescriptionErrorMessage,
  setEventImageErrorMessage,
  setcouponDiscountErrorMessage,
  setBranchNameErrorMessage,
  setPincodeErrorMessage,
  setCityErrorMessage,
  setAvailabilityErrorMessage,
  setMobNumErrMessage,
  setUnitNumberErrorMessage,
  setDocumentTypeErrorMessage,
  setCodeErrorMessage,
  setOfferTypeErrorMessage,
  setBookingFormErrMessage,
  setAllotmentErrMessage,
  setRentalErrMessage,
  setRecipteErrMessage,
  setMaintainceErrMessage,
  setMilestoneCustomerErrorMessage,
  setMilestoneProjectErrorMessage,
  setMilestoneStartDateErrorMessage,
  setMilestoneEndDateErrorMessage,
  setMilestoneEstimatedErrorMessage,
  setMilestoneImageErrorMessage,
  setParkingProjectErrorMessage,
  setParkingVisitorErrorMessage,
  setProductCategoryErrorMessage,
  setQuantityErrorMessage,
  setProductDescriptionErrorMessage,
  setPrevilagePriceErrorMessage,
  setOriginalPriceErrorMessage,
  setProductNameErrorMessage,
  setProductImageErrorMessage,
  setProductSubCategoryErrorMessage,
  setCategoryTypeErrorMessage,
  setProductErrorMessage,
  setStatusErrorMessage,
} from "../reducer/errorMessageReducer";
import jwt_decode from "jwt-decode";
import { useCustomNavigate } from "../../lib/navigationHook";
import { PATH } from "../../route/path";
import { setMessageInfo, setMessageNotify, setMessageType } from "../reducer/message";



///
export const doNavigateBasedOnToken = (navigate:any) => {
  const token = localStorage.getItem('dashboard-token');
  if (token) {
    let userData: any = jwt_decode(token);
    switch (userData.type) {
      case 'vendor':
        navigate(PATH.VENDOR_HOME);
        break;
      case 'staff':
        if (userData?.department) {
          navigate(PATH.SECURITY_HOME);
        } else {
          navigate(PATH.ASSOCIATION_HOME);
        }
        break;
      case 'admin':
        navigate(PATH.HOME_ADMIN);
        break;
    }
  }
};

export const doLogout =(navigate:any)=>{
  localStorage.removeItem("dashboard-token");
  navigate(PATH.LOGIN)
  window.location.reload();
}

export const doCheckSession = (token: any) => {
  let userData: any = jwt_decode(token);
  let expiryTimeStamp = userData.exp;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  if (currentTimestamp >= expiryTimeStamp) {
    localStorage.removeItem("dashboard-token");
  }
};

export const getCookie = (name: any) => {
  var cookieArr = document.cookie.split(";");

  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0]) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const doValidateEmail = (fieldValue: any, dispatch: any) => {
  // let emailRegEx: RegExp =
  //   /^([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/i;
  if (fieldValue.trim()) {
    // if (emailRegEx.test(fieldValue)) {
      dispatch(setEmailErrorMessage(""));
      return true;
    // } else {
    //   dispatch(setEmailErrorMessage("Email is not valid"));
    // }
  } else {
    dispatch(setEmailErrorMessage("Email or Employee Id field should not be empty"));
  }
};

export const doValidatePassword = (password: any, dispatch: any) => {
  if (password.length < 8) {
    dispatch(
      setPasswordErrorMessage("Password must be at least 8 characters long")
    );
  } else {
    return true;
  }
};

export const doValidateName = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setNameErrorMessage("Name field should not be empty"));
  }
};
export const doValidatePhone = (fieldValue: any, dispatch: any) => {
  let mobileRegex = /^\d{10}$/;
  if (fieldValue) {
    if (mobileRegex.test(fieldValue)) {
      return true;
    } else {
      dispatch(
        setPhoneErrorMessage("Mobile Number should contain only 10 numbers")
      );
    }
  } else {
    dispatch(setPhoneErrorMessage("Mobile Number field should not be empty"));
  }
};
export const doValidateAddress = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setAddressErrorMessage("Address field should not be empty"));
  }
};
export const doValidateDiscount = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setDiscountErrorMessage("Discount field should not be empty"));
  }
};
export const doValidateDiscountType = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(
      setDiscountTypeErrorMessage("Discount type field should not be empty")
    );
  }
};
export const doValidateLocation = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setLocationErrorMessage("Location field should not be empty"));
  }
};


export const doValidateProject = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setProjectErrorMessage("Project field is required"));
  }
};
export const doValidateCode = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setCodeErrorMessage(""));

    return true;
  } else {
    dispatch(setCodeErrorMessage("Code field should not be empty"));
  }
};
export const doValidateBranchName = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setBranchNameErrorMessage("BranchName field is required"));
  }
};
export const doValidatePincode = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setPincodeErrorMessage("Pincode field is required"));
  }
};
export const doValidateCity = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setCityErrorMessage("City field is required"));
  }
};
export const doValidateCouponName = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setCouponNameErrorMessage("Coupon field required"));
  }
};

export const doValiateCouponCode = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setCouponCodeErrorMessage(""));
    return true;
  } else {
    dispatch(setCouponCodeErrorMessage("Coupon Code should not be empty"));
  }
};
export const doValidateMobileNumber = (fieldValue: any, dispatch: any) => {
  let mobileRegex = /^\d{10}$/;
  if (fieldValue) {
    if (mobileRegex.test(fieldValue)) {
      return true;
    } else {
      dispatch(
        setMobNumErrMessage("Mobile Number should contain only 10 numbers")
      );
    }
  } else {
    dispatch(setMobNumErrMessage("Mobile Number field should not be empty"));
  }
};
export const doValidateShopName = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setShopNameErrorMessage(""));
    return true;
  } else {
    dispatch(setShopNameErrorMessage("Shop Name  should not be empty"));
  }
};
export const doValidateCouponStartDate = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setCouponStartDateErrorMessage(""));
    return true;
  } else {
    dispatch(setCouponStartDateErrorMessage("Start date not be empty"));
  }
};
export const doValidateCouponExpiryDate = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setCouponExpiryDateErrorMessage(""));
    return true;
  } else {
    dispatch(
      setCouponExpiryDateErrorMessage("Coupon Expiry should not be empty")
    );
  }
};
export const doValidateCouponType = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setCouponTypeErrorMessage(""));
    return true;
  } else {
    dispatch(setCouponTypeErrorMessage("Coupon type should not be empty"));
  }
};
export const doValidatecouponDiscountPecentage = (
  fieldValue: any,
  dispatch: any
) => {
  let countRegex: RegExp = /^\d+$/;

  if (fieldValue.discount) {
    if (countRegex.test(fieldValue.discount)) {
      if (fieldValue.couponType) {
        if (
          fieldValue.couponType == "102" &&
          parseInt(fieldValue.discount) > 100
        ) {
          dispatch(
            setcouponDiscountErrorMessage("Discount should not exceed 100 %")
          );
          return false;
        } else {
          dispatch(setcouponDiscountErrorMessage(""));
          return true;
        }
      } else {
        dispatch(setcouponDiscountErrorMessage(""));
        return true;
      }
    } else {
      dispatch(setcouponDiscountErrorMessage("Amount/Discount is not valid"));
    }
  } else {
    dispatch(
      setcouponDiscountErrorMessage(
        "Coupon Amount/Discount should not be empty"
      )
    );
  }
};

export const doValidateCouponEndDate = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setCouponStartDateErrorMessage(""));
    return true;
  } else {
    dispatch(setCouponStartDateErrorMessage("Start date not be empty"));
  }
};
export const doValidateFromDate = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setEventFromDateErrorMessage(""));
    return true;
  } else {
    dispatch(setEventFromDateErrorMessage("From date field required"));
  }
};
export const doValidateToDate = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setEventToDateErrorMessage(""));
    return true;
  } else {
    dispatch(setEventToDateErrorMessage("To date field required"));
  }
};
export const doValidateEventTitle = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setEventTitleErrorMessage(""));
    return true;
  } else {
    dispatch(setEventTitleErrorMessage("Event title field required"));
  }
};
export const doValidateEventVenue = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setEventVenueErrorMessage(""));
    return true;
  } else {
    dispatch(setEventVenueErrorMessage("Event Venue field required"));
  }
};
export const doValidateEventDescription = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setEventDescriptionErrorMessage(""));
    return true;
  } else {
    dispatch(
      setEventDescriptionErrorMessage("Event description field required")
    );
  }
};
export const doValidateEventImage = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setEventImageErrorMessage(""));
    return true;
  } else {
    dispatch(setEventImageErrorMessage("Event image field required"));
  }
};
export const doValidateDocument = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setDocumentTypeErrorMessage(""));
    return true;
  } else {
    dispatch(setDocumentTypeErrorMessage("Type field required"));
  }
};
export const doValidateBookingForm = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setBookingFormErrMessage(""));
    return true;
  } else {
    dispatch(setBookingFormErrMessage("Document field required"));
  }
};
export const doValidateAllotment = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setAllotmentErrMessage(""));
    return true;
  } else {
    dispatch(setAllotmentErrMessage("Document field required"));
  }
};
export const doValidateRental = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setRentalErrMessage(""));
    return true;
  } else {
    dispatch(setRentalErrMessage("Document field required"));
  }
};
export const doValidateReciepte = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setRecipteErrMessage(""));
    return true;
  } else {
    dispatch(setRecipteErrMessage("Document field required"));
  }
};
export const doValidateMaintaince = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setMaintainceErrMessage(""));
    return true;
  } else {
    dispatch(setMaintainceErrMessage("Document field required"));
  }
};
export const doValidateProduct = (fieldValue: any, dispatch: any) => {
  if (fieldValue.length > 0) {
    return true;
  } else {
    dispatch(setProductErrorMessage("Product field should not be empty"));
  }
};



export const doValidateParticipantsCount = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setParticipantCountErrorMessage(""));
    return true;
  } else {
    dispatch(setParticipantCountErrorMessage("Participant count is required."));
  }
};
export const doValidateProductCategory = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setProductCategoryErrorMessage(""));

    return true;
  } else {
    dispatch(setProductCategoryErrorMessage("Category field is required"));
  }
};
export const doValidateOriginalPrice = (fieldValue: any, dispatch: any) => {
  let countRegex: RegExp = /^\d+$/;

  if (fieldValue) {
    if(countRegex.test(fieldValue)){
      return true;
    }else{
      dispatch(setOriginalPriceErrorMessage("Original price should be numeric"));

    }
  } else {
    dispatch(setOriginalPriceErrorMessage("Original price field is required"));
  }
};
export const doValidatePrevialgePrice = (fieldValue: any, dispatch: any) => {
  let countRegex: RegExp = /^\d+$/;

  if (fieldValue) {
    setPrevilagePriceErrorMessage("");

    return true;
  } else {
    // dispatch(setPrevilagePriceErrorMessage(" fieldssssssss is required"));
  }
};
export const doValidateQuantity = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setQuantityErrorMessage("Quantity field is required"));
  }
};
export const doValidateProductDescription = (
  fieldValue: any,
  dispatch: any
) => {
  if (fieldValue) {
    return true; 
  } else {
    dispatch(
      setProductDescriptionErrorMessage("Description field is required")
    );
  }
};
export const doValidateProductImage = (fieldValue: any, dispatch: any) => {
  if (fieldValue.length > 0) {
    return true;
  } else {
    dispatch(setProductImageErrorMessage("Image field is required"));
  }
  fieldValue = fieldValue.length;
  if (fieldValue == 0) {
    dispatch(setProductImageErrorMessage("Please select image"));
  } else if (fieldValue < 5) {
    dispatch(setProductImageErrorMessage(""));
    return true;
  } else {
    dispatch(setProductImageErrorMessage("Upload less than five images"));
  }
};
export const doValidateCategoryType = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setCategoryTypeErrorMessage("Category type field is required"));
  }
};
export const doValidateProductSubCategory = (
  fieldValue: any,
  dispatch: any
) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(
      setProductSubCategoryErrorMessage("Subcategory field is required")
    );
  }
};
export const doValidateAvailability = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setAvailabilityErrorMessage("Availability field required"));
  }
};
export const doValidateProductName = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    return true;
  } else {
    dispatch(setProductNameErrorMessage("Name field required"));
  }
};
export const doValidateOfferType = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setOfferTypeErrorMessage(""));
    return true;
  } else {
    dispatch(setOfferTypeErrorMessage("Offer type field is required"));
  }
};




export const doValidateUnitNumber = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setUnitNumberErrorMessage(""));
    return true;
  } else {
    dispatch(setUnitNumberErrorMessage("Unit number field is required"));
  }
};



export const doValidateMilestoneName = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setNameErrorMessage(""));
    return true;
  } else {
    dispatch(setNameErrorMessage("Name field is required"));
  }
};
export const doValidateMilestoneCustomer = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setMilestoneCustomerErrorMessage(""));
    return true;
  } else {
    dispatch(setMilestoneCustomerErrorMessage("Customer field is required"));
  }
};
export const doValidateMilestoneProject = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setMilestoneProjectErrorMessage(""));
    return true;
  } else {
    dispatch(setMilestoneProjectErrorMessage("Project field is required"));
  }
};
export const doValidateMilestoneStartDate = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setMilestoneStartDateErrorMessage(""));
    return true;
  } else {
    dispatch(setMilestoneStartDateErrorMessage("Date field should not be empty"));
  }
};
export const doValidateMilestoneEndDate = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setMilestoneEndDateErrorMessage(""));
    return true;
  } else {
    dispatch(setMilestoneEndDateErrorMessage("Date field should not be empty"));
  }
};
export const doValidateMilestoneEstimated = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setMilestoneEstimatedErrorMessage(""));
    return true;
  } else {
    dispatch(setMilestoneEstimatedErrorMessage("Date field should not be empty"));
  }
};
export const doValidateMilestoneImage = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setMilestoneImageErrorMessage(""));
    return true;
  } else {
    dispatch(setMilestoneImageErrorMessage("Image field is required"));
  }
};
export const doValidateParkingProject = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setParkingProjectErrorMessage(""));
    return true;
  } else {
    dispatch(setParkingProjectErrorMessage("Project field is required"));
  }
};
export const doValidateParkingVisitor = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setParkingVisitorErrorMessage(""));
    return true;
  } else {
    dispatch(setParkingVisitorErrorMessage("Visitor Code field is required"));
  }
};

export const doNotify =(type:any , message:any ,dispatch:any)=>{
dispatch(setMessageNotify(true))
dispatch(setMessageInfo(message))
dispatch(setMessageType(type))

}
export const doValidateCommunityEnrichStatus = (fieldValue: any, dispatch: any) => {
  if (fieldValue) {
    dispatch(setStatusErrorMessage(""));
    return true;
  } else {
    dispatch(setStatusErrorMessage("Kindly select the status"));
  }
};

export const doCheckIsAssociation = ()=>{
  let token:string = localStorage.getItem('dashboard-token')||''
  let userData: any = jwt_decode(token);
  console.log(userData)
  return userData?.type=='staff'
}
 export const getProject=()=>{
  let token:string = localStorage.getItem('dashboard-token')||''
  let userData: any = jwt_decode(token);
  console.log(userData)
  return {
    projectId:userData?.project_id,
    projectName:userData?.project_name
  }
 }