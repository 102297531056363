import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const notificationApi = createApi({
  reducerPath: "notification",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["notification","notification-admin"],
  endpoints: (builder) => ({
    listOfNotification: builder.query< any,{ pages: number; searchName: string ,project:any} >({
      query: ({ pages, searchName,project}) => ({
        url: "/notifications/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          project_id:project
        },
      }),
      providesTags: ["notification"],
    }),
    singleNotificationData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/notifications/${editId}/`,
        method: "GET",
      }),
      providesTags: ["notification"],
    }),

    addNotification: builder.mutation<any, any>({
      query: (formData) => ({
        url: "/notifications/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["notification"],
    }),
    deleteNotification: builder.mutation<any, { notificationId: any }>({
      query: ({ notificationId }) => ({
        url: `/notifications/${notificationId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["notification"],
    }),
    updateNotification: builder.mutation<any, { notificationData: any; notificationId: any } >({
      query: ({ notificationData, notificationId }) => ({
        url: `/notifications/${notificationId}/`,
        method: "PUT",
        body: notificationData,
      }),
      invalidatesTags: ["notification"],
    }),
    notificationList: builder.query< any,{ pages: number} >({
      query: ({pages}) => ({
        url: "/notifications/",
        method: "GET",
        params: {
          from_dashboard: "1",
          page: String(pages),
        },
        providesTags: ["notification"],
      }),
    }),
    readNotification: builder.mutation<any, any>({
      query: (id) => ({
          url: `/notifications/${id}/view_notification/`,
          method: "PUT",
      }),
      invalidatesTags: ['notification-admin']
  }),
  }),
});

export const {
useListOfNotificationQuery,
useSingleNotificationDataQuery,
useAddNotificationMutation,
useDeleteNotificationMutation,
useUpdateNotificationMutation,
useNotificationListQuery,
useReadNotificationMutation

} = notificationApi;
