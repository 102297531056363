import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const galleryApi = createApi({
  reducerPath: "gallery",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["gallery"],
  endpoints: (builder) => ({
    listOfGallery: builder.query< any,{ pages: number; searchName: string,projectId:any } >({
      query: ({ pages, searchName ,projectId}) => ({
        url: "/my-gallery/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          project_id: projectId,
          admin:1
        },
      }),
      providesTags: ["gallery"],
    }),
    singleGallery: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/my-gallery/${editId}/`,
        method: "GET",
      }),
      providesTags: ["gallery"],
    }),

    addgallery: builder.mutation<any, any>({
      query: (formData) => ({
        url: "/my-gallery/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["gallery"],
    }),
    deleteGallery: builder.mutation<any, { deleteId: any }>({
      query: ({ deleteId }) => ({
        url: `/my-gallery/${deleteId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["gallery"],
    }),
    updateGallery: builder.mutation<any, { galleryData: any; galleryId: any } >({
      query: ({ galleryData, galleryId }) => ({
        url: `/my-gallery/${galleryId}/`,
        method: "PUT",
        body: galleryData,
      }),
      invalidatesTags: ["gallery"],
    }),
  }),
});

export const {
  useListOfGalleryQuery,
  useSingleGalleryQuery,
  useAddgalleryMutation,
  useDeleteGalleryMutation,
  useUpdateGalleryMutation
} = galleryApi;
