import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const categorySubCategoryApi = createApi({
  reducerPath: "catSub",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["cat_sub_product", "cat_sub_service", "cat_helpers"],
  endpoints: (builder) => ({
    listOfProductCategory: builder.query<any, { searchName: string }>({
      query: ({ searchName }) => ({
        url: "/product-category-list/",
        method: "GET",
        params: {
          search: searchName,
          dashboard: 1,
        },
      }),
      providesTags: ["cat_sub_product"],
    }),
    listOfProductCategoryDropDown: builder.query<any, any>({
      query: () => ({
        url: "/product-category-list/",
        method: "GET",
      }),
      providesTags: ["cat_sub_product"],
    }),
    productCategoryAdd: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "/product-category-list/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["cat_sub_product"],
    }),
    listOfProductSubCategory: builder.query<any, { searchName: string }>({
      query: ({ searchName }) => ({
        url: "/product-subcategory-list/",
        method: "GET",
        params: {
          search: searchName,
          dashboard: 1,
        },
      }),
      providesTags: ["cat_sub_product"],
    }),
    productSubCategoryAdd: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "/product-subcategory-list/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["cat_sub_product"],
    }),
    singleProductData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/product-category-list/${editId}/`,
        method: "GET",
      }),
      providesTags: ["cat_sub_product"],
    }),
    singleProductSubCategoryData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/product-subcategory-list/${editId}/`,
        method: "GET",
      }),
      providesTags: ["cat_sub_product"],
    }),
    productCategoryUpdate: builder.mutation<any, { editId: any; data: any }>({
      query: ({ editId, data }) => ({
        url: `/product-category-list/${editId}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["cat_sub_product"],
    }),
    productSubCategoryUpdate: builder.mutation<any, { editId: any; data: any }>(
      {
        query: ({ editId, data }) => ({
          url: `/product-subcategory-list/${editId}/`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["cat_sub_product"],
      }
    ),
    //service
    listOfServiceCategory: builder.query<any, { searchName: string }>({
      query: ({ searchName }) => ({
        url: "/service-category-list/",
        method: "GET",
        params: {
          search: searchName,
          dashboard: 1,
        },
      }),
      providesTags: ["cat_sub_service"],
    }),
    listOfServiceCategoryDropDown: builder.query<any, any>({
      query: () => ({
        url: "/service-category-list/",
        method: "GET",
      }),
      providesTags: ["cat_sub_service"],
    }),
    serviceCategoryAdd: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "/service-category-list/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["cat_sub_service"],
    }),
    listOfServiceSubCategory: builder.query<any, { searchName: string }>({
      query: ({ searchName }) => ({
        url: "/service-subcategory-list/",
        method: "GET",
        params: {
          search: searchName,
          dashboard: 1,
        },
      }),
      providesTags: ["cat_sub_service"],
    }),
    serviceSubCategoryAdd: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "/service-subcategory-list/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["cat_sub_service"],
    }),
    singleServiceData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/service-category-list/${editId}/`,
        method: "GET",
      }),
      providesTags: ["cat_sub_product"],
    }),
    singleServiceSubCategoryData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/service-subcategory-list/${editId}/`,
        method: "GET",
      }),
      providesTags: ["cat_sub_product"],
    }),
    serviceCategoryUpdate: builder.mutation<any, { editId: any; data: any }>({
      query: ({ editId, data }) => ({
        url: `/service-category-list/${editId}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["cat_sub_service"],
    }),
    serviceSubCategoryUpdate: builder.mutation<any, { editId: any; data: any }>(
      {
        query: ({ editId, data }) => ({
          url: `/service-subcategory-list/${editId}/`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["cat_sub_service"],
      }
    ),
    //helpers
    listOfHelpersCategory: builder.query<any,any>({
      query: ({ searchName }) => ({
        url: "/helpers-category/",
        method: "GET",
        params: {
          search: searchName,
        },
      }),
      providesTags: ["cat_helpers"],
    }),
    helperCategoryAdd: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "/helpers-category/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["cat_helpers"],
    }),
    singleHelperData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/helpers-category/${editId}/`,
        method: "GET",
      }),
      providesTags: ["cat_helpers"],
    }),
    helperCategoryUpdate: builder.mutation<any, { editId: any; data: any }>({
      query: ({ editId, data }) => ({
        url: `/helpers-category/${editId}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["cat_helpers"],
    }),
  }),
});

export const {
  useListOfProductCategoryQuery,
  useListOfProductCategoryDropDownQuery,
  useProductCategoryAddMutation,
  useListOfProductSubCategoryQuery,
  useProductSubCategoryAddMutation,
  useSingleProductDataQuery,
  useSingleProductSubCategoryDataQuery,
  useProductCategoryUpdateMutation,
  useProductSubCategoryUpdateMutation,
  //service
  useListOfServiceCategoryQuery,
  useListOfServiceCategoryDropDownQuery,
  useServiceCategoryAddMutation,
  useListOfServiceSubCategoryQuery,
  useServiceSubCategoryAddMutation,
  useSingleServiceDataQuery,
  useSingleServiceSubCategoryDataQuery,
  useServiceCategoryUpdateMutation,
  useServiceSubCategoryUpdateMutation,
  //helper
  useListOfHelpersCategoryQuery,
  useHelperCategoryAddMutation,
  useSingleHelperDataQuery,
  useHelperCategoryUpdateMutation
} = categorySubCategoryApi;
