import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const cutomerAppointmentApi = createApi({
  reducerPath: "customer-appointment",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["customer-appointment"],
  endpoints: (builder) => ({
    listOfAppointment: builder.query< any,{ pages: number; searchName: string ,projectId:any} >({
      query: ({ pages, searchName ,projectId}) => ({
        url: "/appointment-request/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          project_id:projectId
        },
      }),
      providesTags: ["customer-appointment"],
    }),
    singleAppointmentData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/appointment-request/${editId}/`,
        method: "GET",
      }),
      providesTags: ["customer-appointment"],
    }),
    updateAppointment: builder.mutation<any, { appointmentData: any; appointmentId: any } >({
      query: ({ appointmentData, appointmentId }) => ({
        url: `/appointment-request/${appointmentId}/`,
        method: "PUT",
        body: appointmentData,
      }),
      invalidatesTags: ["customer-appointment"],
    }),
  }),
});

export const {
 useListOfAppointmentQuery,
 useSingleAppointmentDataQuery,
 useUpdateAppointmentMutation
} = cutomerAppointmentApi;
