import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const enrichCouponsApi = createApi({
    reducerPath: "enrichCouponsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['Coupon'],
    endpoints: (builder) => ({
        getDiscountCouponsPagination: builder.query<any, {pages:any,searchName:any}>({
            query: ({pages,searchName}) => ({
                url: "/coupon-code-list/",
                method: "GET",
                params: {
                   page:pages,
                   search:searchName
                },
            }),
            providesTags: ['Coupon']
        }),
        getDiscountCoupons: builder.query<any, any>({
            query: () => ({
                url: "/coupon-code-list/",
                method: "GET",
            }),
            providesTags: ['Coupon']
        }),
        getAllListOfCoupon: builder.query<any, void>({
            query: () => ({
                url: "/coupon-code-list/",
                method: "GET",
                params: {
                    without_pagination:"1"
                },
            }),
            providesTags: ['Coupon']
        }),
        addCoupon: builder.mutation<any, any>({
            query: (couponInfo: any) => ({
                url: "/coupon-code-list/",
                method: "POST",
                body: couponInfo,
            }),
            invalidatesTags: ['Coupon']
        }),
        deleteCoupon: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `/coupon-code-list/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['Coupon']
        }),
        editCoupon: builder.mutation<any, any>({
            query: (couponData: any) => ({
                url: `/coupon-code-list/${couponData.id}/`,
                method: "PATCH",
                body: couponData.couponInfo,

            }),
            invalidatesTags: ['Coupon']
        }),
    }),

});

export const { useGetDiscountCouponsPaginationQuery, useGetDiscountCouponsQuery, useGetAllListOfCouponQuery, useAddCouponMutation ,useDeleteCouponMutation ,useEditCouponMutation} = enrichCouponsApi;
