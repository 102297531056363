import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { loginApi } from "../../service/generalApiService/login/loginApi";
import loginReducer from "../reducer/loginReducer";
import errorMessageReducer from "../reducer/errorMessageReducer";
import associationErrorMessageReducer from "../reducer/associationErrorMessageReducer";
import { eventListApi } from "../../service/apiService/association/associationEvent/associationEvent";
import { communityEnrichApi } from "../../service/apiService/association/communityEnrich/communityEnrich";
import { customerApi } from "../../service/apiService/admin/customer/customerApi";
import { categoriesApi } from "../../service/apiService/vendor/categories/categories";
import { enrichCouponsApi } from "../../service/apiService/vendor/vendorCoupons/CouponsApi";
import { productApi } from "../../service/apiService/vendor/products/products";
import { projectApi } from "../../service/generalApiService/projects/projects";
import { subCategoriesApi } from "../../service/apiService/vendor/subCategories/subCategories";
import { locationApi } from "../../service/apiService/vendor/location/location";
import { associationApi } from "../../service/apiService/association/associationHome/associationHome";
import { shopListApi } from "../../service/apiService/vendor/shops/shops";
import { ordersApi } from "../../service/apiService/vendor/orders/vendorOrders";
import { ownerApi } from "../../service/apiService/association/owners/ownersApi";
import { documentListApi } from "../../service/apiService/admin/projectDocuments/projectDocuments";
import { registrationApi } from "../../service/apiService/admin/registration/registration";
import { serviceApi } from "../../service/apiService/vendor/service/service";
import { milestoneListApi } from "../../service/apiService/admin/milestones/milestone";
import { parkingListApi } from "../../service/generalApiService/parkingService/parking/parking";
import { aminitiesListApi } from "../../service/apiService/association/amenities/amenities";
import { stockManagementApi } from "../../service/apiService/association/stockManagement/stockManagement";
import { assetManagementApi } from "../../service/apiService/association/assetManagement/assetManagement";
import toastReducer from "../reducer/message";
import vendorErrorMessageReducer from "../reducer/vendorErrorMessageReducer";
import adminErrorMessageReducer from "../reducer/adminErrorMessageReducer";
import { bannerApi } from "../../service/apiService/admin/banner/banner";
import { paymentScheduleApi } from "../../service/apiService/admin/paymentSchedule/paymentScheduleApi";
import { paymentApi } from "../../service/apiService/admin/payment/paymentApi";
import { registrationOfficeApi } from "../../service/apiService/admin/registrationOffice/registrationOffice";
import { notificationApi } from "../../service/apiService/admin/notification/notificationApi";
import { maintenanceApi } from "../../service/apiService/association/maintenance/maintenanceApi";
import { helpersApi } from "../../service/apiService/admin/helpersManagement/helpersApi";
import { helpersAssociationApi } from "../../service/apiService/association/helpersAssociationManagement/helpersAssociationApi";
import { forumReportApi } from "../../service/apiService/admin/forumReports/forumReports";
import { vendorAdApi } from "../../service/apiService/vendor/vendorAd/vendorAdApi";
import { VendorAdRequestApi } from "../../service/apiService/admin/adRequestApi/adRequestApi";
import { cutomerAppointmentApi } from "../../service/apiService/admin/customerAppointment/customerAppointmentApi";
import { assureApi } from "../../service/apiService/admin/assure/assureApi";
import { galleryApi } from "../../service/apiService/admin/customerGallery/customerGalleryApi";
import { categorySubCategoryApi } from "../../service/apiService/admin/category_SubCategoryApi/category_subcategoryApi";
import { referraleApi } from "../../service/apiService/admin/referral/referral";
import { enquiryListApi } from "../../service/apiService/admin/fittedHomeEnquiryList/fittedHomeEnquiryList";
import { testimonialListApi } from "../../service/apiService/admin/testimonialList/testimonialList";
import { securityVisitorApi } from "../../service/apiService/security/visitor/visitorApi";
import { ticketApi } from "../../service/apiService/admin/ticket/ticketApi";
import { casaCommissionApi } from "../../service/generalApiService/casaCommission/casaCommission";
import { vendorListReducer } from "../../service/apiService/admin/vendor/vendorList";
import { userListApi } from "../../service/apiService/admin/usersList/userList";
import { permissionApi } from "../../service/generalApiService/permission/permission";
import { profileApi } from "../../service/apiService/admin/profile/profileList";
import { rolesApi } from "../../service/apiService/admin/roles/roles";
import { supportTeamApi } from "../../service/apiService/admin/supportTeam/supportTeam";
import { dashboardApi } from "../../service/apiService/admin/adminDashboard/adminDashboardApi";
import { departmentApi } from "../../service/apiService/admin/department/department";
import { propertyServicesApi } from "../../service/apiService/admin/propertyServices/propertyServices";
import { bankApi } from "../../service/apiService/admin/bankApi/bankListApi";
import { securityDashboardApi } from "../../service/apiService/security/securityDashboard/securityDashboard";
import { associationDashboardApi } from "../../service/apiService/association/associationDashboard/associationDashboardApi";
import { vendorDashboardApi } from "../../service/apiService/vendor/vendorDashBoard/vendorDashBoard";
import { reportApi } from "../../service/apiService/admin/report/reportApi";


const rootReducer = combineReducers({
  loginReducer: loginReducer,
  ErrorMessageReducer: errorMessageReducer,
  associationErrorMessageReducer: associationErrorMessageReducer,
  toastReducer:toastReducer,
  vendorErrorMessageReducer: vendorErrorMessageReducer,
  adminErrorMessageReducer: adminErrorMessageReducer,
  // associationErrorMessageReducer:associationErrorMessageReducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [shopListApi.reducerPath]: shopListApi.reducer,
  [enrichCouponsApi.reducerPath]:enrichCouponsApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [subCategoriesApi.reducerPath]: subCategoriesApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [associationApi.reducerPath]: associationApi.reducer,
  [eventListApi.reducerPath]: eventListApi.reducer,
  [communityEnrichApi.reducerPath]: communityEnrichApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [ownerApi.reducerPath]: ownerApi.reducer,
  [documentListApi.reducerPath]: documentListApi.reducer,
  [registrationApi.reducerPath]: registrationApi.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [milestoneListApi.reducerPath]: milestoneListApi.reducer,
  [parkingListApi.reducerPath]: parkingListApi.reducer,
  [aminitiesListApi.reducerPath]: aminitiesListApi.reducer,
  [stockManagementApi.reducerPath]: stockManagementApi.reducer,
  [assetManagementApi.reducerPath]: assetManagementApi.reducer,
  [bannerApi.reducerPath]: bannerApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [paymentScheduleApi.reducerPath]: paymentScheduleApi.reducer,
  [registrationOfficeApi.reducerPath]: registrationOfficeApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [maintenanceApi.reducerPath]: maintenanceApi.reducer,
  [helpersApi.reducerPath]: helpersApi.reducer,
  [helpersAssociationApi.reducerPath]: helpersAssociationApi.reducer,
  [forumReportApi.reducerPath]: forumReportApi.reducer,
  [vendorAdApi.reducerPath]: vendorAdApi.reducer,
  [VendorAdRequestApi.reducerPath]: VendorAdRequestApi.reducer,
  [cutomerAppointmentApi.reducerPath]: cutomerAppointmentApi.reducer,
  [assureApi.reducerPath]: assureApi.reducer,
  [galleryApi.reducerPath]: galleryApi.reducer,
  [categorySubCategoryApi.reducerPath]: categorySubCategoryApi.reducer,
  [referraleApi.reducerPath]: referraleApi.reducer,
  [enquiryListApi.reducerPath]: enquiryListApi.reducer,
  [testimonialListApi.reducerPath]: testimonialListApi.reducer,
  [ticketApi.reducerPath]: ticketApi.reducer,
  [securityVisitorApi.reducerPath]: securityVisitorApi.reducer,
  [casaCommissionApi.reducerPath]: casaCommissionApi.reducer,
  [vendorListReducer.reducerPath]:vendorListReducer.reducer,
  [userListApi.reducerPath]:userListApi.reducer,
  [permissionApi.reducerPath]:permissionApi.reducer,
  [profileApi.reducerPath]:profileApi.reducer,
  [rolesApi.reducerPath]:rolesApi.reducer,
  [supportTeamApi.reducerPath]:supportTeamApi.reducer,
  [dashboardApi.reducerPath]:dashboardApi.reducer,
  [departmentApi.reducerPath]:departmentApi.reducer,
  [propertyServicesApi.reducerPath]:propertyServicesApi.reducer,
  [bankApi.reducerPath]:bankApi.reducer,
  [securityDashboardApi.reducerPath]:securityDashboardApi.reducer,
  [associationDashboardApi.reducerPath]:associationDashboardApi.reducer,
  [vendorDashboardApi.reducerPath]:vendorDashboardApi.reducer,
  [reportApi.reducerPath]:reportApi.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware().concat([
      loginApi.middleware,
      shopListApi.middleware,
      enrichCouponsApi.middleware,
      categoriesApi.middleware,
      productApi.middleware,
      projectApi.middleware,
      subCategoriesApi.middleware,
      locationApi.middleware,
      associationApi.middleware,
      eventListApi.middleware,
      communityEnrichApi.middleware,
      customerApi.middleware,
      ordersApi.middleware,
      ownerApi.middleware,
      documentListApi.middleware,
      registrationApi.middleware,
      serviceApi.middleware,
      milestoneListApi.middleware,
      parkingListApi.middleware,
      aminitiesListApi.middleware,
      assetManagementApi.middleware,
      bannerApi.middleware,
      paymentScheduleApi.middleware,
      paymentApi.middleware,
      registrationOfficeApi.middleware,
      stockManagementApi.middleware,
      notificationApi.middleware,
      maintenanceApi.middleware,
      helpersApi.middleware,
      helpersAssociationApi.middleware,
      forumReportApi.middleware,
      vendorAdApi.middleware,
      VendorAdRequestApi.middleware,
      cutomerAppointmentApi.middleware,
      assureApi.middleware,
      galleryApi.middleware,
      categorySubCategoryApi.middleware,
      referraleApi.middleware,
      enquiryListApi.middleware,
      testimonialListApi.middleware,
      securityVisitorApi.middleware,
      ticketApi.middleware,
      casaCommissionApi.middleware,
      vendorListReducer.middleware,
      userListApi.middleware,
      permissionApi.middleware,
      profileApi.middleware,
      rolesApi.middleware,
      supportTeamApi.middleware,
      dashboardApi.middleware,
      departmentApi.middleware,
      propertyServicesApi.middleware,
      bankApi.middleware,
      securityDashboardApi.middleware,
      associationDashboardApi.middleware,
      vendorDashboardApi.middleware,
      reportApi.middleware,
    ]),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;