import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const departmentApi = createApi({
    reducerPath: "departmentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem("dashboard-token")}`
            );
            return headers;
        },
    }),
    tagTypes: ["department"],
    endpoints: (builder) => ({
        getDepartmentSubCategories: builder.query<any, void>({
            query: () => ({
                url: "/subquery-type/",
                method: "GET",
                //   params: {
                //     search: searchName,
                //     page: String(pages),
                //     project_id:projectId
                //   },
            }),
            providesTags: ["department"],
        }),
        getDepartmentList: builder.query<any, { search: any, page: any }>({
            query: ({ search, page }) => ({
                url: "/department/",
                method: "GET",
                params: {
                    search: search,
                    page: String(page),
                    pagination: 1
                },
            }),
            providesTags: ["department"],
        }),
        getSingleDepartmentList: builder.query<any, { id: any }>({
            query: ({ id }) => ({
                url: `/department/get_one/`,
                method: "GET",
                params:{
                    department_id:id
                }
            }),
            providesTags: ["department"],
        }),
        addDeaprtment: builder.mutation<any, any>({///department/?pagination=1&search=
            query: (departmentData) => ({
                url: `/department/`,
                method: "POST",
                body: departmentData,
            }),
            invalidatesTags: ["department"],
        }),
        editDeaprtment: builder.mutation<any, { departmentData: any, id: any }>({///department/?pagination=1&search=
            query: ({ departmentData, id }) => ({
                url: `/department/${id}/`,
                method: "PUT",
                body: departmentData,
            }),
            invalidatesTags: ["department"],
        }),
        deleteDeaprtment: builder.mutation<any, { id: any }>({///department/?pagination=1&search=
            query: ({ id }) => ({
                url: `/department/${id}/`,
                method: "DELETE",
                //   body:departmentData,
            }),
            invalidatesTags: ["department"],
        }),
    }),
});
export const {
    useGetDepartmentSubCategoriesQuery,
    useGetDepartmentListQuery,
    useGetSingleDepartmentListQuery,
    useEditDeaprtmentMutation,
    useDeleteDeaprtmentMutation,
    useAddDeaprtmentMutation
} = departmentApi;
