import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const milestoneListApi = createApi({
    reducerPath: "milestone",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['milestones'],
    endpoints: (builder) => ({
        getAllListOfMilestonestatus: builder.query<any, void>({
            query: () => ({
                url: "/project-milestone-status/",
                method: "GET",
            }),
            providesTags: ['milestones']
        }),
        getAllListOfMilestone: builder.query<any, {pages:number,searchName:any,projectId:any}>({
            query: ({pages,searchName,projectId}) => ({
              url: "/project-milestone/",
              method: "GET",
              params: {
                page: String(pages),
                search:searchName,
                project_id:projectId
              },
            }),
            providesTags: ["milestones"],
          }),
        
        AddMilestone: builder.mutation<any, object>({
            query: (joinMilestoneInfo: object) => ({
              url: "/project-milestone/",
              method: "POST",
              body: joinMilestoneInfo,
            }),
            invalidatesTags: ["milestones"],
          }),
      
        deleteMilestone: builder.mutation<any, any>({
          query: (milestoneId: any) => ({
            url: `/project-milestone/delete_milestone/`,
            method: "DELETE",
            params: {
              milestone_id: milestoneId,
            },
          }),
          invalidatesTags: ['milestones']
        }),
        
    
        updateMilestone: builder.mutation<any, { milestone_id: number }>({
          query: (milestoneData:any) => ({
            url: `/project-milestone/update_milestone/`,
            method: 'PUT',
            body: milestoneData.formData,
            params: { milestone_id: milestoneData.id }, 
          }),
          invalidatesTags: ['milestones'],
        }),
        getListOfCustomer: builder.query<any, any>({
            query: (project:any) => ({
                url: "/customers/",
                method: "GET",
                params: {
                  paginated:false,
                  project:project
                },
            }),
            providesTags: ['milestones']
        }),

        getOneMilestone: builder.query<any,any>({
          query: (id:any) => ({
            url: `/project-milestone/${id}/`,
            method: "GET"
            
          }),
          providesTags: ['milestones']
        }),
        milestoneUpdate: builder.mutation<any,{id:any,status:any}>({
          query: ({id,status}) => ({
            url: `project-milestone/update_milestone/?milestone_id=${id}`,
            method: "PUT",
            body:status
          }),
          invalidatesTags: ['milestones']
        }),
    }),
});

export const { useGetAllListOfMilestoneQuery,useGetAllListOfMilestonestatusQuery,useGetListOfCustomerQuery ,useAddMilestoneMutation,useDeleteMilestoneMutation,useUpdateMilestoneMutation,useGetOneMilestoneQuery ,useMilestoneUpdateMutation} = milestoneListApi;