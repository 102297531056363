import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const dashboardApi = createApi({
  reducerPath: "admin-dashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["admin-dashboard"],
  endpoints: (builder) => ({
    admindashboardValue: builder.query< any,{paid_cus_date:any,overdue_cus_date:any} >({
      query: ({paid_cus_date,overdue_cus_date}) => ({
        url: "/admin-dashboard/",
        method: "GET",
        params:{
          paid_cus_date:paid_cus_date,
          overdue_cus_date:overdue_cus_date
        }
      }),
      providesTags: ["admin-dashboard"],
    }),
  }),
});

export const {
  useAdmindashboardValueQuery
} = dashboardApi;
