import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const stockManagementApi = createApi({
    reducerPath: "stockManagements",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['stockManagement'],
    endpoints: (builder) => ({
        listOfStockManagement: builder.query<any, {pages:number,searchName:string}>({
            query: ({pages,searchName}) => ({
                url: "/association-stocks/",
                method: "GET",
                params: {
                    search:searchName,
                  page: String(pages),
                },
            }),
            providesTags: ['stockManagement']
        }),
        singleStockManagementData: builder.query<any, {editId:any}>({
            query: ({editId}) => ({
                url: `/association-stocks/${editId}/`,
                method: "GET",
            }),
            providesTags: ['stockManagement']
        }),
        
        addStockManagement: builder.mutation<any, any>({
            query: (formData) => ({
              url: "/association-stocks/",
              method: "POST",
              body: formData,
            }),
            invalidatesTags: ["stockManagement"],
          }),
          deleteStockManagement: builder.mutation<any, {stockId:any}>({
            query: ({stockId}) => ({
                url: `/association-stocks/${stockId}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['stockManagement']
        }),
        updateStockManagement: builder.mutation<any,{stockManagementData:any,stockId:any}>({
            query: ({stockManagementData,stockId}) => ({
                url: `/association-stocks/${stockId}/`,
                method: "PUT",
                body: stockManagementData,
            }),
            invalidatesTags: ['stockManagement']
        }),
    }),

});

export const { useListOfStockManagementQuery , useSingleStockManagementDataQuery, useAddStockManagementMutation,useDeleteStockManagementMutation,useUpdateStockManagementMutation} =stockManagementApi ;
