import { createSlice,PayloadAction, createAction } from '@reduxjs/toolkit';
export const reset = createAction('counter/reset');
export type InitialState = {
  emailErrorMessage: string;
  passwordErrorMessage: string;
  nameErrorMessage: string;
  phoneErrorMessage: string;
  discountErrorMessage: string;
  locationErrorMessage: string;
  discountTypeErrorMessage: string;
  addressErrorMessage: string;
  productErrorMessage: string;
  codeErrorMessage: string;
  productNameErrorMessage: string;
  productImageErrorMessage: string;
  productCategoryErrorMessage: string;
  productSubCategoryErrorMessage: string;
  categoryTypeErrorMessage: string;
  quantityErrorMessage: string;
  originalPriceErrorMessage: string;
  previlagePriceErrorMessage: string;
  // projectErrorMessage: string;
  productDescriptionErrorMessage: string;
  couponExpiryDateErrorMessage: string;
  couponCodeErrorMessage: string;
  couponNameErrorMessage: string;
  couponTypeErrorMessage: string;
  shopNameErrorMessage: string;
  couponStartDateErrorMessage: string;
  couponDiscountPecentageErrorMessage: string;
  branchNameErrorMessage: string;
  cityErrorMessage: string;
  pincodeErrorMessage: string;
  apiErrorMessage: string;
  eventImageErrorMessage: string;
  eventTitlesErrorMessage: string;
  venueErrorMessage: string;
  descriptionsErrorMessage: string;
  fromDateErrorMessage: string;
  toDateErrorMessage: string;
  participantsErrorMessage: string;
  availabilityErrorMessage: string
  mobileNumberErrorMessage: string
  cuoponTypeErrorMessage: string;
  bookingFormErrorMessage:string;
  maintainceErrorMessage:string
  recipteErrorMessage:string;
  rentalErrorMessage:string;
  allotmentErrorMessage:string
  unitNumberErrorMessage: string
  projectErrorMessage:string;
  milestoneStartDateErrorMessage: string;
  milestoneEndDateErrorMessage: string;
  milestoneEstimatedErrorMessage: string;
  milestoneCustomerErrorMessage: string;
  milestoneProjectErrorMessage: string;
  milestoneImageErrorMessage: string;
  documentTypeErrorMessage:string
  parkingProjectErrorMessage:string
  parkingVisitorErrorMessage:string
  offerTypeErrorMessage:string
  statusErrorMessage:string
};
const initialState: InitialState = {
  milestoneCustomerErrorMessage:"",
  parkingVisitorErrorMessage:"",
  parkingProjectErrorMessage:"",
  milestoneProjectErrorMessage:"",
  milestoneImageErrorMessage:"",
  milestoneStartDateErrorMessage:"",
  milestoneEndDateErrorMessage:"",
  milestoneEstimatedErrorMessage:"",
  emailErrorMessage: "",
  maintainceErrorMessage:"",
  bookingFormErrorMessage:"",
  passwordErrorMessage: "",
  nameErrorMessage: "",
  phoneErrorMessage: "",
  discountErrorMessage: "",
  locationErrorMessage: "",
  discountTypeErrorMessage: "",
  addressErrorMessage: "",
  productErrorMessage: "",
  codeErrorMessage: "",
  productNameErrorMessage: "",
  productImageErrorMessage: "",
  productCategoryErrorMessage: "",
  productSubCategoryErrorMessage: "",
  categoryTypeErrorMessage: "",
  quantityErrorMessage: "",
  originalPriceErrorMessage: "",
  previlagePriceErrorMessage: "",
  projectErrorMessage: "",
  productDescriptionErrorMessage: "",
  couponExpiryDateErrorMessage: "",
  couponCodeErrorMessage: "",
  couponNameErrorMessage: "",
  couponTypeErrorMessage: "",
  shopNameErrorMessage: "",
  couponDiscountPecentageErrorMessage: "",
  cityErrorMessage: "",
  branchNameErrorMessage: "",
  pincodeErrorMessage: "",
  apiErrorMessage: "",
  cuoponTypeErrorMessage: "",
  couponStartDateErrorMessage: "",
  eventImageErrorMessage: "",
  eventTitlesErrorMessage: "",
  venueErrorMessage: "",
  descriptionsErrorMessage: "",
  fromDateErrorMessage: "",
  toDateErrorMessage: "",
  participantsErrorMessage:"",
  availabilityErrorMessage:"",
  mobileNumberErrorMessage:"",
  recipteErrorMessage:"",
  rentalErrorMessage:"",
  allotmentErrorMessage:"",
  unitNumberErrorMessage: "",
  statusErrorMessage:"",
  documentTypeErrorMessage:"",
  offerTypeErrorMessage:""
};
const ErrorSlice = createSlice({
  name: "ErrorMessage",
  initialState,
  reducers: {
    setEmailErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.emailErrorMessage = action.payload;
    },
    setDocumentTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.documentTypeErrorMessage = action.payload;
    },
    setApiErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.apiErrorMessage = action.payload;
    },
    setPasswordErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.passwordErrorMessage = action.payload;
    },
    setNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.nameErrorMessage = action.payload;
    },
    setPhoneErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.phoneErrorMessage = action.payload;
    },
    setDiscountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.discountErrorMessage = action.payload;
    },
    setLocationErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.locationErrorMessage = action.payload;
    },
    setDiscountTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.discountTypeErrorMessage = action.payload;
    },
    setAddressErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.addressErrorMessage = action.payload;
    },
    setProductErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productErrorMessage = action.payload;
    },
    setCodeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.codeErrorMessage = action.payload;
    },
    setProductNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productNameErrorMessage = action.payload;
    },
    setProductImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productImageErrorMessage = action.payload;
    },
    setProductCategoryErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productCategoryErrorMessage = action.payload;
    },
    setProductSubCategoryErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productSubCategoryErrorMessage = action.payload;
    },
    setCategoryTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.categoryTypeErrorMessage = action.payload;
    },
    setQuantityErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.quantityErrorMessage = action.payload;
    },
    setOriginalPriceErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.originalPriceErrorMessage = action.payload;
    },
    setPrevilagePriceErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.previlagePriceErrorMessage = action.payload;
    },
    setProjectErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.projectErrorMessage = action.payload;
    },
    setProductDescriptionErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productDescriptionErrorMessage = action.payload;
    },
    setCouponCodeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponCodeErrorMessage = action.payload;
    },
    setCouponExpiryDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponExpiryDateErrorMessage = action.payload;
    },
    setCouponNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponNameErrorMessage = action.payload;
    },
    setCouponTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponTypeErrorMessage = action.payload;
    },
    setShopNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.shopNameErrorMessage = action.payload;
    },
    setCouponStartDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponStartDateErrorMessage = action.payload;
    },
    setcouponDiscountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponDiscountPecentageErrorMessage = action.payload;
    },
    setBranchNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.branchNameErrorMessage = action.payload;
    },
    setCityErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.cityErrorMessage = action.payload;
    },
    setPincodeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.pincodeErrorMessage = action.payload;
    },


    setCuoponTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.cuoponTypeErrorMessage = action.payload;
    },

    setEventImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.eventImageErrorMessage = action.payload;
    },
    setEventTitleErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.eventTitlesErrorMessage = action.payload;
    },
    setEventVenueErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.venueErrorMessage = action.payload;
    },
    setEventDescriptionErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.descriptionsErrorMessage = action.payload;
    },
    setEventFromDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.fromDateErrorMessage = action.payload;
    },
    setEventToDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.toDateErrorMessage = action.payload;
    },
    setParticipantCountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.participantsErrorMessage = action.payload;
    },
    setAvailabilityErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.availabilityErrorMessage = action.payload;
    },
    setStatusErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.statusErrorMessage = action.payload;
    },
    setMobNumErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.mobileNumberErrorMessage = action.payload;
    },
    setBookingFormErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.bookingFormErrorMessage = action.payload;
    },
    setAllotmentErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.allotmentErrorMessage = action.payload;
    },
    setRentalErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.rentalErrorMessage = action.payload;
    },
    setRecipteErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.recipteErrorMessage = action.payload;
    },
    setMaintainceErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.maintainceErrorMessage = action.payload;
    },
   
    setUnitNumberErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.unitNumberErrorMessage = action.payload;
    },
   
    setOfferTypeErrorMessage: (state: InitialState,
      action: PayloadAction<string>
  ) => {
      state.offerTypeErrorMessage = action.payload;

  },
    
  
 
      setMilestoneStartDateErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.milestoneStartDateErrorMessage = action.payload;
      },
      setMilestoneEndDateErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.milestoneEndDateErrorMessage = action.payload;
      },
      setMilestoneEstimatedErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.milestoneEstimatedErrorMessage = action.payload;
      },
      setMilestoneCustomerErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.milestoneCustomerErrorMessage = action.payload;
      },
      setMilestoneProjectErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.milestoneProjectErrorMessage = action.payload;
      },
      setMilestoneImageErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.milestoneImageErrorMessage = action.payload;
      },
      setParkingProjectErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.parkingProjectErrorMessage = action.payload;
      },
      setParkingVisitorErrorMessage: (
        state: InitialState,
        action: PayloadAction<string>
      ) => {
        state.parkingVisitorErrorMessage = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, (state) => {
      return initialState;
    });
  },
});
export default ErrorSlice.reducer;
export const {
  setMilestoneCustomerErrorMessage,
  setParkingVisitorErrorMessage,
  setParkingProjectErrorMessage,
  setMilestoneProjectErrorMessage,
  setMilestoneImageErrorMessage,
  setMilestoneStartDateErrorMessage,
  setMilestoneEndDateErrorMessage,
  setMilestoneEstimatedErrorMessage,
  setAvailabilityErrorMessage,
  setRentalErrMessage,
  setMaintainceErrMessage,
  setRecipteErrMessage,
  setAllotmentErrMessage,
  setBookingFormErrMessage,
  setEmailErrorMessage,
  setMobNumErrMessage,
  setPasswordErrorMessage,
  setNameErrorMessage,
  setPhoneErrorMessage,
  setDiscountErrorMessage,
  setLocationErrorMessage,
  setDiscountTypeErrorMessage,
  setAddressErrorMessage,
  setProductErrorMessage,
  setCodeErrorMessage,
  setProductNameErrorMessage,
  setProductImageErrorMessage,
  setProductSubCategoryErrorMessage,
  setProductCategoryErrorMessage,
  setCategoryTypeErrorMessage,
  setQuantityErrorMessage,
  setOriginalPriceErrorMessage,
  setPrevilagePriceErrorMessage,
  setProductDescriptionErrorMessage,
  setBranchNameErrorMessage,
  setPincodeErrorMessage,
  setCityErrorMessage,
  setApiErrorMessage,
  setCouponCodeErrorMessage,
  setCouponExpiryDateErrorMessage,
  setCouponTypeErrorMessage,
  setCouponNameErrorMessage,
  setShopNameErrorMessage,
  setCouponStartDateErrorMessage,
  setEventImageErrorMessage,
  setEventTitleErrorMessage,
  setEventVenueErrorMessage,
  setEventDescriptionErrorMessage,
  setEventFromDateErrorMessage,
  setEventToDateErrorMessage,
  setParticipantCountErrorMessage,
  setcouponDiscountErrorMessage,
  setProjectErrorMessage,
  setUnitNumberErrorMessage,
  setDocumentTypeErrorMessage,
  setOfferTypeErrorMessage,
  setStatusErrorMessage

} = ErrorSlice.actions;
