import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";
export const referraleApi = createApi({
  reducerPath: "referraleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["referral"],
  endpoints: (builder) => ({
    getListOfReferrals: builder.query<any,any>({
      query: ({ pages, searchName ,projectId,nonCasa }) => ({
        url: "/staff-referral/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          project_id: projectId,
          admin:1,
          nonCasa:nonCasa
        },
      }),
      providesTags: ["referral"],
    }),
 
    editReferral: builder.mutation< any, { id: any,statusObj:any } >({
      query: ({ id,statusObj }) => ({
        url: `/staff-referral/${id}/`,
        method: "PUT",
        body: statusObj,
       
      }),
      invalidatesTags: ["referral"],
    }),
   
  }),
});

export const {
useGetListOfReferralsQuery,
useEditReferralMutation
} = referraleApi;
