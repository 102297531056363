import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const projectApi = createApi({
    reducerPath: "projectApi",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
        }),
    tagTypes: ['project-list'],
    endpoints: (builder) => ({
        getAllListOfProjects: builder.query<any,void>({
            query: () => ({
                url: "/project-list/",
                method: "GET",
            }),
        }),
        getAllListOfCustomer: builder.query<any,{projectId:any}>({
            query: ({projectId}) => ({
                url: "/customers/",
                method: "GET",
                params:{
                    project:projectId,
                    paginated:false
                }
            }),
        }),
    }),
});

export const { useGetAllListOfProjectsQuery,useGetAllListOfCustomerQuery} = projectApi;
