import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";
export const propertyServicesApi = createApi({
  reducerPath: "propertyServices",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["propertyServices"],
  endpoints: (builder) => ({
    getListOfPropertyServices: builder.query<any,any>({
      query: ({ pages, searchName  }) => ({
        url: "/customer-property-services/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
        },
      }),
      providesTags: ["propertyServices"],
    }),
 
    addPropertyServices: builder.mutation< any, any >({
      query: (data) => ({
        url: `/customer-property-services/`,
        method: "POST",
        body: data,
       
      }),
      invalidatesTags: ["propertyServices"],
    }),
    updatePropertyService: builder.mutation< any, {id:any,data:any} >({
      query: ({id,data}) => ({
        url: `/customer-property-services/${id}/`,
        method: "PUT",
        body: data,
       
      }),
      invalidatesTags: ["propertyServices"],
    }),
    deletePropertyService: builder.mutation< any, {id:any} >({
      query: ({id}) => ({
        url: `/customer-property-services/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["propertyServices"],
    }),
    editPropertyServices: builder.query< any, {id:any} >({
      query: ({id}) => ({
        url: `/customer-property-services/${id}/`,
        method: "GET",
       
      }),
      providesTags: ["propertyServices"],
    }),
   
  }),
});

export const {
    useGetListOfPropertyServicesQuery,
    useAddPropertyServicesMutation,
    useEditPropertyServicesQuery,
    useUpdatePropertyServiceMutation,
    useDeletePropertyServiceMutation

} = propertyServicesApi;
