import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem("dashboard-token")}`
            );
            return headers;
        },
    }),
    tagTypes: ["profile"],
    endpoints: (builder) => ({
        addProfileGroup: builder.mutation<any, {userInfo:any,id:string}>({
            query: ({userInfo,id}) => ({
                url: "/group_perm/",
                method: "POST",
                body: userInfo,
                params:{
                    department_id:id
                }
            }),
            invalidatesTags: ["profile"],
        }),
        editProfileGroup: builder.mutation<any, {userInfo:any,id:string,groupId:any}>({
            query: ({userInfo,id,groupId}) => ({
                url: `/group_perm/update_group/`,
                method: "PUT",
                body: userInfo,
                params:{
                    department_id:id,
                    group_id:groupId
                }
            }),
            invalidatesTags: ["profile"],
        }),
        getListOfProfileGroups: builder.query<any, {page:any,search:any}>({
            query: ({page,search}) => ({
                url: `/group_perm/`,
                method: "GET",
                params:{
                    page:page,
                    search:search
                }
            }),
            providesTags: ["profile"],
        }),

        getSingleProfilePermissions: builder.query<any, {  groupId: any }>({
            query: ({  groupId }) => ({
                url: "/group_perm/get_one/",
                method: "GET",
                params: {
                    group_id: groupId,
                },
            }),
            providesTags: ["profile"],
        }),
        getInitialProfilePermissions: builder.query<any,void>({
            query: () => ({
                url: "/group_perm/",
                method: "GET",
                params: {
                    permissions: 1,
                },
            }),
            providesTags: ["profile"],
        }),
        deleteProfileGroup: builder.mutation<any, {id:string}>({
            query: ({id}) => ({
                url: `/group_perm/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ["profile"],
        }),
        getStaffsDepartmentsList: builder.query<any, void>({
            query: () => ({
                url: "/department/",
                method: "GET",

            }),
        }),
    }),
});

export const {
    useAddProfileGroupMutation,
    useEditProfileGroupMutation,
    useGetListOfProfileGroupsQuery,
    useGetSingleProfilePermissionsQuery,
    useGetInitialProfilePermissionsQuery,
    useDeleteProfileGroupMutation,
    useGetStaffsDepartmentsListQuery,
} = profileApi;
