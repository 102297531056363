import styles from './propertyServices.module.scss'
import { useEffect, useMemo, useState } from 'react';
import { AiTwotoneDelete } from 'react-icons/ai'
import { BiEditAlt } from 'react-icons/bi'
import { useCustomNavigate } from '../../../lib/navigationHook';
import { useAppDispatch, useAppSelector } from '../../../base/hooks/hooks';
import { doNotify } from '../../../base/utils/utils';
import { PATH } from '../../../route/path';
import { useGetAllListOfProjectsQuery } from '../../../service/generalApiService/projects/projects';
import Search from 'antd/es/input/Search';
import { Badge, Modal, Select, Popconfirm, Table, Tooltip, Pagination, Button ,Popover} from 'antd';
import { useParams } from 'react-router-dom';
import { checkPropertyServiceError, doCheckPermissionAdd, doCheckPermissionDelete, doCheckPermissionEdit, propertyServicesInitialState, propertyServicesValidation, propertyServicesValidation2 } from './propertyServicesController';
import { Adminreset } from '../../../base/reducer/adminErrorMessageReducer';
import { doValidateEbTaxAmount, doValidateEbTaxContactUs, doValidatePropertyTaxAmount, doValidatePropertyTaxConatctus, doValidatePropertyTaxReqDoc, doValidateEbTaxReqDoc, doValidatePropertyServicesProject } from '../../../base/utils/adminUtils';
import { HiOutlineMap } from 'react-icons/hi2';
import { TagsInput } from "react-tag-input-component";
import { useAddPropertyServicesMutation, useDeletePropertyServiceMutation, useEditPropertyServicesQuery, useGetListOfPropertyServicesQuery,useUpdatePropertyServiceMutation } from '../../../service/apiService/admin/propertyServices/propertyServices';
import { Switch } from 'antd';
import { MdMiscellaneousServices } from 'react-icons/md';
import { useAssociationPermissionQuery } from '../../../service/generalApiService/permission/permission';


function PropertyService() {
  const { edit } = useParams();
  let editId: any = edit?.split("=")
  const id = editId?.length > 0 && editId[1]
  const dispatch = useAppDispatch()
  const navigate = useCustomNavigate()
  const [pages, setPages] = useState(1)
  const [searchName, setSearchName] = useState('')
  const [project, setProject] = useState("")
  const [propertyServices, setPropertyServices] = useState(propertyServicesInitialState)
  const [hideBasedOnDep, setHideBasedOnDep] = useState('')
  const { data: propertyServicesList, isSuccess: propertyServicesListApiSuccess, isLoading: propertyServicesListApiLoading } = useGetListOfPropertyServicesQuery({ pages, searchName });
  const { data: editDatapropertyService, isSuccess: editDatapropertyServiceListApiSuccess, isLoading: editDatapropertyServiceListApiLoading } = useEditPropertyServicesQuery({ id: id }, { skip: !id });
  const [addPropertyService, { isLoading: addPropertyServiceIsLoading }]: any = useAddPropertyServicesMutation()
  const [updatePropertyService, { isLoading:updatePropertyServiceIsLoading }]: any = useUpdatePropertyServiceMutation()
  const [deletePropertyService, { isLoading:deletePropertyServiceIsLoading }]: any = useDeletePropertyServiceMutation()
  const { data: projectList, isSuccess: projectListApiSuccess, isLoading: projectListApiIsLoading } = useGetAllListOfProjectsQuery();
  const { data: permissionList, isLoading: permissionListApiIsLoading } = useAssociationPermissionQuery()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const errors: any = useAppSelector((state: any) => state.adminErrorMessageReducer);
  console.log(propertyServices, "propertyServices")

  const storeData = (event: any) => {
    const { name, value } = event.target;
    setPropertyServices((prev) => ({ ...prev, [name]: value }))
    propertyServicesValidation(event, dispatch)
  }
  const storeDataEbTaxReqDoc = (values: any) => {
    setPropertyServices({ ...propertyServices, ebTaxReqDoc: values })
    // propertyServicesValidation2(values, dispatch, "ebTaxReqDoc")
  }
  const storeDataPropTaxReqDoc = (values: any) => {
    setPropertyServices({ ...propertyServices, propTaxReqDoc: values })
    // propertyServicesValidation2(values, dispatch, "propTaxReqDoc")
  }


  const doFormArrayOfObject = (apiValue: any) => {
    if (propertyServicesListApiSuccess) {
      const ResultArr: any = []
      apiValue?.result?.data?.results?.forEach((list: any) => {
        ResultArr.push({
          project: list?.project,
          id: list?.id,
          ebTaxAmount: list?.EB_Tax?.eb_tax_amount,
          ebTaxContactUs: list?.EB_Tax?.eb_tax_contact_us,
          ebTaxReqDocuments: list?.EB_Tax?.eb_tax_req_documents,
          propertyTaxAmount: list?.Property_Tax?.prop_tax_amount,
          propertyTaxContactUs: list?.Property_Tax?.prop_tax_contact_us,
          propertyTaxReqDocuments: list?.Property_Tax?.prop_tax_req_documents
        })
      })
      return ResultArr
    }
  }

  const tableList = useMemo(() => doFormArrayOfObject(propertyServicesList), [propertyServicesList]);

  useEffect(() => {
    if (id) {
      if (editDatapropertyServiceListApiSuccess &&editDatapropertyService.result.data.id==id ) {
        setIsModalOpen(true)
        const editPropertyService = editDatapropertyService.result.data;
        setPropertyServices({...propertyServices,ebTaxReqDoc:editPropertyService?.eb_tax_req_documents,ebTaxAmount:editPropertyService?.eb_tax_amount,ebTaxContactUs:editPropertyService?.eb_tax_contact_us,
        propTaxReqDoc:editPropertyService?.prop_tax_req_documents ,propTaxAmount:editPropertyService?.prop_tax_amount,propTaxContactUs:editPropertyService?.prop_tax_contact_us,isPaid:editPropertyService?.is_paid_service==="1"?true:false,
        project:editPropertyService?.project
        })
      }
    }
  }, [editDatapropertyServiceListApiSuccess])

  const addPropertyServices = async () => {
    if (checkPropertyServiceError(dispatch, propertyServices)) {
      const apiData = {
        eb_tax_req_documents: propertyServices.ebTaxReqDoc,
        eb_tax_amount: propertyServices.ebTaxAmount,
        eb_tax_contact_us: propertyServices.ebTaxContactUs,
        prop_tax_req_documents: propertyServices.propTaxReqDoc,
        prop_tax_amount: propertyServices.propTaxAmount,
        prop_tax_contact_us: propertyServices.propTaxContactUs,
        is_paid_service: propertyServices.isPaid?"1":"0",
        project_id: propertyServices.project
      }
      if(id){
        await updatePropertyService({id:id,data:apiData}).unwrap().then((success: any) => {
          setPropertyServices(propertyServicesInitialState)
          setIsModalOpen(false)
          doNotify("success", "Property services Updated successfully", dispatch)
        }).catch((err: any) => {
          doNotify("error", err?.data?.result?.message || "Failed to update property services", dispatch)
        })
      }else{
        await addPropertyService(apiData).unwrap().then((success: any) => {
          setPropertyServices(propertyServicesInitialState)
          setIsModalOpen(false)
          doNotify("success", "Property services added successfully", dispatch)
        }).catch((err: any) => {
          doNotify("error", err?.data?.result?.message || "Failed to add property services", dispatch)
        })
      }
    }
  };

  const doEdit = (id: any) => {
    if (id) {
      navigate(`${PATH.ADMIN_PROPERTY_SERVICES}/edit-property-services/edit=${id}`)
    } else {
      doNotify("error", "Id was missing while trying to edit", dispatch)
    }

  }

  const doDeleteFunc = async (id: any) => {
    await deletePropertyService({ id: id }).unwrap()
      .then((payload: any) => doNotify("success", "Successfully deleted property service", dispatch))
      .catch((error: any) => doNotify("error", error?.data?.result?.message || "Failed to delete property service", dispatch))
  }

  const handleCancel = () => {
    setPropertyServices(propertyServicesInitialState)
    setIsModalOpen(false)
    dispatch(Adminreset())
    navigate(PATH.ADMIN_PROPERTY_SERVICES)
  }
  const columns: any = [
    {
      title: 'S No',
      dataIndex: 'S_No',
      key: 'S_No',

      render: (title: any, data: any, index: any) => {

        return (
          <span className={styles.actionTicketTableHeader}>{index + 1}</span>
        )

      },
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      render: (title: any, key: any) => {
        return (
          <span className={styles.actionTicketTableHeader} key={key.id}>{title}</span>
        )

      }
    },
    {
      title: 'Eb Tax Amount',
      dataIndex: 'ebTaxAmount',
      key: 'ebTaxAmount',
      render: (title: any, key: any) => {

        return (
          <span className={styles.actionTicketTableHeader} key={key.id}>{title}</span>
        )

      }
    },
    {
      title: 'Eb Tax Contact Us',
      dataIndex: 'ebTaxContactUs',
      key: 'ebTaxContactUs',
      render: (title: any, key: any) => {
        return (
          <span className={styles.actionTicketTableHeader}>{title}</span>
        )
      }
    },
    {
      title: 'Eb Tax Req Documents',
      dataIndex: 'ebTaxReqDocuments',
      key: 'ebTaxReqDocuments',
      render: (title: any, key: any) => {
        return (
           <span className="viewProject">
            <Popover
              placement="rightTop"
              title="Eb Tax ReqDocuments"
              content={
                <div>
                  {title && title.map((project: any,index:any) => (
                    <div key={index}>
                      <ul className='px-3 mb-1'>
                        <li><label>{title}</label></li>
                      </ul>
                    </div>
                  ))}
                </div>
              }
              trigger="click"
            >
              <a >View Eb Tax ReqDocuments</a>
            </Popover>
          </span>
        )

      }
    },
    {
      title: 'Property Tax Amount',
      dataIndex: 'propertyTaxAmount',
      key: 'propertyTaxAmount',
      render: (title: any, key: any) => {

        return (
          <span className={`${styles.actionTicketTableHeader}`} key={key.id}>{title}</span>
        )

      }
    },
    {
      title: 'Property Tax Contact Us',
      dataIndex: 'propertyTaxContactUs',
      key: 'propertyTaxContactUs',
      render: (title: any, key: any) => {

        return (
          <span className={`${styles.actionTicketTableHeader}`} key={key.id}>{title}</span>
        )

      }
    },
    {
      title: 'Property Tax Req Documents',
      dataIndex: 'propertyTaxReqDocuments',
      key: 'propertyTaxReqDocuments',
      render: (title: any, key: any) => {

        return (
          <span className="viewProject">
            <Popover
              placement="rightTop"
              title="Property Tax ReqDocuments"
              content={
                <div>
                  {title && title.map((project: any,index:any) => (
                    <div key={index}>
                      <ul className='px-3 mb-1'>
                        <li><label>{project}</label></li>
                      </ul>
                    </div>
                  ))}
                </div>
              }
              trigger="click"
            >
              <a >View Property Tax ReqDocuments</a>
            </Popover>
          </span>
        )

      }
    },
    {
      title: 'Action', dataIndex: 'id', key: 'id', render: (id: any, data: any) => {
        return (
          <span className={`${styles.actionTicketTableHeader} ${styles.status}` + " d-flex"}>
            <>
              {doCheckPermissionEdit(permissionList)&&<div className={styles.tableBtn + " tableBtn"}>
                <div className={styles.edit + " edit"}>
                  <Tooltip title="Edit">
                    <BiEditAlt onClick={() => doEdit(id)} />
                  </Tooltip>
                </div>
              </div>}
              {doCheckPermissionDelete(permissionList)&&<div className={styles.tableBtn + " tableBtn"}>
                <div className="delete">
                  <Popconfirm
                    title="Delete Property Service"
                    description="Are you sure ? You want to delete property service?"
                    onConfirm={() => doDeleteFunc(id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Delete">
                      <AiTwotoneDelete />
                    </Tooltip>
                  </Popconfirm>
                </div>
              </div>}
            </>
          </span>
        )

      }
    },

  ];

  const projectOptions = projectList?.result?.data?.map(
    (data: any) => ({
      value: data.id,
      label: data.project_name,
    })
  );

  // useEffect(() => {
  //   if (id) {
  //     setIsModalOpen(true);
  //     dispatch(Adminreset())
  //     if (singleParkingDataApiSuccess) {
  //       const editData = singleParkingData.result.data
  //       if (id == editData?.id) {
  //         setPropertyServices({ ...propertyServices, customer: editData?.customer?.id, parkingSlotId: editData?.id, project: editData?.project?.id, vehicleNumber: editData?.vehicle_number })
  //       }

  //     }
  //   }
  // }, [id, singleParkingDataApiSuccess, singleParkingData])
console.log(doCheckPermissionAdd( permissionList),"asthdfhadhs")

  return (
    <div className={styles.stock + " casaDashboardSec"}>
      <div className={styles.pageTitleBox + " Pageitle d-sm-flex align-items-center justify-content-between "}>
        <h4 className='mb-sm-0'><MdMiscellaneousServices /><span>PROPERTY SERVICES MANAGEMENT</span></h4>
      </div>
      <div className={styles.stockList + " casaDashboardWrapper"}>
        <div className="container-fluid">
          <div className={styles.pageTitle + " casaDashboardWarapperBody"}>

            <div className='row pageHeader justify-content-between'>
              <div className='col-sm-12 col-lg-auto'>
                <h3>Property Services list</h3>
              </div>
              <div className='col-sm-12 col-lg-auto'>
                <div className='row'>
                  <div className='col-sm-12 col-lg-auto'>
                    <Search placeholder="Search by Project" className={styles.searchBar} onChange={(e: any) => { setSearchName(e.target.value); setPages(1) }} />
                  </div>
                  <div className='col-sm-12 col-lg-auto'>
                    <div className="ViewAll">
                      <button disabled={!doCheckPermissionAdd( permissionList)} onClick={() => setIsModalOpen(true)} className={styles.button + " viewAllBtn"}>Add Property Services</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <Table className="commonTable" columns={columns} dataSource={tableList} pagination={false} loading={propertyServicesListApiLoading} rowKey={"id"} />
            <Pagination defaultCurrent={pages || 1} total={propertyServicesList?.result?.data?.count} onChange={(e) => setPages(e)} showSizeChanger={false} />
          </div>
          <Modal title="Property services"
            open={isModalOpen}
            className='commonPopup'
            onOk={() => addPropertyServices()} onCancel={handleCancel}
            width={700} maskClosable={false}
            footer={[
              <Button key="submit" type="primary" onClick={() => addPropertyServices()}>
                Submit
              </Button>,
              <Button
                danger
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>,
            ]}>
            <div className="casaDashboardSec">
              <div className="casaDashboardWrapper p-0 m-0">
                <div className="casaDashboardWarapperBody">
                  <div className='formGroup'>
                    <div className="row gy-3 text-start">
                      <div className="col-md-6 mb-3">
                        <div className='fieldsGroup'>
                          <label> Project<span>*</span> </label>
                          <Select
                            showSearch
                            className={errors.propertyServicesProjectErrMessage && "error"}
                            placeholder="Select a Project"
                            optionFilterProp="children"
                            onChange={(value) => {
                              setPropertyServices({ ...propertyServices, project: value })
                              doValidatePropertyServicesProject(value, dispatch)
                            }}
                            onBlur={() => doValidatePropertyServicesProject(propertyServices.project, dispatch)}
                            value={propertyServices.project || null}
                            filterOption={(input, option: any) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={projectOptions}
                          />
                          <p className='error-msg'>{errors.propertyServicesProjectErrMessage}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='fieldsGroup'>
                          <label className={styles.formLabel + " d-block"}>Eb Tax Req Doc<span>*</span></label>
                          <TagsInput
                            name="ebTaxReqDoc"
                            value={propertyServices.ebTaxReqDoc}
                            placeHolder="Enter duplicate ticket id"
                            onChange={storeDataEbTaxReqDoc}
                            onBlur={() => doValidateEbTaxReqDoc(propertyServices.ebTaxReqDoc, dispatch)}
                          />
                          <p className='error-msg'>{errors.ebTaxReqDocErrMessage}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='fieldsGroup'>
                          <label className={styles.formLabel + " d-block"}>Eb Tax Amount<span>*</span></label>
                          <input type="number" name="ebTaxAmount" placeholder='Enter Eb tax amount' value={propertyServices.ebTaxAmount} onBlur={() => doValidateEbTaxAmount(propertyServices.ebTaxAmount, dispatch)} onChange={storeData} />
                          <p className='error-msg'>{errors.ebTaxAmountErrMessage}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='fieldsGroup'>
                          <label className={styles.formLabel + " d-block"}>Eb Tax Contact Us<span>*</span></label>
                          <input type="text" name="ebTaxContactUs" placeholder='Enter Eb tax contact' value={propertyServices.ebTaxContactUs} onBlur={() => doValidateEbTaxContactUs(propertyServices.ebTaxContactUs, dispatch)} onChange={storeData} />
                          <p className='error-msg'>{errors.ebTaxContactUsErrMessage}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='fieldsGroup'>
                          <label className={styles.formLabel + " d-block"}>Property Tax Req Doc<span>*</span></label>
                          <TagsInput
                            name="propTaxReqDoc"
                            value={propertyServices.propTaxReqDoc}
                            placeHolder="Enter duplicate ticket id"
                            onChange={storeDataPropTaxReqDoc}
                            onBlur={() => doValidatePropertyTaxReqDoc(propertyServices.propTaxReqDoc, dispatch)}
                          />
                          <p className='error-msg'>{errors.propertyTaxReqDocErrMessage}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='fieldsGroup'>
                          <label className={styles.formLabel + " d-block"}>Property Tax Amount<span>*</span></label>
                          <input type="number" name="propTaxAmount" placeholder='Enter Property Tax amount' value={propertyServices.propTaxAmount} onBlur={() => doValidatePropertyTaxAmount(propertyServices.propTaxAmount, dispatch)} onChange={storeData} />
                          <p className='error-msg'>{errors.propertyTaxAmountErrMessage}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='fieldsGroup'>
                          <label className={styles.formLabel + " d-block"}>Property Tax Contact Us<span>*</span></label>
                          <input type="tel" name="propTaxContactUs" placeholder='Enter Property tax contact us' value={propertyServices.propTaxContactUs} onBlur={() => doValidatePropertyTaxConatctus(propertyServices.propTaxContactUs, dispatch)} onChange={storeData} />
                          <p className='error-msg'>{errors.propertyTaxContactUsErrMessage}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='fieldsGroup'>
                          <label className={styles.formLabel + " d-block"}>Is Paid Service<span>*</span></label>
                          <Switch className={styles.addTenantToggle} checked={propertyServices.isPaid} onChange={(e) => setPropertyServices({ ...propertyServices, isPaid: e })} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default PropertyService;
