
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const VendorAdRequestApi = createApi({
  reducerPath: "adRequestApiss",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["adRequestApiss"],
  endpoints: (builder) => ({
    getListOfAdRequestList: builder.query<any, { pages: any; searchName: any; }>({
      query: ({ pages, searchName }) => ({
        url: "/vendor-advertisement/?admin=1",
        method: "GET",
        params: {
          search: searchName,
          page: pages,
        },
      }),
      providesTags: ["adRequestApiss"],
    }),
    singleVendorAd: builder.query<any, {id:any }>({
      query: ({id }) => ({
        url: `/vendor-advertisement/${id}/`,
        method: "GET",
      }),
      providesTags: ["adRequestApiss"],
    }),
    updateVendorAd: builder.mutation<any, {id:any,data:any }>({
      query: ({id,data }) => ({
        url: `/vendor-advertisement/${id}/`,
        method: "PUT",
        body:data
      }),
      invalidatesTags: ["adRequestApiss"],
    }),
  }),
});
export const { useGetListOfAdRequestListQuery,useSingleVendorAdQuery,useUpdateVendorAdMutation } = VendorAdRequestApi;
