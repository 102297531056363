import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const forumReportApi = createApi({
  reducerPath: "forumReports",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["forumReports"],
  endpoints: (builder) => ({
    getListOfForumReports: builder.query<any, { pages: any; searchName: any; projectId: any }>({
      query: ({ pages, searchName, projectId }) => ({
        url: "/forum/",
        method: "GET",
        params: {
          project_id: projectId,
          search: searchName,
          page: pages,
        },
      }),
      providesTags: ["forumReports"],
    }),
    deleteReport: builder.mutation<any,any>({
      query: (id:any) => ({
        url: `/forum/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["forumReports"],
    }),
  }),
});
export const { useGetListOfForumReportsQuery ,useDeleteReportMutation } = forumReportApi;
