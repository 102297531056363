import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
export const VendorReset = createAction("counter/reset");

type InitialState = {
  //location
  branchNameErrorMessage: string;
  cityErrorMessage: string;
  pincodeErrorMessage: string;
  locationErrorMessage: string;
  //Coupon
  couponCodeErrorMessage: string;
  couponExpiryDateErrorMessage: string;
  couponNameErrorMessage: string;
  couponTypeErrorMessage: string;
  shopNameErrorMessage: string;
  couponStartDateErrorMessage: string;
  couponDiscountPecentageErrorMessage: string;
  projectErrorMessage: string;
  //Shop
  nameErrorMessage: string;
  phoneErrorMessage: string;
  addressErrorMessage: string;
  productErrorMessage: string;
  shopProjectErrMessage:string;
  shopImageErrMessage:string;
  // product_service
  codeErrorMessage: string;
  productNameErrorMessage: string;
  productImageErrorMessage: string;
  productCategoryErrorMessage: string;
  productSubCategoryErrorMessage: string;
  categoryTypeErrorMessage: string;
  quantityErrorMessage: string;
  originalPriceErrorMessage: string;
  previlagePriceErrorMessage: string;
  projectErrorMessagePrdSrv: string;
  productDescriptionErrorMessage: string;
  offerTypeErrorMessage: string;
  discountErrorMessage: string;
  availabilityErrorMessage: string;
  isOfflineErrorMessage: string;
  quantityTypeErrMessage: string;
  shopErrMessage:string
  //ad
  adProjectErrMessage: string;
  adDateRangeErrMessage:string;
  adImagesErrMessage:string;
};
const initialState: InitialState = {
  //location
  branchNameErrorMessage: "",
  cityErrorMessage: "",
  pincodeErrorMessage: "",
  locationErrorMessage: "",
  //Coupon
  couponCodeErrorMessage: "",
  couponExpiryDateErrorMessage: "",
  couponNameErrorMessage: "",
  couponTypeErrorMessage: "",
  shopNameErrorMessage: "",
  couponStartDateErrorMessage: "",
  couponDiscountPecentageErrorMessage: "",
  projectErrorMessage: "",
  //Shop
  nameErrorMessage: "",
  phoneErrorMessage: "",
  addressErrorMessage: "",
  productErrorMessage: "",
  shopProjectErrMessage:"",
  shopImageErrMessage:"",
  //product_service
  codeErrorMessage: "",
  productNameErrorMessage: "",
  productImageErrorMessage: "",
  productCategoryErrorMessage: "",
  productSubCategoryErrorMessage: "",
  categoryTypeErrorMessage: "",
  quantityErrorMessage: "",
  originalPriceErrorMessage: "",
  previlagePriceErrorMessage: "",
  projectErrorMessagePrdSrv: "",
  productDescriptionErrorMessage: "",
  offerTypeErrorMessage: "",
  discountErrorMessage: "",
  availabilityErrorMessage: "",
  isOfflineErrorMessage: "",
  quantityTypeErrMessage: "",
  shopErrMessage:"",
  //ad
  adProjectErrMessage: "",
  adDateRangeErrMessage: "",
  adImagesErrMessage: "",
};
const vendorErrorSlice = createSlice({
  name: "vendorErrorMessage",
  initialState,
  reducers: {
    //location
    setBranchNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.branchNameErrorMessage = action.payload;
    },
    setCityErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.cityErrorMessage = action.payload;
    },
    setPincodeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.pincodeErrorMessage = action.payload;
    },
    setLocationErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.locationErrorMessage = action.payload;
    },
    //coupon
    setCouponCodeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponCodeErrorMessage = action.payload;
    },
    setCouponExpiryDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponExpiryDateErrorMessage = action.payload;
    },
    setCouponNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponNameErrorMessage = action.payload;
    },
    setCouponTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponTypeErrorMessage = action.payload;
    },
    setShopNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.shopNameErrorMessage = action.payload;
    },
    setCouponStartDateErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponStartDateErrorMessage = action.payload;
    },
    setcouponDiscountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.couponDiscountPecentageErrorMessage = action.payload;
    },
    //shop
    setNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.nameErrorMessage = action.payload;
    },
    setPhoneErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.phoneErrorMessage = action.payload;
    },
    setAddressErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.addressErrorMessage = action.payload;
    },
    setProductErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productErrorMessage = action.payload;
    },
    setShopProjectErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.shopProjectErrMessage = action.payload;
    },
    setShopImageErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.shopImageErrMessage = action.payload;
    },
    //product_service
    setProjectErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.projectErrorMessage = action.payload;
    },
    setIsOfflineErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.isOfflineErrorMessage = action.payload;
    },

    setCodeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.codeErrorMessage = action.payload;
    },
    setProductNameErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productNameErrorMessage = action.payload;
    },
    setQuantityTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.quantityTypeErrMessage = action.payload;
    },
    setProductImageErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productImageErrorMessage = action.payload;
    },
    setProductCategoryErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productCategoryErrorMessage = action.payload;
    },
    setProductSubCategoryErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productSubCategoryErrorMessage = action.payload;
    },
    setCategoryTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.categoryTypeErrorMessage = action.payload;
    },
    setQuantityErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.quantityErrorMessage = action.payload;
    },
    setOriginalPriceErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.originalPriceErrorMessage = action.payload;
    },
    setPrevilagePriceErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.previlagePriceErrorMessage = action.payload;
    },
    setProjectErrorMessagePrdSrv: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.projectErrorMessagePrdSrv = action.payload;
    },
    setProductDescriptionErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.productDescriptionErrorMessage = action.payload;
    },
    setOfferTypeErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.offerTypeErrorMessage = action.payload;
    },
    setDiscountErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.discountErrorMessage = action.payload;
    },
    setAvailabilityErrorMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.availabilityErrorMessage = action.payload;
    },
    setShopErrMessage: (
      state: InitialState,
      action: PayloadAction<string>
    ) => {
      state.shopErrMessage = action.payload;
    },
    
  //ad
  setAdProjectErr: (
    state: InitialState,
    action: PayloadAction<string>
  ) => {
    state.adProjectErrMessage = action.payload;
  },
  setAdDateRangeErr: (
    state: InitialState,
    action: PayloadAction<string>
  ) => {
    state.adDateRangeErrMessage = action.payload;
  },
  setAdImagesErr: (
    state: InitialState,
    action: PayloadAction<string>
  ) => {
    state.adImagesErrMessage = action.payload;
  },
},
  extraReducers: (builder) => {
    builder.addCase(VendorReset, (state) => {
      return initialState;
    });
  },
});
export default vendorErrorSlice.reducer;
export const {
  //location
  setBranchNameErrorMessage,
  setCityErrorMessage,
  setPincodeErrorMessage,
  setLocationErrorMessage,
  //coupon
  setCouponCodeErrorMessage,
  setCouponExpiryDateErrorMessage,
  setCouponNameErrorMessage,
  setCouponTypeErrorMessage,
  setShopNameErrorMessage,
  setcouponDiscountErrorMessage,
  setCouponStartDateErrorMessage,
  //shop
  setNameErrorMessage,
  setPhoneErrorMessage,
  setAddressErrorMessage,
  setProductErrorMessage,
  setShopProjectErrorMessage,
  setCodeErrorMessage,
  setShopImageErrMessage,
  //prod-service
  setProductNameErrorMessage,
  setProductImageErrorMessage,
  setProductSubCategoryErrorMessage,
  setProductCategoryErrorMessage,
  setCategoryTypeErrorMessage,
  setQuantityErrorMessage,
  setOriginalPriceErrorMessage,
  setPrevilagePriceErrorMessage,
  setProductDescriptionErrorMessage,
  setDiscountErrorMessage,
  setOfferTypeErrorMessage,
  setProjectErrorMessage,
  setAvailabilityErrorMessage,
  setProjectErrorMessagePrdSrv,
  setIsOfflineErrorMessage,
  setQuantityTypeErrorMessage,
  setShopErrMessage,
  //ad
  setAdProjectErr,
  setAdDateRangeErr,
  setAdImagesErr
} = vendorErrorSlice.actions;
