import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["orders"],
  endpoints: (builder) => ({
    getAllListOfUpComingProductOrders: builder.query<any, any>({
      query: (data: any) => ({
        url: "/vendor-order-records/",
        method: "GET",
        params: {
          status: data.status,
          page: data.page,
          type:"product",
          search:data.searchName
        },
      }),
      providesTags: ["orders"],
    }),
    getAllListOfUpComingServiceOrders: builder.query<any, any>({
      query: (data: any) => ({
        url: "/vendor-order-records/",
        method: "GET",
        params: {
          status: data.status,
          page: data.page,
          type:"service",
          search:data.searchName
        },
      }),
      providesTags: ["orders"],
    }),
    updateOrder: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `/vendor-order-records/${data?.id}/`,
        method: "PUT",
        body: data?.editInfo,
      }),
      invalidatesTags: ["orders"],
    }),
    updateOrderReccuring: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `recurring-ordered-dates/${data?.id}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["orders"],
    }),
  }),
});

export const { useGetAllListOfUpComingProductOrdersQuery,useGetAllListOfUpComingServiceOrdersQuery, useUpdateOrderMutation  ,useUpdateOrderReccuringMutation} =
  ordersApi;
