import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const vendorAdApi = createApi({
    reducerPath: "vendorAdApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['ad'],
    endpoints: (builder) => ({
        getVendorAdList: builder.query<any, {pages:any,searchName:any,project:any}>({
            query: ({pages,searchName,project}) => ({
                url: "/vendor-advertisement/",
                method: "GET",
                params: {
                   page:pages,
                   search:searchName,
                   vendor:1,
                   project_id:project
                },
            }),
            providesTags: ['ad']
        }),
        vendorProject: builder.query<any, void>({
            query: () => ({
                url: "/vendor-advertisement/?projects=1",
                method: "GET",
            }),
            providesTags: ['ad']
        }),
        addAdRequest: builder.mutation<any, any>({
            query: (adData: any) => ({
                url: "/vendor-advertisement/",
                method: "POST",
                body: adData,
            }),
            invalidatesTags: ['ad']
        }),
        singleAdData: builder.query<any, any>({
            query: (id: any) => ({
                url: `/vendor-advertisement/${id}/`,
                method: "GET",
            }),
            providesTags: ['ad']
        }),
        deleteAdRequest: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `/vendor-advertisement/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['ad']
        }),
        updateAd: builder.mutation<any, {adId :any , adData:any}>({
            query: ({adId,adData}) => ({
                url: `/vendor-advertisement/${adId}/`,
                method: "PATCH",
                body:adData,

            }),
            invalidatesTags: ['ad']
        }),
    }),

});

export const {useGetVendorAdListQuery,useVendorProjectQuery ,useAddAdRequestMutation , useSingleAdDataQuery ,useDeleteAdRequestMutation ,useUpdateAdMutation} = vendorAdApi;
