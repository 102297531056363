import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const helpersApi = createApi({
  reducerPath: "helpers",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["helpers","helpers-category"],
  endpoints: (builder) => ({
    listOfHelpers: builder.query<any, { pages: number; searchName: string ,project_id:string}>({
      query: ({ pages, searchName, project_id}) => ({
        url: "helper-admin/",
        method: "GET",
        params: {
          admin:"1",
          search: searchName,
          page: String(pages),
          project_id:project_id
        },
      }),
      providesTags: ["helpers"],
    }),
    listOfHelpersCategory: builder.query<any, void>({
      query: () => ({
        url: "/helpers-category/",
        method: "GET",
      }),
      providesTags: ["helpers-category"],
    }),
    singleHelpersData: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/helper-admin/${editId}/`,
        method: "GET",
      }),
      providesTags: ["helpers"],
    }),

    addHelpers: builder.mutation<any, any>({
      query: (formData) => ({
        url: "/helper-admin/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["helpers"],
    }),
    deleteHelpers: builder.mutation<any, { helperId: any }>({
      query: ({ helperId }) => ({
        url: `/helper-admin/${helperId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["helpers"],
    }),
    updateHelpers: builder.mutation<any, { helperData: any; helperId: any }>({
      query: ({ helperData, helperId }) => ({
        url: `/helper-admin/${helperId}/`,
        method: "PUT",
        body: helperData,
      }),
      invalidatesTags: ["helpers"],
    }),
  }),
});

export const {
    useListOfHelpersQuery,
    useSingleHelpersDataQuery,
    useAddHelpersMutation,
    useUpdateHelpersMutation,
    useDeleteHelpersMutation,
    useListOfHelpersCategoryQuery
} = helpersApi;
