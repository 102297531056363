import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const loginApi = createApi({
    reducerPath: "loginApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${config().BASE_URL}`}),
    tagTypes: ['login'],
    endpoints: (builder) => ({
        Login: builder.mutation<any, any>({
            query: (loginInfo) => ({
                url: "/dashboard-login/",
                method: "POST",
                body: loginInfo,
            }),
        }),
        
    }),
});

export const { useLoginMutation } = loginApi;
