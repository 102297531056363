import "./App.scss";
import './index.scss'
import React, { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "./route/path";
// import Login from "./pages/entry/login/login";

import "bootstrap/dist/css/bootstrap.min.css"
import AdminRoutes from "./routes/adminRoutes";
import VendorRoutes from "./routes/vendorRoute";
import AssociationRoutes from "./routes/associationRoute";
import PrivateRoutes from "./routes/privateRoutes";
import SecurityRoutes from "./routes/securityRoute";
import { useAppDispatch, useAppSelector } from "./base/hooks/hooks";
import { setMessageNotify } from "./base/reducer/message";
import { notification } from "antd";
import { NOTFOUND } from "dns";
import NotFound from "./pages/common/notFound";
import PropertyService from "./pages/admin/propertyServices/propertyServiceList";

const Login = React.lazy(() => import("./pages/entry/login/login"));
const AdminLayout = React.lazy(() => import("./layouts/adminLayout/index"));
const Home = React.lazy(() => import("./pages/admin/home/index"));
const EditCommunityEnrich = React.lazy(() => import("./pages/association/communityEnrich/editCommunityEnrich/editCommunityEnrich"))
const CommunityEnrichList = React.lazy(() => import("./pages/association/communityEnrich/communityEnrichList/communityEnrich"))
const VendorPastOrderProduct = React.lazy(() => import("./pages/vendor/orders/pastOrderProduct/pastOrderProduct"))
const VendorUpcomingOrder = React.lazy(() => import("./pages/vendor/orders/upcomingOrderProduct/upcomingOrder"))
const VendorUpcomingOrderService = React.lazy(() => import("./pages/vendor/orders/upcomingOrderService/upcomingOrderService"))
const AssociationOwners = React.lazy(() => import("./pages/association/associationOwners/associationOwners"))
const EventList = React.lazy(() => import("./pages/association/associationEventList/associationEventList"))
const AssociationEvents = React.lazy(() => import("./pages/association/associationEvents/associationEvents"))
const AssociationIncome = React.lazy(() => import("./pages/association/income/income"))
const AssociationExpenses = React.lazy(() => import("./pages/association/expenses/expenses"))
const AssociationHome = React.lazy(() => import("./pages/association/home"))
const AssociationLayout = React.lazy(() => import("./layouts/associationLayout"))
const VendorLocation = React.lazy(() => import("./pages/vendor/location/addLocation/index"))
const VendorLocationList = React.lazy(() => import("./pages/vendor/location/locationList/locationList"))
const VendorShopList = React.lazy(() => import("./pages/vendor/shop/shopList/index"))
const VendorCouponList = React.lazy(() => import("./pages/vendor/coupon/couponList/couponList"))
const Coupon = React.lazy(() => import('./pages/vendor/coupon/addCoupon/addCoupon'))
const VendorAddShop = React.lazy(() => import("./pages/vendor/shop/addShop/index"))
const VendorProductList = React.lazy(() => import("./pages/vendor/enrichList/productList/productList"))
const VendorProduct = React.lazy(() => import("./pages/vendor/product_service/product"))
const VendorLayout = React.lazy(() => import("./layouts/vendorLayout"))
const VendorHome = React.lazy(() => import("./pages/vendor/home/index"))
const BannerList = React.lazy(() => import("./pages/admin/banner/bannerList/bannerList"))
const AddBanner = React.lazy(() => import("./pages/admin/banner/addBanner/addBanner"))
const AddPayment = React.lazy(() => import("./pages/admin/payment/addPayment/addPayment"))
const PaymentList = React.lazy(() => import("./pages/admin/payment/paymentList/paymentList"))
const AddPaymentSchedule = React.lazy(() => import("./pages/admin/paymentSchedule/addPaymentSchedule/paymentSchedule"))
const PaymentScheduleList = React.lazy(() => import("./pages/admin/paymentSchedule/addPaymentScheduleList/paymentScheduleList"))
const AddRegistrationOffice = React.lazy(() => import("./pages/admin/addRegistrationOffice/addRegistrationOffice/addRegistrationOffice"))
const RegistrationOfficeList = React.lazy(() => import("./pages/admin/addRegistrationOffice/registrationOfficeList/registrationOfficeList"))
// const Notification = React.lazy(() => import("./pages/admin/notification/addNotification/notification"))
// const NotificationList = React.lazy(() => import("./pages/admin/notification/notificationList/notificationList"))
const Notification = React.lazy(() => import("./pages/common/notification/addNotification/notification"))
const NotificationList = React.lazy(() => import("./pages/common/notification/notificationList/notificationList"))
const NonCasaList = React.lazy(() => import("./pages/admin/customer/nonCasaCustomerList/nonCasaCustomerList"))
const RequestDocumentList = React.lazy(() => import("./pages/admin/document/requestDocumentList/requestDocumentList"))
const AddHelpers = React.lazy(() => import("./pages/admin/helpersManagement/addHelpers/addHelpers"))
const HelpersList = React.lazy(() => import("./pages/admin/helpersManagement/helpersList/helpersList"))
const ParkingSlotList= React.lazy(() => import("./pages/common/parkingSlot/parkingSlotList"))
const ForumReportList =React.lazy(() => import("./pages/admin/forumReports/forumReportList/forumReportList"));
const VendorAdList =React.lazy(() => import("./pages/vendor/adRequest/adRequestList/adRequestList"));
const AdRequestList =React.lazy(() => import("./pages/admin/adRequestsList/adRequestList"));
const AssureListConfiquration =React.lazy(() => import("./pages/admin/assureConfiguration/assureList"));
const AssureReqList =React.lazy(() => import("./pages/admin/assureReqList/assureReqList"));
const AdminGallery =React.lazy(() => import("./pages/admin/gallery/gallery"));
const CategoryAndSubcategory =React.lazy(() => import("./pages/admin/categoryAndSubcategory/categoryList"));
const MaintenanceConfig =React.lazy(() => import("./pages/common/maintenanceConfig/maintenanceConfig"));
const Tickets =React.lazy(() => import("./pages/admin/tickets/tickets"));
const CasaCommission =React.lazy(() => import("./pages/common/casaCommission/casaCommission"));
const CommissionDetailsView =React.lazy(() => import("./pages/common/commissionDetailsView/commissionDetailsView"));
const BankList =React.lazy(() => import("./pages/admin/bank/bankList/bankList"));
const BankAdd =React.lazy(() => import("./pages/admin/bank/addBank/addBank"));
const UserList =React.lazy(() => import("./pages/admin/userList/userList"));
const ProfileList =React.lazy(() => import("./pages/admin/profileList/profileList"));
const ProfileAdd =React.lazy(() => import("./pages/admin/profileAdd/profileAdd"));
const AdminRoles =React.lazy(() => import("./pages/admin/roles/roles"));
const SupportTeamList=React.lazy(()=>import("./pages/admin/supportTeam/supportTeam"))
const Department=React.lazy(()=>import("./pages/admin/department/department"))
const Reports=React.lazy(()=>import("./pages/admin/report/report"))

const VendorList =React.lazy(() => import("./pages/admin/vendor/vendorList"));

const AssociationVisitor = React.lazy(() => import("./pages/common/visitor/visitor"))
const AssocicationEnquiryList = React.lazy(() => import("./pages/association/associationEventEnquiryList/associationEventEnquiryList"))
const StockManagement = React.lazy(() => import("./pages/association/stockManagement/addStock/addStock"))
const StockManagementList = React.lazy(() => import("./pages/association/stockManagement/stockManagementList/stockManagementList"))
const Customers = React.lazy(() => import("./pages/admin/customer/customerList/customersList"))
const CustomerEdit = React.lazy(() => import("./pages/admin/customer/customerEdit"))
const AddMeeting = React.lazy(() => import("./pages/admin/addMeeting/addMeeting"))
const TicketsList = React.lazy(() => import("./pages/admin/ticketsList/ticketsList"))
const AssociationOwnerList = React.lazy(() => import("./pages/association/associationOwnersList/AssociationOwnerList"))
const AdminRegistrationSlots = React.lazy(() => import("./pages/admin/houseRegistration/addRegistrationSlots/addRegistrationSlots"))
const AdminRegistrationList = React.lazy(() => import("./pages/admin/houseRegistration/registrationSlotsList/registrationSlotList"))
const AssociationRegistrationList = React.lazy(() => import("./pages/admin/houseRegistration/registrationSlotsList/registrationSlotList"))
const Document = React.lazy(() => import("./pages/admin/document/addDocument/document"))
const VendorServiceList = React.lazy(() => import("./pages/vendor/enrichList/serviceList/serviceList"));
const Maintenance = React.lazy(() => import("./pages/association/maintenance/maintenance"));
const HelpersRequestList = React.lazy(() => import("./pages/common/helpersRequestList/helpersRequestList"));
const AmenitiesEnquiryList = React.lazy(() => import("./pages/association/amenities/amenitiesEnquiryList/amenitieEnquires"));

const SecurityHome = React.lazy(() => import("./pages/security/home/index"))
const SecurityLayout = React.lazy(() => import("./layouts/securityLayout"))
const SecurityParkingSlot = React.lazy(() => import("./pages/common/visitorParkingSlot/parkingSlot"))
const SecurityParkingSlotAdd = React.lazy(() => import("./pages/common/parkingSlotAdd/parkingSlotAdd"))
const Milestones = React.lazy(() => import("./pages/admin/milestones/milestones"));
const MileStoneList = React.lazy(() => import("./pages/admin/milestonesList/milestonesList"));
const VenodrPastServiceOrder = React.lazy(() => import("./pages/vendor/orders/pastOrderService/pastOrderService"));
const AssociationAmenitiesList = React.lazy(() => import("./pages/association/amenities/amenitiesList/amenitiesList"));
const AssociationAmenitiesAdd = React.lazy(() => import("./pages/association/amenities/amenitiesAdd/amenitiesAdd"));
const AssetManagement = React.lazy(() => import("./pages/association/asset/AddAsset/addAsset"));
const AssetManagementList = React.lazy(() => import("./pages/association/asset/assetManagementList/assetManagementList"));
const CustomerAppointmentList = React.lazy(() => import("./pages/admin/customerAppointmentList/customerAppointmentList"));
const ReferralList= React.lazy(() => import("./pages/admin/referral/referralList"));
const FittedHomeEnquiryList = React.lazy(() => import("./pages/admin/fittedHomeEnquiryList/fittedHomeEnquiryList"));
const TestimonialList = React.lazy(() => import("./pages/admin/testimonialList/testimonialList"));


function App() {
  const { messageNotify, messageInfo, messageType }: any = useAppSelector((state: any) => state.toastReducer)
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch()
  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  useEffect(() => {
    if (messageNotify) {
      OpenNotificationWithIcon(messageType, messageInfo);
    }
  }, [messageNotify, messageInfo]);
  const OpenNotificationWithIcon = (
    type: NotificationType,
    errorMessage: any
  ) => {
    api[type]({
      message: <div className={type === "success" ? "success" :type=== "error"?"error":"warning"}>{type}</div>,
      description: <div className='descMessage'>{errorMessage}</div>,
      placement: 'bottomRight',
      closeIcon: <div className='closeIcon'>&times;</div>,
    });
    dispatch(setMessageNotify(false));
  };
  return (
    <div className="App">
      {contextHolder}
      <Suspense fallback={<div>Loading....</div>}>
        <Routes>
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path="*" element={<NotFound />} />

          <Route element={<PrivateRoutes />}>
            <Route element={<AdminRoutes />}>
              <Route element={<AdminLayout />}>
                <Route path={PATH.HOME_ADMIN} element={<Home />} />
                <Route path={PATH.ADMIN_CUSTOMERS} element={<Customers />} />
                <Route path={PATH.ADMIN_CUSTOMER_EDIT} element={<CustomerEdit />} />
                <Route path={PATH.ADMIN_MEETING_ADD} element={<AddMeeting />} />
                <Route path={PATH.ADMIN_TICKETS_LIST} element={<TicketsList />} />
                {/* <Route path={PATH.ADMIN_DOCUMENT} element={<Document />} /> */}
                <Route path={PATH.ADMIN_DOCUMENT_TYPE} element={<Document />} />

                <Route path={PATH.ADMIN_REGISTRATION_TYPE} element={<AdminRegistrationSlots />} />
                <Route path={PATH.ADMIN_REGISTRATION_LIST} element={<AdminRegistrationList />} />
                <Route path={PATH.ADMIN_MILESTONESLIST} element={<MileStoneList />} />
                <Route path={PATH.ADMIN_MILESTONES_TYPE} element={<Milestones />} />
                <Route path={PATH.ADMIN_BANNER_TYPE} element={<AddBanner />} />
                <Route path={PATH.ADMIN_BANNER_LIST} element={<BannerList />} />
                <Route path={PATH.ADMIN_PAYMENT_TYPE} element={<AddPayment />} />
                <Route path={PATH.ADMIN_PAYMENT_LIST} element={<PaymentList />} />
                <Route path={PATH.ADMIN_PAYMENT_SCHEDULE_TYPE} element={<AddPaymentSchedule />} />
                <Route path={PATH.ADMIN_PAYMENT_SCHEDULE_LIST} element={<PaymentScheduleList />} />
                <Route path={PATH.ADMIN_REGISTRATION_OFFICE_TYPE} element={<AddRegistrationOffice />} />
                <Route path={PATH.ADMIN_REGISTRATION_OFFICE_LIST} element={<RegistrationOfficeList />} />
                <Route path={PATH.ADMIN_NOTIFICATION_TYPE} element={<Notification />} />
                <Route path={PATH.ADMIN_NOTIFICATION_LIST} element={<NotificationList />} />
                <Route path={PATH.ADMIN_NON_CASA_LIST} element={<NonCasaList />} />
                <Route path={PATH.ADMIN_REQUEST_DOC_LIST} element={<RequestDocumentList />} />
                <Route path={PATH.ADMIN_HELPERS_TYPE} element={<AddHelpers />} />
                <Route path={PATH.ADMIN_HELPERS_LIST} element={<HelpersList />} />
                <Route path={PATH.ADMIN_PARKING_TYPE} element={<ParkingSlotList />} />
                <Route path={PATH.ADMIN_FORUM_REPORT} element={<ForumReportList />} />
                <Route path={PATH.ADMIN_Ad_REQUEST_LIST_TYPE} element={<AdRequestList />} />
                <Route path={PATH.ADMIN_CUSTOMER_APPOINTMENT_LIST_TYPE} element={<CustomerAppointmentList />} />
                <Route path={PATH.ADMIN_ASSURE_CONFIG} element={<AssureListConfiquration />} />
                <Route path={PATH.ADMIN_ASSURE_LIST} element={<AssureReqList />} />
                <Route path={PATH.ADMIN_GALLERY_LIST_TYPE} element={<AdminGallery />} />
                <Route path={PATH.CATEGORY_AND_SUBCATEGORY_TYPE} element={<CategoryAndSubcategory />} />
                <Route path={PATH.REFERRAL_LIST} element={<ReferralList />} />
                <Route path={PATH.ADMIN_FITTED_HOME_ENQUIRY_LIST} element={<FittedHomeEnquiryList />} />
                <Route path={PATH.ADMIN_TESTIMONIAL_LIST} element={<TestimonialList />} />
                <Route path={PATH.ADMIN_PARKING_SLOT_ADD_TYPE} element={<SecurityParkingSlotAdd />} />
                <Route path={PATH.ADMIN_MAINTENANCE_CONFIG} element={<MaintenanceConfig />} />
                <Route path={PATH.ADMIN_TICKETS_TYPE} element={<Tickets />} />                
                <Route path={PATH.ADMIN_CASA_COMMISSION} element={<CasaCommission />} />
                <Route path={PATH.ADMIN_VENDOR_LIST} element={<VendorList />} />
                <Route path={PATH.ADMIN_CASA_COMMISSION_MORE_DETAILS} element={<CommissionDetailsView />} />
                <Route path={PATH.ADMIN_BANK_LIST} element={<BankList />} />
                <Route path={PATH.ADMIN_BANK_ADD} element={<BankAdd />} />
                <Route path={PATH.ADMIN_USER_LIST_ADD_USER} element={<UserList />} />
                <Route path={PATH.ADMIN_PROFILE_LIST} element={<ProfileList />} />
                <Route path={PATH.ADMIN_PROFILE_ADD} element={<ProfileAdd />} />
                <Route path={PATH.ADMIN_ROLES_ADD} element={<AdminRoles />} />
                <Route path={PATH.ADMIN_PROPERTY_SERVICES_TYPE} element={<PropertyService />} />
                <Route path={PATH.ADMIN_SUPPORT_TEAM} element={<SupportTeamList />} />
                <Route path={PATH.ADMIN_DEPARTMENT} element={<Department />} />
                <Route path={PATH.ADMIN_REPORT} element={<Reports/>} />
              </Route>

            </Route>
            <Route element={<VendorRoutes />}>
              <Route element={<VendorLayout />}>
                <Route path={PATH.VENDOR_HOME} element={<VendorHome />} />
                <Route path={PATH.VENDOR_PRODUCT} element={<VendorProduct />} />
                <Route path={PATH.VENDOR_PRODUCT_LIST} element={<VendorProductList />} />
                <Route path={PATH.VENDOR_SERVICE_LIST} element={<VendorServiceList />} />
                <Route path={PATH.VENDOR_SHOP} element={<VendorAddShop />} />
                <Route path={PATH.VENDOR_COUPON_TYPE} element={<Coupon />} />
                <Route path={PATH.VENDOR_COUPON_LIST} element={<VendorCouponList />} />
                <Route path={PATH.VENDOR_SHOP_LIST} element={<VendorShopList />} />
                <Route path={PATH.VENDOR_ADD_LOCATION_TYPE} element={<VendorLocation />} />
                <Route path={PATH.VENDOR_LOCATION_LIST} element={<VendorLocationList />} />
                <Route path={PATH.VENDOR_UPCOMING_ORDER_PRODUCT} element={<VendorUpcomingOrder />} />
                <Route path={PATH.VENDOR_UPCOMING_ORDER_SERVICE} element={<VendorUpcomingOrderService />} />
                <Route path={PATH.VENDOR_PAST_ORDER_PRODUCT} element={<VendorPastOrderProduct />} />
                <Route path={PATH.VENDOR_PAST_ORDER_SERVICE} element={<VenodrPastServiceOrder />} />
                <Route path={PATH.VENDOR_AD_TYPE} element={<VendorAdList />} />
                <Route path={PATH.VENDOR_CASA_COMMISSION} element={<CasaCommission />} />
                <Route path={PATH.VENDOR_CASA_COMMISSION_MORE_DETAILS} element={<CommissionDetailsView />} />

              </Route>
            </Route>
            <Route element={<AssociationRoutes />}>
              <Route element={<AssociationLayout />}>
                <Route path={PATH.ASSOCIATION_HOME} element={<AssociationHome />} />
                <Route path={PATH.ASSOCIATION_EXPENSES_TYPE} element={<AssociationExpenses />} />
                <Route path={PATH.ASSOCIATION_INCOME_TYPE} element={<AssociationIncome />} />
                <Route path={PATH.ASSOCIATION_EVENTS_TYPE} element={<AssociationEvents />} />
                <Route path={PATH.ASSOCIATION_OWNERS} element={<AssociationOwners />} />
                <Route path={PATH.ASSOCIATION_EVENT_LIST} element={<EventList />} />
                <Route path={PATH.COMMUNITY_ENRICH_LIST} element={<CommunityEnrichList />} />
                <Route path={PATH.COMMUNITY_ENRICH_ADD} element={<EditCommunityEnrich />} />
                <Route path={PATH.ASSOCIATION_STOCK_MANAGEMENT} element={<StockManagement />} />
                <Route path={PATH.ASSOCIATION_STOCK_MANAGEMENT_LIST} element={<StockManagementList />} />
                <Route path={PATH.ASSOCIATION_VISITOR} element={<AssociationVisitor />} />
                <Route path={PATH.ASSOCIATION_STOCK_MANAGEMENT_LIST} element={<StockManagementList />} />
                <Route path={PATH.ASSOCIATION_OWNER_LIST} element={<AssociationOwnerList />} />
                <Route path={PATH.ASSOCIATION_REGISTRATION_LIST} element={<AssociationRegistrationList />} />
                <Route path={PATH.ASSOCIATION_AMENITIES_LIST} element={<AssociationAmenitiesList />} />
                <Route path={PATH.ASSOCIATION_AMENITIES_TYPE} element={<AssociationAmenitiesAdd />} />
                <Route path={PATH.ASSOCIATION_ASSET} element={<AssetManagement />} />
                <Route path={PATH.ASSOCIATION_ASSETS_LIST} element={<AssetManagementList />} />
                <Route path={PATH.ASSOCIATION_EVENT_ENQUIRY_LIST} element={<AssocicationEnquiryList />} />
                <Route path={PATH.ASSOCIATION_MAINTENANCE_TYPE} element={<Maintenance />} />
                <Route path={PATH.HELPERS_REQUEST_LIST} element={<HelpersRequestList />} />
                <Route path={PATH.ASSOCIATION_AMENITIES_ENQUIRY_LIST} element={<AmenitiesEnquiryList />} />
                <Route path={PATH.ASSOCIATION_VISITOR_PARKING_SLOT} element={<SecurityParkingSlot />} />
                <Route path={PATH.ASSOCIATION_PARKING_SLOT_ADD_TYPE} element={<SecurityParkingSlotAdd />} />
                <Route path={PATH.ASSOCIATION_PARKING_TYPE} element={<ParkingSlotList />} />
                <Route path={PATH.ASSOCIATION_MAINTENANCE_CONFIG} element={<MaintenanceConfig />} />
                <Route path={PATH.ASSOCIATION_NOTIFICATION_TYPE} element={<Notification />} />
                <Route path={PATH.ASSOCIATION_NOTIFICATION_LIST} element={<NotificationList />} />
                <Route path={PATH.ASSOCIATION_HELPERS_LIST} element={<HelpersList />} />
                <Route path={PATH.ASSOCIATION_HELPERS_TYPE} element={<AddHelpers />} />
              </Route>
            </Route>
            <Route element={<SecurityRoutes />}>
              <Route element={<SecurityLayout />}>
                <Route path={PATH.SECURITY_HOME} element={<SecurityHome />} />
                <Route path={PATH.SECURITY_VISITOR} element={<AssociationVisitor />} />
                <Route path={PATH.SECURITY_PARKING_SLOT} element={<SecurityParkingSlot />} />
                <Route path={PATH.SECURITY_PARKING_TYPE} element={<ParkingSlotList />} />
                <Route path={PATH.SECURITY_HELPERS_REQUEST_LIST} element={<HelpersRequestList />} />
                <Route path={PATH.SECURITY_PARKING_SLOT_ADD_TYPE} element={<SecurityParkingSlotAdd />} />
                <Route path={PATH.ASSOCIATION_HELPERS_LIST} element={<HelpersList />} />
                <Route path={PATH.ASSOCIATION_HELPERS_TYPE} element={<AddHelpers />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
