import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const helpersAssociationApi = createApi({
  reducerPath: "helpersAssociation",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["helpers"],
  endpoints: (builder) => ({
    listOfHelperRequest: builder.query<any, { pages: number; searchName: string}>({
      query: ({ pages, searchName}) => ({
        url: "helper-admin/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
        },
      }),
      providesTags: ["helpers"],
    }),
    doAssignHelper:builder.mutation<any ,{id:any,customerId:any}>({
      query: ({id,customerId}) => ({
        url: `/helper-admin/${id}/?assign=1`,
        method: "PUT",
        body:customerId
      }),
      invalidatesTags: ["helpers"],
    }),
    doRemoveCustomer:builder.mutation<any ,{id:any,customerId:any}>({
      query: ({id,customerId}) => ({
        url: `/helper-admin/${id}/?remove=1`,
        method: "PUT",
        body:customerId
      }),
      invalidatesTags: ["helpers"],
    }),
    doAttendance:builder.mutation<any,any>({
      query: (attendanceData) => ({
        url: `/helper-records/`,
        method: "POST",
        body:attendanceData
      }),
      invalidatesTags: ["helpers"],
    }),
  }),
});

export const {
    useListOfHelperRequestQuery,
    useDoAssignHelperMutation,
    useDoRemoveCustomerMutation,
    useDoAttendanceMutation,
} = helpersAssociationApi ;
