import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const enquiryListApi = createApi({
  reducerPath: "enquiryList",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["enquiryList"],
  endpoints: (builder) => ({
    getAllEnquiryList: builder.query< any,{ pages: number; searchName: string ,projectId:any}>({
        query: ({ pages, searchName ,projectId}) => ({
          url: "/fitted-homes-request/",
          method: "GET",
          params: {
            search: searchName,
            page: String(pages),
            project_id:projectId
          },
        }),
        providesTags: ["enquiryList"],
      }),
    EnquiryStatus: builder.mutation<any, {id:any,status:any}>({
        query: ({ id,status }) => ({
          url: `/fitted-homes-request/${id}/`,
          method: "PUT",
          body:status
        }),
        invalidatesTags: ["enquiryList"],
      }),
  }),
});
export const { useGetAllEnquiryListQuery,useEnquiryStatusMutation } = enquiryListApi;
