import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const serviceApi = createApi({
  reducerPath: "service",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["service"],
  endpoints: (builder) => ({
    getListOfservice: builder.query<any, {pages:any,searchName:any}>({
      query: ({ pages ,searchName}) => ({
        url: "/show-all-service/?myServices=1/",
        method: "GET",
        params: {
          page: pages,
          search: searchName,
        },
      }),
      providesTags: ["service"],
    }),
    getListOfServiceCategory: builder.query<any, void>({
      query: () => ({
        url: "/service-category-list/",
        method: "GET",
      }),
    }),
    getListofServiceSubcategory: builder.query<any, any>({
      query: (value) => ({
        url: `/service-subcategory-list/?service_category=${value}`,
        method: "GET",
      }),
      
    }),
    addService: builder.mutation<any, object>({
      query: (productInfo: object) => ({
        url: "/enrich-service/",
        method: "POST",
        body: productInfo,
      }),
      invalidatesTags: ["service"],
    }),
    deleteService: builder.mutation<any, any>({
      query: (id) => ({
        url: `/enrich-service/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["service"],
    }),
    updateService: builder.mutation<any, { id: any; formData: any }>({
      query: ({formData ,id}) => ({
        url: `enrich-service/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["service"],
    }),
    getSingleService: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `enrich-service/?edit=${editId}`,
        method: "GET",

      }),
      providesTags: ["service"],
    }),
  }),
});

export const {
  useGetListOfserviceQuery,
  useAddServiceMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
  useGetListOfServiceCategoryQuery,
  useGetListofServiceSubcategoryQuery,
  useGetSingleServiceQuery,
} = serviceApi;
