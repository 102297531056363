import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const customerApi = createApi({
  reducerPath: "customer",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["customer"],
  endpoints: (builder) => ({
    // getListOfCustomerWithDocuments: builder.query<any, {pages:number,searchName:string}>({
    //   query: ({pages,searchName}) => ({
    //     url: "/customers-document/",
    //     method: "GET",
    //     params: {
    //         search:searchName,
    //       page: String(pages),
    //     },
    //   }),
    //   providesTags: ["customer"],
    // }),
    getListOfCustomers: builder.query<any, { pages: number; searchName: string,project:string }>({
      query: ({ pages, searchName, project }) => ({
        url: "/customers/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          project:project,
        },
      }),
      providesTags: ["customer"],
    }),
    getProjectBasedCustomer: builder.query<any, any>({
      query: () => ({
        url: "/customers/",
        method: "GET",
      }),
      providesTags: ["customer"],
    }),
    deleteCustomer: builder.mutation<any, any>({
      query: (id) => ({
        url: "/customers/",
        method: "DELETE",
      }),
      invalidatesTags: ["customer"],
    }),
    editCustomer: builder.mutation<any, any>({
      query: (customerData: any) => ({
        url: `/customers/${customerData.id}/`,
        method: "PUT",
        body: customerData.formdata,
      }),
      invalidatesTags: ["customer"],
    }), //api/customers/{id}
    getOneCustomer: builder.query<any, any>({
      query: (id: any) => ({
        url: `/customers/${id}/`,
        method: "GET",
      }),
      providesTags: ["customer"],
    }),
    getListOfNonCasaCustomers: builder.query<any, { pages: number; searchName: string }>({
      query: ({ pages, searchName }) => ({
        url: "/customers/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          non_casa: 1,
        },
      }),
      providesTags: ["customer"],
    }),
    resetRegistration: builder.mutation<any, any>({
      query: (id: any) => ({
        url: "/reset-registration/",
        method: "POST",
        params: {
          customer_id: id
        },
      }),
      invalidatesTags: ["customer"],
    }),
  })
});

export const {
  useGetListOfCustomersQuery,
  useGetProjectBasedCustomerQuery,
  useDeleteCustomerMutation,
  useEditCustomerMutation,
  useGetOneCustomerQuery,
  useGetListOfNonCasaCustomersQuery,
  useResetRegistrationMutation,
} = customerApi;
