export const PATH = {
  //general
  LOGIN: "/",

  //admin
  HOME_ADMIN: "/admin/home",
  EMPLOYEES: "/employees",
  SHOPS: "/shop",
  ADD_SHOP: "/add-shop",
  ADMIN_MEETING_ADD: "/admin/addMeeting",
  ADMIN_TICKETS_LIST: "/admin/ticketsList",
  //document
  ADMIN_DOCUMENT_TYPE: "/admin/document/:type?/:method?",
  ADMIN_DOCUMENT: "/admin/document",
  ADMIN_DOCUMENT_ADD: "/admin/document/add-document",
  //customer
  ADMIN_CUSTOMERS: "/admin/home/customers",
  ADMIN_CUSTOMER_EDIT: "/admin/home/customerEdit",
  //registration slots
  ADMIN_REGISTRATION_LIST: "/admin/registration/list",
  ADMIN_ADD_REGISTRATION: "/admin/registration/add-registration-slots",
  ADMIN_REGISTRATION_TYPE: "/admin/registration/:type/:method?",
  //milestone
  ADMIN_MILESTONES_TYPE: "/admin/milestones/:type?/:method?",
  ADMIN_MILESTONES: "/admin/milestones/add-milestone",
  ADMIN_MILESTONESLIST: "/admin/milestonesList",
  //banner
  ADMIN_BANNER_TYPE: "/admin/banner/:type?/:method?",
  ADMIN_ADD_BANNER: "/admin/banner/add-banner",
  ADMIN_BANNER_LIST: "/admin/banner-list",
  //requestDoc
  ADMIN_REQUEST_DOC_LIST: "/admin/request-document-list",
  //payment
  ADMIN_PAYMENT_TYPE: "/admin/payment/:type?/:method?",
  ADMIN_ADD_PAYMENT: "/admin/payment/add-payment/",
  ADMIN_PAYMENT_LIST: "/admin/payment-list",
  //helpers
  ADMIN_HELPERS_TYPE: "/admin/helpers/:type?/:method?",
  ADMIN_ADD_HELPERS: "/admin/helpers/add-helpers",
  ADMIN_HELPERS_LIST: "/admin/helpers-list",
  //payment schedule
  ADMIN_PAYMENT_SCHEDULE_TYPE: "/admin/payment-schedule/:type?/:method?",
  ADMIN_PAYMENT_SCHEDULE_ADD: "/admin/payment-schedule/add-schedule",
  ADMIN_PAYMENT_SCHEDULE_LIST: "/admin/payment-schedule-list",
  //notification
  ADMIN_NOTIFICATION_TYPE: "/admin/notification/:type?/:method?",
  ADMIN_NOTIFICATION_ADD: "/admin/notification/add-notification",
  ADMIN_NOTIFICATION_LIST: "/admin/notification-list",
  //registration office
  ADMIN_REGISTRATION_OFFICE_TYPE: "/admin/registration-office/:type?/:method?",
  ADMIN_REGISTRATION_OFFICE_ADD: "/admin/registration-office/add-office",
  ADMIN_REGISTRATION_OFFICE_LIST: "/admin/registration-office-list",
  ADMIN_NON_CASA_LIST: "/admin/non-casa-customer-list",

  //adManagement
  ADMIN_Ad_REQUEST_LIST_TYPE: "/admin/ad-req-list/:type?/:method?",
  ADMIN_Ad_REQUEST_LIST: "/admin/ad-req-list",
  //customerAppointmentList
  ADMIN_CUSTOMER_APPOINTMENT_LIST_TYPE:
    "/admin/customer-appointment-list/:type?/:method?",
  ADMIN_CUSTOMER_APPOINTMENT_LIST: "/admin/customer-appointment-list",

  //assure
  ADMIN_ASSURE_LIST: "/admin/assure-request/rent-sell-list",
  //assure req list
  ADMIN_ASSURE_CONFIG_LIST: "/admin/assure-confiq/rent-sell-list",
  ADMIN_ASSURE_CONFIG: "/admin/assure-confiq/rent-sell-list/:type?/:edit?",

  //gallery
  ADMIN_GALLERY_LIST_TYPE: "/admin/gallery-list/:type?/:method?",
  ADMIN_GALLERY_LIST: "/admin/gallery-list",
  ADMIN_GALLERY_ADD: "/admin/gallery-list/add-gallery",
  //category/subcategory
  CATEGORY_AND_SUBCATEGORY_TYPE: "/admin/category-subcategory/:type?/:method?",
  CATEGORY_AND_SUBCATEGORY: "/admin/category-subcategory",

  //forum report
  ADMIN_FORUM_REPORT: "/admin/forum/report-list",
  //referral
  REFERRAL_LIST: "/admin/referral/referral-list",
  //Fitted home enquiry list
  ADMIN_FITTED_HOME_ENQUIRY_LIST: "/admin/fitted-home-enquiry-list",
  //Testimonial
  ADMIN_TESTIMONIAL_LIST: "/admin/testimonial-list",
  //add parking slot admin
  ADMIN_PARKING_SLOT_ADD_TYPE:
    "/admin/parking-slot-list/:type?/:method?/:edit?",
  ADMIN_PARKING_SLOT_ADD_NEW_LIST: "/admin/parking-slot-list",
  //customer parkingslot mapping
  ADMIN_PARKING_SLOT: "/admin/customer-parking-list",
  ADMIN_PARKING_ADD: "/admin/customer-parking-list/add-parking-slot/",
  ADMIN_PARKING_TYPE: "/admin/customer-parking-list/:method?/:edit?",
  //admin maintenance config
  ADMIN_MAINTENANCE_CONFIG: "/admin/maintenance-configuration",
  //admin tickets
  ADMIN_TICKETS_TYPE: "/admin/support-tickets/:type?/:method?/:note?",
  //casa commission
  ADMIN_CASA_COMMISSION: "/admin/casa-commission/:id?",
  ADMIN_CASA_COMMISSION_MORE_DETAILS: "/admin/casa-commission/commission-details-view/:vendor?/:commission?",
  // ADMIN_CASA_COMMISSION_VIEW: "/admin/commissionDetailsView",
  //vendor list
  ADMIN_VENDOR_LIST: '/admin/vendor-list',
  //BAnk
  ADMIN_BANK_ADD: '/admin/:type?/:edit?',
  ADMIN_BANK_LIST: '/admin/bank-list',
  //user
  ADMIN_USER_LIST: '/admin/user-list',
  ADMIN_USER_LIST_ADD_USER: '/admin/user-list/:type?/:edit?',
  //profile
  ADMIN_PROFILE_LIST: '/admin/profile-list',
  ADMIN_PROFILE_ADD: '/admin/profile/:type?/:edit?',
  //roles
  ADMIN_ROLES: '/admin/roles',
  ADMIN_ROLES_ADD: '/admin/roles/:type?/:edit?',
  //support team
  ADMIN_SUPPORT_TEAM_LIST: '/admin/support-team-list',
  ADMIN_SUPPORT_TEAM: '/admin/support-team-list/:type?/:edit?',
  //property service
  ADMIN_PROPERTY_SERVICE_LIST: '/admin/property-service-list',
  ADMIN_PROPERTY_SERVICE: '/admin/property-service-list/:type?/:edit?',
  //department
  ADMIN_DEPARTMENT_LIST: '/admin/department-list',
  ADMIN_DEPARTMENT: '/admin/department-list/:type?/:edit?',



  //propertyService
  ADMIN_PROPERTY_SERVICES_TYPE: "/admin/property-services/:type?/:edit?",
  ADMIN_PROPERTY_SERVICES: "/admin/property-services",
  //admin report 
  ADMIN_REPORT: "/admin/report",

  //Vendor
  VENDOR_HOME: "/vendor/home",
  //product
  VENDOR_PRODUCT: "/vendor/:type?/:method?",
  VENDOR_PRODUCT_ADD: "/vendor/product/add",
  VENDOR_SERVICE_ADD: "/vendor/service/add",
  VENDOR_PRODUCT_LIST: "/vendor/product-list",
  VENDOR_SERVICE_LIST: "/vendor/service-list",
  //coupon
  VENDOR_COUPON_TYPE: "/vendor/coupon/:type?/:method?",
  VENDOR_COUPON_ADD: "/vendor/coupon/add-coupon",
  VENDOR_COUPON_LIST: "/vendor/couponList",
  //location
  VENDOR_ADD_LOCATION_TYPE: "/vendor/Location/:type?/:method?",
  VENDOR_ADD_LOCATION: "/vendor/Location/add-location",
  VENDOR_LOCATION_LIST: "/vendor/locationsList",
  //shop
  VENDOR_SHOP: "/vendor/shop/:type?",
  VENDOR_SHOP_ADD: "/vendor/shop/add",
  VENDOR_SHOP_LIST: "/vendor/shopList",
  //
  VENDOR_UPCOMING_ORDER_PRODUCT: "/vendor/upcoming-order/products",
  VENDOR_UPCOMING_ORDER_SERVICE: "/vendor/upcoming-order/service",
  VENDOR_PAST_ORDER_PRODUCT: "/vendor/past-order/product",
  VENDOR_PAST_ORDER_SERVICE: "/vendor/past-order/service",

  //vendor ad
  VENDOR_AD_LIST: "/vendor/ad-list",
  VENDOR_AD_ADD: "/vendor/ad-list/add-ad",
  VENDOR_AD_TYPE: "/vendor/ad-list/:type?/:method?",
  //vendor commision
  VENDOR_CASA_COMMISSION: "/vendor/casa-commission",
  VENDOR_CASA_COMMISSION_MORE_DETAILS: "/vendor/casa-commission/commission-details-view/:commission?",
  //association
  ASSOCIATION_HOME: "association/home",
  ASSOCIATION_EVENTS_TYPE: "/association/associationEvents/:type?/:method?",

  ASSOCIATION_EVENTS: "/association/associationEvents/add-Events",
  ASSOCIATION_EVENT_LIST: "/association/eventList",
  ASSOCIATION_EVENT_ENQUIRY_LIST: "/association/event/enquiry-list",
  ASSOCIATION_OWNERS: "/association/associationOwners",
  COMMUNITY_ENRICH_LIST: "/association/community-enrich-List",
  COMMUNITY_ENRICH_ADD: "/association/editCommunityEnrich/:productType?/:edit?",
  ASSOCIATION_VISITOR: "/association/visitor/visitor-list",
  ASSOCIATION_OWNER_LIST: "/association/owner-list",
  ASSOCIATION_REGISTRATION_LIST: "/association/registration-list",
  ASSOCIATION_ASSETS: "/association/assets",
  ASSOCIATION_REPORT: "/association/report",
  NOTIFICATION: "/notification",
  //amenities
  ASSOCIATION_AMENITIES_TYPE: "/association/amenities/:type",
  ASSOCIATION_AMENITIES_LIST: "/association/amenitiesList",
  ASSOCIATION_AMENITIES_ADD: "/association/amenities/add-amenity",

  //
  ASSOCIATION_INCOME_TYPE: "/association/income/:type?/:method?",
  ASSOCIATION_INCOME_ADD: "/association/income/income-add",
  ASSOCIATION_INCOME: "/association/income",
  //expense
  ASSOCIATION_EXPENSES_TYPE: "/association/expenses/:type?/:method?",
  ASSOCIATION_EXPENSES_ADD: "/association/expenses/expense-add",
  ASSOCIATION_EXPENSES: "/association/expenses",
  //maintenance
  ASSOCIATION_MAINTENANCE_TYPE: "/association/maintenance/:type?/:method?",
  ASSOCIATION_MAINTENANCE_ADD: "/association/maintenance/maintenance-add",
  ASSOCIATION_MAINTENANCE: "/association/maintenance",
  //stock
  ASSOCIATION_STOCK_MANAGEMENT: "/association/stock-management/:type?/:method?",
  ASSOCIATION_STOCK_MANAGEMENT_ADD: "/association/stock-management/add-stock/",
  ASSOCIATION_STOCK_MANAGEMENT_LIST: "/association/stockManagementList",

  //asset
  ASSOCIATION_ASSET: "/association/asset-management/:type?/:method?",
  ASSOCIATION_ASSET_ADD: "/association/asset-management/add-asset/",
  ASSOCIATION_ASSETS_LIST: "/association/assets-list",

  HELPERS_REQUEST_LIST: "/association/daily-helpers/request-list",

  //amenitis
  ASSOCIATION_AMENITIES_ENQUIRY_LIST: "/association/amenities/enquiry-list",

  //parking slot visitor
  ASSOCIATION_VISITOR_PARKING_SLOT: "/association/visitor/parking-slot",
  //add parking slot association
  ASSOCIATION_PARKING_SLOT_ADD_TYPE:
    "/association/parking-slot-list/:type?/:method?/:edit?",
  ASSOCIATION_PARKING_SLOT_ADD_NEW_LIST: "/association/parking-slot-list",

  //customer parkingslot mapping association
  ASSOCIATION_PARKING_SLOT_CUSTOMER: "/association/customer-parking-list",
  ASSOCIATION_PARKING_ADD:
    "/association/customer-parking-list/add-parking-slot/",


  ASSOCIATION_PARKING_TYPE: "/association/:type?/:method?/:edit?",
  //notification
  ASSOCIATION_NOTIFICATION_TYPE: "/association/notification/:type?/:method?",
  ASSOCIATION_NOTIFICATION_ADD: "/association/notification/add-notification",
  ASSOCIATION_NOTIFICATION_LIST: "/association/notification-list",

  //maintenance config
  ASSOCIATION_MAINTENANCE_CONFIG: "/association/maintenance-configuration",
  ASSOCIATION_HELPERS_TYPE: "/association/helpers/:type?/:method?",
  ASSOCIATION_HELPERS_LIST: "/association/helpers-list",
  ASSOCIATION_ADD_HELPERS: "/association/helpers/add-helpers",
  //security
  SECURITY_HOME: "/security/home",
  //security visitor
  SECURITY_VISITOR: "/security/visitor-list",
  //visitor parking slot
  SECURITY_PARKING_SLOT: "/security/visitor-allotment/parking-slot",
  //parking slot mapping
  SECURITY_PARKING_SLOT_LIST: "/security/customer-parking-list",
  SECURITY_PARKING_ADD: "/security/customer-parking-list/add-parking-slot/",
  SECURITY_PARKING_TYPE: "/security/customer-parking-list/:method?/:edit?",
  //helpers
  SECURITY_HELPERS_REQUEST_LIST: "/security/daily-helpers/request-list",
  //add parking slot security
  SECURITY_PARKING_SLOT_ADD_TYPE:
    "/security/parking-slot-list/:type?/:method?/:edit?",
  SECURITY_PARKING_SLOT_ADD_NEW_LIST: "/security/parking-slot-list",

};
