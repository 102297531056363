import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const bannerApi = createApi({
    reducerPath: "banner",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['banner'],
    endpoints: (builder) => ({
        getListOfBanner: builder.query<any,  {pages:number,searchName:string}>({
            query: ({pages,searchName}) => ({
                url: "/banner/",
                method: "GET",
                params: {
                  search:searchName,
                page: String(pages),
              },
            }),
            providesTags: ['banner']
        }),
        getSingleBanner: builder.query<any, {editId:number}>({
            query: ({editId}) => ({
              url: `/banner/${editId}/`,
              method: "GET",
            }),
            providesTags: ["banner"],
          }),
        
        addBanner: builder.mutation<any, object>({
            query: (joinMilestoneInfo: object) => ({
              url: "/banner/",
              method: "POST",
              body: joinMilestoneInfo,
            }),
            invalidatesTags: ["banner"],
          }),
      
        deleteBanner: builder.mutation<any, {bannerId:any}>({
          query: ({bannerId}) => ({
            url: `/banner/${bannerId}`,
            method: "DELETE",
          }),
          invalidatesTags: ['banner']
        }),
        updateBanner: builder.mutation<any, {bannerId:any,bannerData:any}>({
          query: ({bannerId,bannerData}) => ({
            url: `/banner/${bannerId}/`,
            method: "PATCH",
            body:bannerData
          }),
          invalidatesTags: ['banner']
        }),
        
    }),

});

export const { useGetListOfBannerQuery ,useGetSingleBannerQuery ,useAddBannerMutation,useUpdateBannerMutation ,useDeleteBannerMutation} = bannerApi;