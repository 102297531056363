import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const registrationOfficeApi = createApi({
  reducerPath: "registrationOffice",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["registrationOffice"],
  endpoints: (builder) => ({
    getListOfRegistrationOffice: builder.query<any, { pages: any ,searchName:any}>({
      query: ({ pages,searchName }) => ({
        url: "/registration-office/",
        method: "GET",
        params: {
          page: String(pages),
          searchName: searchName,
        },
      }),
      providesTags: ["registrationOffice"],
    }),
    addRegistrationOffice: builder.mutation<any, any>({
      query: (RegistrationData) => ({
        url: "/registration-office/",
        method: "POST",
        body: RegistrationData,
      }),
      invalidatesTags: ["registrationOffice"],
    }),
    SingleRegistrationOfficeData: builder.query<any, { slotEditId: any }>({
      query: ({ slotEditId }) => ({
        url: `/registration-office/${slotEditId}/`,
        method: "GET",
      }),
      providesTags: ["registrationOffice"],
    }),
    editRegistrationOffice: builder.mutation< any, { EditId: any; editData: any } >({
      query: ({ EditId, editData }) => ({
        url: `/registration-office/${EditId}/`,
        method: "PUT",
        body: editData,
      }),
      invalidatesTags: ["registrationOffice"],
    }),
   
  }),
});

export const {
useGetListOfRegistrationOfficeQuery,
useAddRegistrationOfficeMutation,
useSingleRegistrationOfficeDataQuery,
useEditRegistrationOfficeMutation
} = registrationOfficeApi;
