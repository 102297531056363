import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const aminitiesListApi = createApi({
  reducerPath: "amenities",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["amenities"],
  endpoints: (builder) => ({
    getAllListOfAminitites: builder.query<any, void>({
      query: () => ({
        url: "/amenity/",
        method: "GET",
      }),
      providesTags: ["amenities"],
    }),
    getAllListOfAminititesWithPagination: builder.query<any,{ pages: number; searchName: string }>({
      query: ({ pages, searchName }) => ({
        url: "/amenity/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          paginated: 1,
        },
      }),
      providesTags: ["amenities"],
    }),
    singleAmenitiesData: builder.query<any, { id: any }>({
      query: ({ id }) => ({
        url: `/amenity/${id}/`,
        method: "GET",
      }),
      providesTags: ["amenities"],
    }),

    AddAminitites: builder.mutation<any, any>({
      query: (formData) => ({
        url: "/amenity/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["amenities"],
    }),
    deleteAminitites: builder.mutation<any, any>({
      query: (id: any) => ({
        url: `/amenity/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["amenities"],
    }),
    updateAminitites: builder.mutation<any, any>({
      query: ({ amenitiesData, id }) => ({
        url: `/amenity/${id}/`,
        method: "PUT",
        body: amenitiesData,
      }),
      invalidatesTags: ["amenities"],
    }),
    amenitiesEnquiryList: builder.query<any, { pages: any ,searchName:any}>({
      query: ({ pages ,searchName}) => ({
        url: `/amenity-booking/`,
        method: "GET",
        params: {
          dashboard: 1,
          page: String(pages),
          paginated: 1,
          upcoming_booking:1,
          search:searchName
        },
      }),
      providesTags: ["amenities"],
    }),
    amenitiesEnquiryStatus: builder.mutation<any, {id:any,status:any}>({
      query: ({ id,status }) => ({
        url: `/amenity-booking/${id}/`,
        method: "PUT",
        body:status
      }),
      invalidatesTags: ["amenities"],
    }),
  }),
});

export const {
  useGetAllListOfAminititesQuery,
  useGetAllListOfAminititesWithPaginationQuery,
  useSingleAmenitiesDataQuery,
  useAddAminititesMutation,
  useDeleteAminititesMutation,
  useUpdateAminititesMutation,
  useAmenitiesEnquiryListQuery,
  useAmenitiesEnquiryStatusMutation
  
} = aminitiesListApi;
