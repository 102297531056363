import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const paymentApi = createApi({
    reducerPath: "payment",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['payment'],
    endpoints: (builder) => ({
        getListOfPayment: builder.query<any,  {pages:number,searchName:string,projectId:any}>({
            query: ({pages,searchName,projectId}) => ({
                url: "/my-payments/",
                method: "GET",
                params: {
                  search:searchName,
                page: String(pages),
                project_id:projectId
              },
            }),
            providesTags: ['payment']
        }),
        getSinglePaymentData: builder.query<any, {editId:number}>({
            query: ({editId}) => ({
              url: `/my-payments/${editId}/`,
              method: "GET",
            }),
            providesTags: ["payment"],
          }),
        
        addPayment: builder.mutation<any, object>({
            query: (paymentData: object) => ({
              url: "/my-payments/",
              method: "POST",
              body: paymentData,
            }),
            invalidatesTags: ["payment"],
          }),
      
        deletePayment: builder.mutation<any, any>({
          query: (milestoneId: any) => ({
            url: `/my-payments/`,
            method: "DELETE",
            params: {
              milestone_id: milestoneId,
            },
          }),
          invalidatesTags: ['payment']
        }),
        updatePayment: builder.mutation<any, {paymentId:any,paymentData:any}>({
          query: ({paymentId,paymentData}) => ({
            url: `/my-payments/${paymentId}/`,
            method: "PATCH",
            body:paymentData
          }),
          invalidatesTags: ['payment']
        }),
        
    }),

});

export const { useGetListOfPaymentQuery ,useGetSinglePaymentDataQuery ,useAddPaymentMutation, useUpdatePaymentMutation ,useDeletePaymentMutation} = paymentApi;