import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const vendorListReducer = createApi({
    reducerPath: "vendorListReducer",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['vendor-list'],
    endpoints: (builder) => ({
      //ticket
        getVendorsList: builder.query<any,{search:any,page:any}>({
            query: ({search,page}) => ({
                url: "/vendor-register/",
                method: "GET",
                params:{
                    search:search,
                    page:page,
                }
            }),
            providesTags: ["vendor-list"]
        }),
       
      
    }),
});

export const {useGetVendorsListQuery} = vendorListReducer;