import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const associationDashboardApi = createApi({
  reducerPath: "associationDashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["association-dashboard"],
  endpoints: (builder) => ({
    associationDashboardValue: builder.query< any,void >({
      query: () => ({
        url: "/association-dashboard/",
        method: "GET",
      }),
      providesTags: ["association-dashboard"],
    }),
  }),
});

export const {
  useAssociationDashboardValueQuery
} = associationDashboardApi;
