import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const ownerApi = createApi({
  reducerPath: "owner",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
      return headers;
    },
  }),
  tagTypes: ["owner", "single-owner-tenant", "single-owner"],
  endpoints: (builder) => ({
    getListOfOwnersWithTenant: builder.query<any, {pages:number,searchName:string}>({
      query: ({pages,searchName}) => ({
        url: "/owner-with-tenants/",
        method: "GET",
        params: {
          search:searchName,
        page: String(pages),
      },
      }),
      providesTags: ["owner"],
    }),
    getEditOwnerTenant: builder.query<any, { tenantId: any }>({
      query: ({ tenantId }) => ({
        url: `/owner-with-tenants/${tenantId}/`,
        method: "GET",
      }),
      providesTags: ["single-owner-tenant"],
    }),
    getOwner: builder.query<any, { ownerId: any }>({
      query: ({ ownerId }) => ({
        url: `/customers/${ownerId}/`,
        method: "GET",
      }),

      providesTags: ["single-owner"],
    }),
    removeTenant: builder.mutation<any,any>({
      query: ( data ) => ({
        url: `/tenant-list/${data.tenantId}/`,
        method: "PUT",
        body: data
      }),
      invalidatesTags: ["single-owner-tenant","owner"]
    }),
    editTenant: builder.mutation<any,{formData:any,tenantId:any}>({
      query: ( {formData,tenantId} ) => ({
        url: `/tenant-list/${tenantId}/`,
        method: "PUT",
        body: formData
      }),
      invalidatesTags: ["owner",'single-owner-tenant'],
    }),
    addTenant: builder.mutation<any,any>({
      query: (formData) => ({
        url: `/tenant-list/`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["owner",'single-owner-tenant'],
    }),
  }),
});

export const { useGetListOfOwnersWithTenantQuery, useGetEditOwnerTenantQuery, useGetOwnerQuery, useRemoveTenantMutation ,useEditTenantMutation  ,useAddTenantMutation} = ownerApi;
