import { Outlet, Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { PATH } from "../route/path";

const AdminRoutes = () => {
  const token: any = localStorage.getItem("dashboard-token")
  let userData: any = jwt_decode(token);
  return userData.type === "admin" ? <Outlet /> : userData.type === "staff"? <Navigate to={PATH.ASSOCIATION_HOME} />:<Navigate to = {PATH.VENDOR_HOME}/>;
};

export default AdminRoutes;
