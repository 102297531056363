import { Outlet, Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { PATH } from "../route/path";
const AssociationRoutes = () => {
  const token: any = localStorage.getItem("dashboard-token")
  let userData: any = jwt_decode(token);
  return userData.type === "staff" &&   userData.department != "security"  ? <Outlet /> : userData.department === "security" ? <Navigate to={PATH.SECURITY_HOME} /> :  <Navigate to={PATH.VENDOR_HOME} /> ;
};

export default AssociationRoutes;
