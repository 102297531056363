import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const vendorDashboardApi = createApi({
    reducerPath: "vendorDashboardApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem("dashboard-token")}`
            );
            return headers;
        },
    }),
    tagTypes: ["roles"],
    endpoints: (builder) => ({
        getDashboardDetails: builder.query<any,{pages:any}>({
            query: ({pages}) => ({
                url: "/vendor-dashboard/dashboard/",
                method: "GET",
                params:{
                    page:pages
                }
            }),
            providesTags: ["roles"],
        }),
   
    }),
});

export const {
    useGetDashboardDetailsQuery
} = vendorDashboardApi;
