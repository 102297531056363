import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";



export const locationApi = createApi({
    reducerPath: "location",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['location'],
    endpoints: (builder) => ({
        GetAllListOfLocationPagination: builder.query<any, {pages:any,searchName:any}>({
            query: ({pages,searchName}) => ({
                url: "/location-list/",
                method: "GET",
                params: {
                    page:pages,
                    search:searchName
                 },
            }),
            providesTags: ['location']
        }),
        GetAllListOfLocation: builder.query<any,void>({
            query: () => ({
                url: "/location-list/",
                method: "GET",
               
            }),
            providesTags: ['location']
        }),
        AddLocation: builder.mutation<any, object>({
            query: (productInfo: object) => ({
              url: "/location-list/",
              method: "POST",
              body: productInfo,
            }),
            invalidatesTags: ["location"],
          }),
          deleteLocation: builder.mutation<any, any>({
            query: (id:any) => ({
                url: `/location-list/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['location']
        }),
        updateLocation: builder.mutation<any, any>({
            query: (locationData:any) => ({
                url: `/location-list/${locationData.id}/`,
                method: "PATCH",
                body: locationData.locationData,
            }),
            invalidatesTags: ['location']
        }),


    }),

});

export const {useGetAllListOfLocationPaginationQuery, useGetAllListOfLocationQuery, useAddLocationMutation,useDeleteLocationMutation,useUpdateLocationMutation } = locationApi;
