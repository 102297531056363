import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const testimonialListApi = createApi({
  reducerPath: "testimonialList",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["testimonialList"],
  endpoints: (builder) => ({
    getAllTestimonialList: builder.query< any,{ pages: number; searchName: string ,projectId:any}>({
        query: ({ pages, searchName ,projectId}) => ({
          url: "/review/?review_type=testimonial",
          method: "GET",
          params: {
            search: searchName,
            page: String(pages),
            project_id:projectId
          },
        }),
        providesTags: ["testimonialList"],
      }),
    TestimonialStatus: builder.mutation<any, {id:any,approve_testimonial:any}>({
        query: ({ id,approve_testimonial }) => ({
          url: `/review/${id}/`,
          method: "PUT",
          body:approve_testimonial
        }),
        invalidatesTags: ["testimonialList"],
      }),
  }),
});
export const { useGetAllTestimonialListQuery,useTestimonialStatusMutation } = testimonialListApi;
