import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";
import { AnyARecord } from "dns";

export const ticketApi = createApi({
  reducerPath: "ticket",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["tickets", "ticket-doc", "ticket-remark","merge-ticket","changes-history"],
  endpoints: (builder) => ({
    //ticket
    getListOfSupportTickets: builder.query<
      any,
      {
        searchName: any;
        pages: any;
        project: any;
        status: any;
        issueCategory: any;
        subCategory: any;
        priority: any;
        department: any;
        staffCreator: any;
        customerCategory: any;
        callStatus: any;
        siteStatus: any;
        taskStatus: any;
        reqType: any;
        customerStatus: any;
      }
    >({
      query: ({
        searchName,
        pages,
        project,
        status,
        issueCategory,
        subCategory,
        priority,
        department,
        staffCreator,
        customerCategory,
        callStatus,
        siteStatus,
        taskStatus,
        reqType,
        customerStatus,
      }) => ({
        url: "/service-request/",
        method: "GET",
        params: {
          search: searchName,
          page: pages,
          project: project.join(","),
          status: status.join(","),
          issue_category: issueCategory,
          sub_category: subCategory.join(","),
          priority: priority.join(","),
          department: department.join(","),
          staff_creator: staffCreator.join(","),
          customer_category: customerCategory.join(","),
          call_status: callStatus.join(","),
          site_status: siteStatus.join(","),
          task_status: taskStatus.join(","),
          request_type: reqType.join(","),
          customer_status: customerStatus.join(","),
        },
      }),
      providesTags: ["tickets"],
    }),
    getSingleSupportTicket: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/service-request/${editId}/`,
        method: "GET",
      }),
      providesTags: ["tickets"],
    }),
    updateSupportTicket: builder.mutation<any, { editId: any; editData: any }>({
      query: ({ editId, editData }) => ({
        url: `/service-request/${editId}/`,
        method: "PUT",
        body: editData,
      }),
      invalidatesTags: ["tickets"],
    }),
    //ticket-department
    getListOfDepartment: builder.query<any, void>({
      query: () => ({
        url: "/department/",
        method: "GET",
      }),
    }),
    //ticket-issue
    getListOfIssue: builder.query<any, void>({
      query: () => ({
        url: "/query-type/",
        method: "GET",
      }),
    }),
    //filter staff list
    getListOfStaff: builder.query<any, void>({
      query: () => ({
        url: "/staff-user/",
        method: "GET",
      }),
    }),
    //add document
    addTicketDocument: builder.mutation<any, { id: any; data: any }>({
      query: ({ id, data }) => ({
        url: `/service-request/attachment/?service_request_id=${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ticket-doc"],
    }),
    getTicketDocument: builder.query<any, { id: any }>({
      query: ({ id }) => ({
        url: `/service-request/attachment/?service_request_id=${id}`,
        method: "GET",
      }),
      providesTags: ["ticket-doc"],
    }),
    //addnote
    addTicketNote: builder.mutation<any, { id: any; data: any }>({
      query: ({ id, data }) => ({
        url: `/service-request/remark/?service_request_id=${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ticket-remark"],
    }),
    getTicketNote: builder.query<any, { id: any }>({
      query: ({ id }) => ({
        url: `/service-request/remark/?service_request_id=${id}`,
        method: "GET",
      }),
      providesTags: ["ticket-remark"],
    }),
    updateTicketNote: builder.mutation<any, { id: any; data: any }>({
      query: ({ id, data }) => ({
        url: `/service-request/remark/?remark_id=${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ticket-remark"],
    }),
    //merge ticket
    mergeTicket: builder.mutation<any, { data: any }>({
      query: (data) => ({
        url: `/merge-tickets/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["merge-ticket"],
    }),
    getTicketList: builder.query<any, { id: any }>({
      query: ({ id }) => ({
        url: `/merge-tickets/?service_request_id=${id}`,
        method: "GET",
      }),
      providesTags: ["merge-ticket"],
    }),
    addTicket: builder.mutation<any, any>({
      query: (ticketInfo:any) => ({
        url: `/service-request/`,
        method: "POST",
        body:ticketInfo
      }),
      invalidatesTags: ["tickets"],
    }),
  //changeHistory
  updateHistoryStatus: builder.mutation<any, { data:any,editId:any }>({
    query: ({data,editId}) => ({
      url: `/service-request-history/?service_request_id=${editId}`,
      method: "POST",
      body: data,
    }),
    invalidatesTags: ["changes-history"],
  }),
getHistoryChanges: builder.query<any, { id: any }>({
    query: ({ id }) => ({
      url: `/service-request-history/?service_request_id=${id}`,
      method: "GET",
    }),
    providesTags: ["changes-history"],
  }),
  }),

});

export const {
  useGetListOfSupportTicketsQuery,
  useGetSingleSupportTicketQuery,
  useUpdateSupportTicketMutation,
  useGetListOfDepartmentQuery,
  useGetListOfIssueQuery,
  useGetListOfStaffQuery,
  useAddTicketDocumentMutation,
  useGetTicketDocumentQuery,
  useAddTicketNoteMutation,
  useGetTicketNoteQuery,
  useUpdateTicketNoteMutation,
  useMergeTicketMutation,
  useGetTicketListQuery,
  useAddTicketMutation,
  useUpdateHistoryStatusMutation,
  useGetHistoryChangesQuery
} = ticketApi;
