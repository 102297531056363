import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const documentListApi = createApi({
  reducerPath: "document",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["document"],
  endpoints: (builder) => ({
    getAllListOfCustomer: builder.query<any, void>({
      query: () => ({
        url: "/customers/",
        method: "GET",
        params: {
          paginated: false,
        },
      }),
      providesTags: ["document"],
    }),

    // getAllListOfCustomerDocuments: builder.query<any, {currentPages:number,searchCurrentName:string}>({
    //     query: ({currentPages,searchCurrentName}) => ({
    //       url: "/customers/",
    //       method: "GET",
    //       params: {
    //           search:searchCurrentName,
    //         page: String(currentPages),
    //         paginated:false
    //       },
    //     }),
    //     providesTags: ["document"],
    //   }),
    AddDocument: builder.mutation<any, object>({
      query: (document: object) => ({
        url: "/customer-project-documents/",
        method: "POST",
        body: document,
      }),
      invalidatesTags: ["document"],
    }),
    deleteDocument: builder.mutation<any, any>({
      query: (id: any) => ({
        url: `/customer-project-documents/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["document"],
    }),
    updateDocument: builder.mutation<any, any>({
      query: (documment: any) => ({
        url: `/customer-project-documents/${documment.id}/`,
        method: "PATCH",
        body: documment.formData,
      }),
      invalidatesTags: ["document"],
    }),
    getListOfCustomerWithDocuments: builder.query< any,{ pages: number; searchName: string ,projectId:any}>({
      query: ({ pages, searchName ,projectId}) => ({
        url: "/customers-document/",
        method: "GET",
        params: {
          search: searchName,
          page: String(pages),
          project_id:projectId
        },
      }),
      providesTags: ["document"],
    }),
    getListOfReqDocuments: builder.query< any,{ pages: number; searchName: string ,projectId:any}>({
      query: ({  pages, searchName ,projectId}) => ({
        url: "/request-documents/",
        method: "GET",
        params: {
          page: String(pages),
          search: searchName,
          project_id:projectId
        },
      }),
      providesTags: ["document"],
    }),
  }),
});

export const {
  useGetAllListOfCustomerQuery,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useGetListOfCustomerWithDocumentsQuery,
  useGetListOfReqDocumentsQuery
} = documentListApi;
