import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const securityDashboardApi= createApi({
  reducerPath: "security-Dashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
      return headers;
    },
  }),
  tagTypes: ["security-Dashboard"],
  endpoints: (builder) => ({
    securityDashboardList: builder.query<any, void>({
      query: () => ({
        url: "/association-dashboard/",//dashboard/",
        method: "GET",
      }),
      providesTags: ["security-Dashboard"],
    }),
    upcomingVisitor: builder.query<any, void>({
      query: () => ({
        url: "/visitors/",
        method: "GET",
        params:{
            type:"upcoming"
        }
      }),
      providesTags: ["security-Dashboard"],
    }),
  }),
});
export const {useSecurityDashboardListQuery,useUpcomingVisitorQuery} = securityDashboardApi;
