import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const maintenanceApi = createApi({
  reducerPath: "maintenance",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["maintenance","maintenance-config"],
  endpoints: (builder) => ({
    getMonthlyMaintenance: builder.query<any, any>({
      query: (data) => ({
        url: "/maintenance/",
        method: "GET",
        params: {
          month: data.month,
          year: data.year,
          page: data.page,
        },
      }),
      providesTags: ["maintenance"],
    }),
    singleMaintenance: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/maintenance/${editId}/`,
        method: "GET",
      }),
      providesTags: ["maintenance"],
    }),
    updateMaintenance: builder.mutation<any, { editId: any; maintenanceData: any }>(
      {
        query: ({ editId, maintenanceData }) => ({
          url: `/maintenance/${editId}/`,
          method: "PATCH",
          body: maintenanceData,
        }),
        invalidatesTags: ["maintenance"],
      }
    ),
    runMaintenaceSchedule: builder.mutation<any, any>(
      {
        query: (data) => ({
          url: `/maintenance-schedule/create_customer_maintenace_schedule/`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["maintenance"],
      }
    ),
    createMaintenanceConfig: builder.mutation<any, any>(
      {
        query: (data) => ({
          url: `/maintenance-config/`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["maintenance-config"],
      }
    ),
    getMaintenanceConfig: builder.query<any, {project:any}>(
      {
        query: ({project}) => ({
          url: `/maintenance-config/`,
          method: "GET",
          // body: data,
          params:{
            project_id:project
          }
        }),
        providesTags: ["maintenance-config"],
      }
    ),
    updateMaintenanceConfig: builder.mutation<any, any>(
      {
        query: (data:any) => ({
          url: `/maintenance-config/${data?.id}/`,
          method: "PUT",
          body: data?.maintenanceInfo,
        }),
        invalidatesTags: ["maintenance-config"],
      }
    ),

  }),
});

export const { useGetMonthlyMaintenanceQuery, useSingleMaintenanceQuery, useUpdateMaintenanceMutation, useRunMaintenaceScheduleMutation, useCreateMaintenanceConfigMutation ,useGetMaintenanceConfigQuery,useUpdateMaintenanceConfigMutation} = maintenanceApi;
