import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const subCategoriesApi = createApi({
    reducerPath: "subCategoriesApi",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
        }),
    tagTypes: ['subCategory-list'],
    endpoints: (builder) => ({
        getAllListOfSubCategories: builder.query<any,any>({
            query: (value) => ({
                url: `/subcategory-list/?product_category=${value}`,
                method: "GET",
            providesTags: ['subCategory-list']
            }),
        }),
    }),
});

export const { useGetAllListOfSubCategoriesQuery} = subCategoriesApi;
