import { Outlet, Navigate } from "react-router-dom";
import { PATH } from "../route/path";
import jwt_decode from "jwt-decode";

const VendorRoutes = () => {
  const token: any = localStorage.getItem("dashboard-token")
  let userData: any = jwt_decode(token);
  return userData.type === "vendor" ? <Outlet /> : userData.type === "staff" ? <Navigate to={PATH.ASSOCIATION_HOME} /> : <Navigate to={PATH.HOME_ADMIN} />;
};

export default VendorRoutes;
