import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const associationApi = createApi({
  reducerPath: "associationApissss",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("dashboard-token")}`
      );
      return headers;
    },
  }),
  tagTypes: ["income", "expense"],
  endpoints: (builder) => ({
    //dashbaordCardAPi
    getIncomeExpense: builder.query<any, any>({
      query: (data) => ({
        url: "/income-expense-status/",
        method: "GET",
        params: {
          month: data.month,
          year: data.year,
        },
      }),
      providesTags: ["expense"],
    }),
    //income
    getMonthlyIncome: builder.query<any, any>({
      query: (data) => ({
        url: "/association-income/",
        method: "GET",
        params: {
          month: data.month,
          year: data.year,
          page: data.page,
        },
      }),
      providesTags: ["income"],
    }),
    //expense
    getMonthlyExpense: builder.query<any, any>({
      query: (data) => ({
        url: "/association-expenses/",
        method: "GET",
        params: {
          month: data.month,
          year: data.year,
          page: data.page,
        },
      }),
      providesTags: ["expense"],
    }),
    //monthly maintenence dashboard
    getMonthlyMaintanance: builder.query<any, any>({
      query: (data) => ({
        url: "/maintenance/maintenance_dashboard/",
        method: "GET",
        params: {
          month: data.month,
          year: data.year,
          page: data.page,
        },
      }),
      providesTags: ["expense"],
    }),
    getMonthlyMaintananceRecievedTable: builder.query<any, any>({
      query: (data) => ({
        url: "/maintenance-status/",
        method: "GET",
        params: {
          month: data.month,
          year: data.year,
          page: data.page || 1,
        },
      }),
      providesTags: ["expense"],
    }),
    getMonthlyMaintananceCollectedTable: builder.query<any, any>({
      query: (data: any) => ({
        url: "/maintenance/maintenance_pending_list_dashboard/",
        method: "GET",
        params: {
          month: data.month,
          year: data.year,
          page: data.page || 1,
        },
      }),
      providesTags: ["expense"],
    }),
    addIncome: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "/association-income/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["income"],
    }),

    singleIncome: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/association-income/${editId}/`,
        method: "GET",
      }),
      providesTags: ["income"],
    }),
    updateIncome: builder.mutation<any, { editId: any; incomeData: any }>({
      query: ({ editId, incomeData }) => ({
        url: `/association-income/${editId}/`,
        method: "PATCH",
        body: incomeData,
      }),
      invalidatesTags: ["income"],
    }),
    addExpense: builder.mutation<any, any>({
      query: (data) => ({
        url: "/association-expenses/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["expense"],
    }),
    singleExpense: builder.query<any, { editId: any }>({
      query: ({ editId }) => ({
        url: `/association-expenses/${editId}/`,
        method: "GET",
      }),
      providesTags: ["expense"],
    }),
    updateExpense: builder.mutation<any, { editId: any; expenseData: any }>({
      query: ({ editId, expenseData }) => ({
        url: `/association-expenses/${editId}/`,
        method: "PATCH",
        body: expenseData,
      }),
      invalidatesTags: ["expense"],
    }),
  }),
});

export const {
  useGetIncomeExpenseQuery,
  useGetMonthlyIncomeQuery,
  useGetMonthlyMaintananceQuery,
  useGetMonthlyExpenseQuery,
  useGetMonthlyMaintananceCollectedTableQuery,
  useGetMonthlyMaintananceRecievedTableQuery,
  useAddIncomeMutation,
  useSingleIncomeQuery,
  useUpdateIncomeMutation,
  useAddExpenseMutation,
  useSingleExpenseQuery,
  useUpdateExpenseMutation,
} = associationApi;
