import { Outlet, Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { PATH } from "../route/path";

const PrivateRoutes = () => {
  const token: any = localStorage.getItem("dashboard-token")
  if (token) {
    let userData: any = jwt_decode(token);
    let expiryTimeStamp = userData.exp
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (currentTimestamp >= expiryTimeStamp) {
      localStorage.removeItem("dashboard-token");
    }
    return userData.type === "admin" || "staff" || "vendor" ? <Outlet /> : <Navigate to={PATH.LOGIN} />;
  }
  return <Navigate to={PATH.LOGIN} />
};

export default PrivateRoutes;
