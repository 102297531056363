import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const shopListApi = createApi({
    reducerPath: "shop",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['shops'],
    endpoints: (builder) => ({
        getAllListOfShopsPagination: builder.query<any, {pages:any,searchName:any}>({
            query: ({pages,searchName}) => ({
                url: "/shop-list/",
                method: "GET",
                params:{
                    page:pages,
                    search:searchName
                }
            }),
            providesTags: ['shops']
        }),
        getAllListOfShops: builder.query<any, void>({
            query: () => ({
                url: "/shop-list/",
                method: "GET",
            }),
            providesTags: ['shops']
        }),
        AddShops: builder.mutation<any, object>({
            query: (shopInfo: object) => ({
                url: "/shop-list/",
                method: "POST",
                body: shopInfo,
            }),
            invalidatesTags: ["shops"],
        }),
        deleteShop: builder.mutation<any, any>({
            query: (code:any) => ({
                url: `/shop-list/${code}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['shops']
        }),
        updateShop: builder.mutation<any, any>({
            query: (editInfo: any) => ({
                url: `/shop-list/${editInfo.code}/`,
                method: "PATCH",
                body: editInfo.shopData,
            }),
            invalidatesTags: ['shops']
        }),
        getAllListOfShopsForDropDown: builder.query<any, void>({
            query: () => ({
                url: "/shop-list/?purpose=dropdown",
                method: "GET",
            }),
            providesTags: ['shops']
        }),
        

    }),

});

export const { useGetAllListOfShopsPaginationQuery, useGetAllListOfShopsQuery, useAddShopsMutation, useDeleteShopMutation, useUpdateShopMutation,useGetAllListOfShopsForDropDownQuery } = shopListApi;
