import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const securityVisitorApi = createApi({
  reducerPath: "securityVisitorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
      return headers;
    },
  }),
  tagTypes: ["visitor"],
  endpoints: (builder) => ({
    getUpcomingAndTodayVisitorsList: builder.query<any, {pages:any,type:any}>({
      query: ({pages,type}) => ({
        url: "/visitors/",
        method: "GET",
        params: {
          type: type,
          page:pages
        }
      }),
      providesTags: ["visitor"],
    }),
  }),
});
// https://casagrandsupport.info/api/visitors/update_visitor_date_of_entry/
export const { useGetUpcomingAndTodayVisitorsListQuery} = securityVisitorApi;
