import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";

export const supportTeamApi = createApi({
    reducerPath: "supportTeamApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem("dashboard-token")}`
            );
            return headers;
        },
    }),
    tagTypes: ["support-team"],
    endpoints: (builder) => ({
        addSupportTeam: builder.mutation<any,any>({
            query: (supportTeamInfo) => ({
                url: "/customer-support-team/",
                method: "POST",
                body:supportTeamInfo ,
              
            }),
            invalidatesTags: ["support-team"],
        }),
        getUnassignedCustomers:builder.query<any, {projectId:any,supportId:any}>({
            query: ({projectId,supportId}) => ({
                url: `/customer-support-team/unassigned_customers/`,
                method: "GET",
                params:{
                    project_id:projectId,
                    support_team_id:supportId||"",
                }
            }),
            providesTags: ["support-team"],
        }),
        editSupportTeam: builder.mutation<any, {supportTeamInfo:any,id:string}>({
            query: ({supportTeamInfo,id}) => ({
                url: `/customer-support-team/${id}/`,
                method: "PUT",
                body: supportTeamInfo,
               
            }),
            invalidatesTags: ["support-team"],
        }),
        getListOfSupportTeams: builder.query<any, {page:any,search:any}>({
            query: ({page,search}) => ({
                url: `/customer-support-team/`,
                method: "GET",
                params:{
                    page:page,
                    search:search,
                    pagination:1
                }
            }),
            providesTags: ["support-team"],
        }),

        getSingleSupportTeam: builder.query<any, {  id: any }>({
            query: ({  id }) => ({
                url: `/customer-support-team/${id}/`,
                method: "GET",
              
            }),
            providesTags: ["support-team"],
        }),
        getInitialProfilePermissions: builder.query<any,void>({
            query: () => ({
                url: "/group_perm/",
                method: "GET",
                params: {
                    permissions: 1,
                },
            }),
            providesTags: ["support-team"],
        }),
        deleteSupportTeam: builder.mutation<any, {id:string}>({
            query: ({id}) => ({
                url: `/customer-support-team/${id}/`,
                method: "DELETE",               
            }),
            invalidatesTags: ["support-team"],
        }),
        
    }),
});

export const {
    useAddSupportTeamMutation,
    useEditSupportTeamMutation,
    useGetUnassignedCustomersQuery,
    useGetListOfSupportTeamsQuery,
    useGetSingleSupportTeamQuery,
    useGetInitialProfilePermissionsQuery,
    useDeleteSupportTeamMutation,
} = supportTeamApi;
