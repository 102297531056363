import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../base/constant";


export const assetManagementApi = createApi({
    reducerPath: "assetManagement",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Bearer ${localStorage.getItem("dashboard-token")}`);
            return headers
        }
    }),
    tagTypes: ['assetManagement'],
    endpoints: (builder) => ({
        listOfAssets: builder.query<any,  {pages:number,searchName:string}>({
            query: ({pages,searchName}) => ({
                url: "/association-assets/",
                method: "GET",
                params: {
                    search:searchName,
                  page: String(pages),
                },
            }),
            providesTags: ['assetManagement']
        }),
        singleAssetmanagementData: builder.query<any, {id:any}>({
            query: ({id}) => ({
                url: `/association-assets/${id}/`,
                method: "GET",
            }),
            providesTags: ['assetManagement']
        }),
        
        addAssetManagement: builder.mutation<any, any>({
            query: (formData) => ({
              url: "/association-assets/",
              method: "POST",
              body: formData,
            }),
            invalidatesTags: ["assetManagement"],
          }),
          deleteAssetManagement: builder.mutation<any, {id:any}>({
            query: ({id}) => ({
                url: `/association-assets/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['assetManagement']
        }),
        updateAssetManagement: builder.mutation<any,{assetManagementData:any,assetId:any}>({
            query: ({assetManagementData,assetId}) => ({
                url: `/association-assets/${assetId}/`,
                method: "PUT",
                body: assetManagementData,
            }),
            invalidatesTags: ['assetManagement']
        }),
    }),

});

export const {useListOfAssetsQuery ,useSingleAssetmanagementDataQuery , useAddAssetManagementMutation ,useDeleteAssetManagementMutation,useUpdateAssetManagementMutation} =assetManagementApi ;
