import { setEbTaxAmountErrMessage, setEbTaxContactUsErrMessage, setEbTaxReqDocErrMessage, setPropertyServiceProjectErrMessage, setPropertyTaxAmountErrMessage, setPropertyTaxContactUsErrMessage, setPropertyTaxReqDocErrMessage } from "../../../base/reducer/adminErrorMessageReducer";
import { doValidatePropertyServicesProject, doValidateEbTaxReqDoc, doValidateEbTaxAmount, doValidateEbTaxContactUs, doValidatePropertyTaxReqDoc, doValidatePropertyTaxAmount, doValidatePropertyTaxConatctus } from "../../../base/utils/adminUtils";
import { PERMISSIONAME } from "../../../permissionPath/permissionPath";


export interface propertyServicesInfoTypes {
  project: string;
  ebTaxReqDoc: any[];
  ebTaxAmount: string;
  ebTaxContactUs: string;
  propTaxReqDoc: any[];
  propTaxAmount: string;
  propTaxContactUs: string;
  isPaid: boolean;
}

export const propertyServicesInitialState: propertyServicesInfoTypes = {
  project: "",
  ebTaxReqDoc: [],
  ebTaxAmount: "",
  ebTaxContactUs: "",
  propTaxReqDoc: [],
  propTaxAmount: "",
  propTaxContactUs: "",
  isPaid: false,
};

export const propertyServicesValidation = (event: React.ChangeEvent<HTMLInputElement>,dispatch: any) => {
  const { name, value, files }: any = event.target;
  let mobileRegex = /^\d{10}$/;
  switch (name) {
    case "project":
      if (value === "") {
        dispatch(setPropertyServiceProjectErrMessage("Kindly select the project"));
      } else {
        dispatch(setPropertyServiceProjectErrMessage(""));
      }
      break;
    case "ebTaxAmount":
      if (value === "") {
        dispatch(setEbTaxAmountErrMessage("Kindly enter the Eb Tax Amount"));
      } else {
        dispatch(setEbTaxAmountErrMessage(""));
      }
      break;
    case "ebTaxContactUs":
      if (value?.trim()) {
        if (mobileRegex.test(value)) {
          dispatch(setEbTaxContactUsErrMessage(""));
        } else {
          dispatch(setEbTaxContactUsErrMessage("Mobile Number should contain only 10 numbers"));
        }
      } else {
        dispatch(setEbTaxContactUsErrMessage("Kindly enter Eb tax contact us") );
      }
      break;
    
    case "propTaxAmount":
      if (value === "") {
        dispatch(setPropertyTaxAmountErrMessage("Kindly enter the Property Tax Amount"));
      } else {
        dispatch(setPropertyTaxAmountErrMessage(""));
      }
      break;
    case "propTaxContactUs":
      if (value?.trim()) {
        if (mobileRegex.test(value)) {
             dispatch(setPropertyTaxContactUsErrMessage(""));
        } else {
          dispatch(setPropertyTaxContactUsErrMessage("Mobile Number should contain only 10 numbers" ));
        }
      } else {
        dispatch(setPropertyTaxContactUsErrMessage("Kindly enter Property tax contact us"));
      }
      break;
  }
};
export const propertyServicesValidation2 = (values:any,dispatch: any,newName:any) => {

  switch (newName) {
  
    case "ebTaxReqDoc":
      if (values.length>0) {
        dispatch(setEbTaxReqDocErrMessage(""));
      } else {
        dispatch(setEbTaxReqDocErrMessage("Kindly enter the Eb Tax Req Document"));
      }
      break;
    case "propTaxReqDoc":
      if (values.length>0) {
        dispatch(setPropertyTaxReqDocErrMessage(""));
      } else {
        dispatch(setPropertyTaxReqDocErrMessage("Kindly enter the Eb Tax Req Document"));
      }
      break;
  }
};

export const checkPropertyServiceError = (dispatch: any, data: propertyServicesInfoTypes) => {
  doValidatePropertyServicesProject(data.project,dispatch);doValidateEbTaxReqDoc(data.ebTaxReqDoc,dispatch);doValidateEbTaxAmount(data.ebTaxAmount,dispatch);doValidateEbTaxContactUs(data.ebTaxContactUs,dispatch);doValidatePropertyTaxReqDoc(data.propTaxReqDoc,dispatch);
  doValidatePropertyTaxAmount(data.propTaxAmount,dispatch);doValidatePropertyTaxConatctus(data.propTaxContactUs,dispatch)
  if (
    doValidatePropertyServicesProject(data.project,dispatch)&&doValidateEbTaxReqDoc(data.ebTaxReqDoc,dispatch)&&doValidateEbTaxAmount(data.ebTaxAmount,dispatch)&&doValidateEbTaxContactUs(data.ebTaxContactUs,dispatch)&&doValidatePropertyTaxReqDoc(data.propTaxReqDoc,dispatch)&&
    doValidatePropertyTaxAmount(data.propTaxAmount,dispatch)&&doValidatePropertyTaxConatctus(data.propTaxContactUs,dispatch)
  ) {
    return true;
  }
  return false;
};

export const doCheckPermissionAdd = (permissionList: any) => {
  const data = permissionList?.result?.data?.find((list: any) => list.table === PERMISSIONAME.PROPERTY_SERVICES);
  return !!(data && data.permissions.length > 0 && data.permissions[0].is_selected);
}
export const doCheckPermissionEdit = (permissionList: any) => {
  const data = permissionList?.result?.data?.find((list: any) => list.table === PERMISSIONAME.PROPERTY_SERVICES);
  return !!(data && data.permissions.length > 1 && data.permissions[1].is_selected);
};
export  const doCheckPermissionDelete = (permissionList: any) => {
  const data = permissionList?.result?.data?.find((list: any) => list.table === PERMISSIONAME.PROPERTY_SERVICES);
  return !!(data && data.permissions.length > 1 && data.permissions[2].is_selected);
};